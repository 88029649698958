import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {
    return (
        <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    fontSize: '12px',
                    boxShadow: 3,
                    borderRadius: "8px"
                },
            },
            arrow: {
                sx: {
                    color: '#000',
                },
            },
        }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px", color: "#000" }} />
        </Tooltip>
    );
};
const Receivingwaterbody = () => {
    const { collapsed } = useProSidebar();
    const [selectedOption, setSelectedOption] = useState('');

    // Handle changing the select dropdown
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Profile of receiving waterbody

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Have you considered the profile of the receiving waterbody?  <NewTooltip tooltiptext="In the description, include how, and where water is withdrawn, consumed, and discharged,
as well as water-related impacts the organization has caused or contributed to, or those
that are directly related to its operations, products, and services. An overview of water
use across the organization’s value chain."/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <select
                            className={`block  p-2 text-[#727272] text-[14px] bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}
                            value={selectedOption}
                            onChange={handleSelectChange}
                        >
                            <option value="">Please select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                        </select>

                        {/* Conditionally render the text area if the selected option is "yes" */}
                        {selectedOption === 'yes' && (
                            <>
                                <div className='mt-4'>
                                    <h4 className='text-[#727272] text-[14px] mb-2'>If yes please specify</h4>
                                    <textarea
                                        id="countriesOfOperation"
                                        name="countriesOfOperation"
                                        placeholder="Enter a description..."
                                        className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                                        // value={formData.countriesOfOperation}
                                        // onChange={handleInputChange}
                                        rows={7}
                                    // Specify the number of rows to determine the initial height
                                    ></textarea>
                                </div>

                            </>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
}
export default Receivingwaterbody;