import React from "react";

const IncidentofcomplianceMarketing = () => {
  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.4</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Incidents of non-compliance concerning marketing communications
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] w-[100px] border-4 h-12">
          GRI 417-3
        </h3>
      </div>
      <form>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                TTotal number of Incidents of non-compliance with regulations
                resulting in a fine or penalty
              </h2>
            </label>
            <input
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Total number of Incidents of non-compliance with regulations
                resulting in a warning
              </h2>
            </label>
            <input
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Total number of Incidents of non-compliance with voluntary codes
              </h2>
            </label>
            <input
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default IncidentofcomplianceMarketing;
