import React, { useState,useRef } from "react";
import jsonData from "../../previous-material-topics/tabledata.json";
import download from "../../../../../src/assets/favicons/download.png";

const Tooltip = ({ item, isVisible, position }) => {
  const style = {
    top: position.top,
    left: position.left,
    display: isVisible ? "block" : "none",
  };

  return (
    <div className="tooltip-container" style={style}>
      <div className="tooltip-heading">{item.name}</div>
      <div className="tooltip-description">
        This is a description for {item.name}
      </div>
    </div>
  );
};

const Environmental = () => {
  // const [isFlipped, setIsFlipped] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const leaveTimeoutRef = useRef(null);

  const handleMouseEnter = (event, item) => {
    const rowRect = event.target.getBoundingClientRect();
    const tooltipTop = rowRect.top - 110; // Adjust the value to position the tooltip
    const tooltipLeft = rowRect.left + rowRect.width / 2;

    setHoveredItem(item);
    setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
    clearTimeout(leaveTimeoutRef.current); 
  };

  const handleMouseLeave = () => {
    leaveTimeoutRef.current = setTimeout(() => {
      setHoveredItem(null);
    }, 100); // Adjust the delay as needed
  };


  return (
    <div
      className="col-span-4  mx-2 bg-white border shadow-right-bottom p-6 main-div"
    >
      <h4 className="text-lg font-semibold mb-6 text-black ">Environmental</h4>
      <table className="border-t-2">
        <thead>
        </thead>
        <tbody className="bg-gray-100 cursor-pointer">
        {jsonData.data.map((item, index) => (
            <tr
              key={item.index}
              className="hover:border-b hover:border-black text-gray-600 tooltip  border-b border-gray-400 border-solid font-semibold  items-center"
              onMouseEnter={(event) => handleMouseEnter(event, item)}
              onMouseLeave={handleMouseLeave}
            >
              <td className="text-xs mx-4 flex items-center justify-center col-span-full leading-7  text-left w-fit">
                {item.name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {hoveredItem && (
        <Tooltip
          item={hoveredItem}
          isVisible={hoveredItem !== null}
          position={tooltipPosition}
        />
      )}
    </div>
  );
};

export default Environmental;
