import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {


    return (
        <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    fontSize: '12px',
                    boxShadow: 3,
                    borderRadius: "8px"
                },
            },
            arrow: {
                sx: {
                    color: '#000',
                },
            },
        }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px" ,color:"#000" }} />
        </Tooltip>
    );
};
const Waterdischargerelatedimpacts = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                            Discharge Standards Used
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe any minimum standards set for the quality of effluent discharge. <NewTooltip tooltiptext="Include a description of standards used to set the quality of effluent discharge."/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                            Discharge Standards

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the process of determining discharge standards for facilities with no local discharge requirements  <NewTooltip tooltiptext="Indicate how the company determine discharge standards for
facilities without local discharge requirements.(Please specify
 the identification method use to determine standards (if any)" /></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                            Internal Water Quality Standards

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe internally developed water quality standards or guidelines used (if any)  <NewTooltip tooltiptext="Describe how the internally developed water quality standards or guidelines were determined."/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                            Sector-specific Standards

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the sector-specific standards or guidelines used (if any) <NewTooltip tooltiptext=" Provide a description of how the sector-specific standards were considered"/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-2a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Waterdischargerelatedimpacts;