import React, { useState } from "react";

const BargainingAgreements = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.1</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Collective bargaining agreements
        </h1>
        <h3 className="text-end mx-8 w-[80px] bg-gray-200 font-bold text-sm h-[3%] border-4 h-12 ">
          GRI  2-30
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className=" items-center mx-4 pb-2 mb-4">
          <div className=" items-center pb-2 mb-4">
            <div className="w-full items-center">
              <label
                htmlFor="legalForm"
                className="block font-bold mb-1 w-full"
              >
                <h2 className="text-gray-600">
                  Report the percentage of total employees covered by collective
                  bargaining agreements
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border  rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={4} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
          <div className="flex items-center pb-2 mb-4">
            <div className="w-full items-center">
              <label
                htmlFor="legalForm"
                className="block font-bold mb-1 w-full"
              >
                <h2 className="text-gray-600">
                  For employees not covered by collective bargaining agreements,
                  report whether the organization determines their working
                  conditions and terms of employment based on collective
                  bargaining agreements that cover its other employees or based
                  on collective bargaining agreements from other organizations
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border  rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={4} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BargainingAgreements;
