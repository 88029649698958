import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
const AveragehoursEmployee = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [count, setCount] = useState(2);
  const [tableData, setTableData] = useState([
    ["A"],
    ["Number of training hours provided to employees", 10, "", "", "", ""],
    ["Number of Employees", 10, "", "", "", ""],
  ]);

  const addBenefit = () => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newRow = new Array(newData[1].length).fill("");
      newData.push(newRow);
      return newData;
    });
  };
  const addCategory = () => {
    setTableData((prevData) => {
      const newData = prevData.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return [...row, ""];
        } else {
          return [...row, ""];
        }
      });
      return newData;
    });

    setCount(count + 1);
  };
  const handleCellChange = (value, rowIndex, cellIndex) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      if (rowIndex === 0 || cellIndex === 0) {
        newData[rowIndex][cellIndex] = value;
      } else {
        newData[rowIndex][cellIndex] = {
          category: value,
          management: value,
          company: value,
        };
      }
      return newData;
    });
  };
  const [selectedPeriod, setSelectedPeriod] = useState("Jan-March");
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };
  return (
    <>
      <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[95%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className="text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px]">Training Hours</p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-4 ml-2 ">
        <div className="flex justify-between">
          <h1 className="pb-4 text-gray-500 text-md font-bold mt-4">
            Training Hours
          </h1>
        </div>
        <div className="flex mb-6">
          <button
            className={`${
              selectedPeriod === "Jan-March" ? "bg-blue-100" : "bg-white"
            } mr-2 px-2 text-xs py-1 border rounded `}
            onClick={() => handlePeriodChange("Jan-March")}
          >
            Jan-Mar
          </button>
          <button
            className={`${
              selectedPeriod === "April-June" ? "bg-blue-100" : "bg-white"
            } mr-2 px-2 text-xs py-1 border rounded`}
            onClick={() => handlePeriodChange("April-June")}
          >
            Apr-Jun
          </button>
          <button
            className={`${
              selectedPeriod === "July-Sep" ? "bg-blue-100" : "bg-white"
            } mr-2 px-2 text-xs py-1 border rounded`}
            onClick={() => handlePeriodChange("July-Sep")}
          >
            Jul-Sep
          </button>
          <button
            className={`${
              selectedPeriod === "Oct-Dec" ? "bg-blue-100" : "bg-white"
            } mr-2 px-2 text-xs py-1 border rounded`}
            onClick={() => handlePeriodChange("Oct-Dec")}
          >
            Oct-Dec
          </button>
        </div>

        <div
          className={`${
            collapsed ? "w-[98%]" : "w-[98%]"
          } mb-5 text-xs text-start`}
        >
          <table className="border-collapse border  w-full  rounded-md shadow-md">
            <thead className="h-[50px] pb-5 border gradient-background mb-2">
              <tr>
                <th rowspan="2" className="border  px-4 py-2 w-[40%]"></th>
                <th colspan={count} className="border text-xs font-bold px-4 py-2">
                  Employee Category
                </th>
                <th colspan="3" className="text-xs font-bold px-4 py-2 text-center">
                  Gender
                </th>
              </tr>

              <tr>
                {tableData[0].map((cell, index) => (
                  <th
                    key={index}
                    className="text-gray-500 text-xs text-center pl-2 "
                  >
                    {/* {cell} */}
                    <input
                      type="text"
                      className="w-full py-1 bg-transparent text-center"
                      placeholder="Enter text"
                      autocomplete="off"
                      value={cell}
                      onChange={(e) =>
                        handleCellChange(e.target.value, 0, index)
                      }
                    />
                  </th>
                ))}
                <th
                  className="border text-xs opacity-70 text-blue-600 font-bold cursor-pointer text-center py-2 w-[20%]"
                  onClick={addCategory}
                >
                  + Add Category
                </th>
                <th className="border text-xs font-bold px-4 py-2">Male</th>
                <th className="border text-xs font-bold px-4 py-2">Female</th>
                <th className="border text-xs font-bold px-4 py-2">Non-Binary</th>
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex + 1}>
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="py-2 font-bold text-gray-600 pl-2 border"
                    >
                      {cellIndex === 0 ? (
                        <input
                          type="text"
                          placeholder="Enter a text"
                          className="w-full py-1 "
                          value={cell}
                          onChange={(e) =>
                            handleCellChange(
                              e.target.value,
                              rowIndex + 1,
                              cellIndex
                            )
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          className="w-full py-1  bg-transparent text-center"
                          placeholder=""
                          autocomplete="off"
                          value={cell}
                          onChange={(e) =>
                            handleCellChange(e.target.value, 0, cellIndex)
                          }
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td className="pl-2 py-2">
                  <button
                    className="mb-2 text-blue-600 font-bold py-2 px-4 rounded text-xs opacity-70 border border-[#007EEF]"
                    onClick={addBenefit}
                  >
                    + Add new row
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AveragehoursEmployee;
