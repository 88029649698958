import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Aside from 'components/collect/Aside';
import Emissions from 'components/collect/Emissions';
import { useProSidebar } from 'react-pro-sidebar';
import { setActiveNav } from 'state';
import { useLocation } from 'react-router-dom';
import Energyconsumption from 'pages/Energy/energy-consumption/energy-consumption';
import Energyconsumptionoutside from 'pages/Energy/energy-consumption-outside/energy-consumption-outside';
import Energyintensity from 'pages/Energy/energy-intensity/energy-intensity';
import Reductionenergyconsumption from 'pages/Energy/reduction-energy-consumption/reduction-energy-consumption';
import Energyproductsservices from 'pages/Energy/energy-products-services/energy-products-services';
import Significantwaste from './Waste/significant-waste/significant-waste';
import Managementwaste from './Waste/management-waste/management-waste';
import Wastegenerated from './Waste/waste-generated/waste-generated';
import Wastefromdisposal from './Waste/waste-diverted-from-disposal/waste-from-disposal';
import Wastetodisposal from './Waste/waste-diverted-to-disposal/waste-to-disposal';
import Watersharedresource from './Water-effluents/water-shared-resource/water-shared-resource';
import Waterdischargefromallareas from './Water-effluents/water-discharge-from-all-areas/water-discharge-from-all-areas';
import Waterstres from './Water-effluents/water-stress/water-stres';
import Substancesofconcern from './Water-effluents/substances-of-concern/substances-of-concern';
import Waterstorage from './Water-effluents/water-storage/water-storage';
import Materials1 from './materials/materials1/materials1';
import Materials2 from './materials/materials2/materials2';
import Materials3 from './materials/materials3/materials3';
const Environment = () => {
  const [activeTab, setActiveTab] = useState('Emissions');
  const { collapseSidebar, collapsed } = useProSidebar();
  const activeNav = useSelector((state) => state.global.activeNav);

  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const parts = path.split('/');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Energy consumed inside the organization" || tab === "Energy consumption outside of the organization" || tab === "Energy Intensity" || tab === "Reduction of energy consumption" || tab === "Reductions in energy requirements of products and services") {
      dispatch(setActiveNav("Energy"));
    }
    else if (tab === "Significant waste related impact" ||
      tab === "Management of significant waste related impacts" ||
      tab === "Waste generated" ||
      tab === "Waste Diverted from disposal" ||
      tab === "Waste diverted to disposal") {
      dispatch(setActiveNav("Waste"));
    }
    else if (tab === "Materials used by weight or volume" ||
      tab === "Recycled input materials used" ||
      tab === "Reclaimed products and their packaging materials") {
      dispatch(setActiveNav("Materials"));
    }
    else if (tab === "Interaction with water as shared resource" ||
      tab === "Water Withdrawal and Water Discharge from All Areas" ||
      tab === "Water withdrawal/Discharge from areas with water stress" ||
      tab === "Substances of concern" ||
      tab === "Change in water storage") {
      dispatch(setActiveNav("Water and effluents"));
    }
    else {
      dispatch(setActiveNav(tab));
    }
  };

  useEffect(() => {
    console.log(parts, parts[parts.length - 1]);
    if (
      parts[parts.length - 1] === 'environment' &&
      (!activeNav || !['Emissions', 'Energy', 'Waste'].includes(activeNav))
    ) {
      setActiveTab('Emissions');
      dispatch(setActiveNav('Emissions'));
    }
    return () => {
      dispatch(setActiveNav(''));
    };
  }, []);

  return (
    <div className='flex overflow-x-hidden'>
      <Aside activeTab={activeTab} handleTabClick={handleTabClick} />
      <div
        className={`${collapsed ? 'min-w-[84%]' : 'min-w-[82%]'
          } transition-all me-0`}
      >
        {/* Emissions start  */}
        {activeTab === 'Emissions' && <Emissions />}
        {/* Energy start  */}
        {activeTab === 'Energy consumed inside the organization' && <Energyconsumption />}
        {activeTab === 'Energy consumption outside of the organization' && <Energyconsumptionoutside />}
        {activeTab === 'Energy Intensity' && <Energyintensity />}
        {activeTab === 'Reduction of energy consumption' && <Reductionenergyconsumption />}
        {activeTab === 'Reductions in energy requirements of products and services' && <Energyproductsservices />}
        {/* waste start  */}
        {activeTab === 'Significant waste related impact' && <Significantwaste />}
        {activeTab === 'Management of significant waste related impacts' && <Managementwaste />}
        {activeTab === 'Waste generated' && <Wastegenerated />}
        {activeTab === 'Waste Diverted from disposal' && <Wastefromdisposal />}
        {activeTab === 'Waste diverted to disposal' && <Wastetodisposal />}
        {/* Water start  */}
        {activeTab === 'Interaction with water as shared resource' && <Watersharedresource />}
        {activeTab === 'Water Withdrawal and Water Discharge from All Areas' && <Waterdischargefromallareas />}
        {activeTab === 'Water withdrawal/Discharge from areas with water stress' && <Waterstres />}
        {activeTab === 'Substances of concern' && <Substancesofconcern />}
        {activeTab === 'Change in water storage' && <Waterstorage />}
        {/* Materials start  */}
        {activeTab === 'Materials used by weight or volume' && <Materials1 />}
        {activeTab === 'Recycled input materials used' && <Materials2 />}
        {activeTab === 'Reclaimed products and their packaging materials' && <Materials3 />}

      </div>
    </div>
  );
};

export default Environment;