import React, { useState, useEffect } from 'react';
import keyImg from '../../assets/images/mail.png';
import { Link } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { verifyEmail } from 'state/authSlice';
import { KeyboardBackspaceOutlined } from '@mui/icons-material';

const Emaibox = () => {
  const { key } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [verified, setVerified] = useState(false);

  const handleVerification = () => {
    console.log('handle verification initiated');
    // Perform form validation
    if (!key) {
      setError('Key not found');
      return;
    }

    dispatch(verifyEmail(key))
      .unwrap()
      .then(() => {
        navigate('/login');
        setVerified(true);
      })
      .catch((error) => {
        console.log('Verification error:', error);
        setError('Key not found');
        setVerified(false);
      });
  };

  useEffect(() => {
    handleVerification();
  }, []);

  return (
    <>
      <div className='min-h-screen grid place-items-center bg-[#f2f2f2]'>
        <div className='bg-white  w-80 rounded-md'>
          <div className='w-12 h-12 bg-purple-50 rounded-full mx-auto mt-8'>
            <img src={keyImg} className=' mx-auto py-2' />
          </div>
          <div className='flex justify-center my-8'>
            {verified ? 'Email Verified' : 'Verifying Email ...'}
          </div>
          <div>
            <Link to='/'>
              <p className='text-center mb-5' style={{ fontSize: '13px' }}>
                <KeyboardBackspaceOutlined icon='arrow-left' /> Back to log in
              </p>
            </Link>{' '}
          </div>
        </div>
      </div>
    </>
  );
};
export default Emaibox;
