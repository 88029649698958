import React, { useState, useEffect } from "react";
// import Tooltip from 'components/Tooltip/Tooltip ';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Tooltip from "@mui/material/Tooltip";
import { useProSidebar } from "react-pro-sidebar";

const NewTooltip = ({ tooltiptext }) => {
  return (
    <Tooltip
      title={tooltiptext}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "#000",
            color: "white",
            fontSize: "12px",
            boxShadow: 3,
            borderRadius: "8px",
          },
        },
        arrow: {
          sx: {
            color: "#000",
          },
        },
      }}
    >
      <InfoOutlinedIcon sx={{ fontSize: "14px" }} />
    </Tooltip>
  );
};
const Materialstwo = () => {
  const [rows, setRows] = useState([{ fieldName: "" }]);
  const { collapsed } = useProSidebar();
  const handleInputChange = (index, event) => {
    const newRows = [...rows];
    newRows[index][event.target.name] = event.target.value;
    setRows(newRows);
  };

  const addRow = () => {
    setRows([...rows, { fieldName: "" }]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const Energy = [
    {
      value: "Raw-materials",
      label: "Raw materials",
    },
    {
      value: "Associated-process-materials",
      label: "Associated process materials",
    },
    {
      value: "Semi-manufactured-goods-or-parts",
      label: "Semi-manufactured goods or parts",
    },
    {
      value: "Materials-for-packaging-purposes",
      label: "Materials for packaging purposes",
    },
  ];
  const Source = [
    {
      value: "Aluminum-batteries",
      label: "Aluminum batteries",
    },
    {
      value: "Cardboard",
      label: "Cardboard",
    },
    {
      value: "Glass",
      label: "Glass",
    },
    {
      value: "Hemp",
      label: "Hemp",
    },
    {
      value: "Kenaf",
      label: "Kenaf",
    },
    {
      value: "Natural-Rubber",
      label: "Natural Rubber",
    },
    {
      value: "Paper",
      label: "Paper",
    },
    {
      value: "Timber",
      label: "Timber",
    },
    {
      value: "Wool",
      label: "Wool",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];
  const Unit = [
    {
      value: "Externally-sourced",
      label: "Externally sourced",
    },
    {
      value: "Internally-sourced",
      label: "Internally sourced",
    },
  ];
  const Munit = [
    {
      value: "Cubic-centimeter-cm3",
      label: "Cubic centimeter cm3",
    },
    {
      value: "Cubic-decimeter-dm3",
      label: "Cubic decimeter dm3",
    },
    {
      value: "Cubic-meter-m3",
      label: "Cubic meter m3",
    },
    {
      value: "Gram",
      label: "Gram",
    },
    {
      value: "Kilogram-Kg",
      label: "Kilogram Kg",
    },
    {
      value: "Liter",
      label: "Liter",
    },
    {
      value: "Milligram",
      label: "Milligram",
    },
    {
      value: "Milliliter",
      label: "Milliliter",
    },
    {
      value: "Fluid-Ounce-fl-Oz",
      label: "Fluid Ounce fl Oz",
    },
    {
      value: "Gallon-Gal",
      label: "Gallon Gal",
    },
    {
      value: "Pint-Pt",
      label: "Pint Pt",
    },
    {
      value: "Pound-Lb",
      label: "Pound Lb",
    },
    {
      value: "Quart-Qt",
      label: "Quart Qt",
    },
    {
      value: "Cubic-foot-ft3",
      label: "Cubic foot ft3",
    },
    {
      value: "Kg",
      label: "Kg",
    },
    {
      value: "Pound",
      label: "Pound",
    },
    {
      value: "Metric-ton",
      label: "Metric ton",
    },
    {
      value: "Others",
      label: "Others",
    },
  ];
  return (
    <>
      <div className="mt-3">
        <form>
          {rows.map((row, index) => (
            <div key={index} className="mb-4">
              <div className="flex mb-3">
                <div className={`overflow-x-scroll custom-scrollbar ${collapsed ? 'sm:w-[720px] md:w-[720px] lg:w-[720px] xl:w-[700px] 2xl:w-[720px]' : 'sm:w-[420px] md:w-[420px] lg:w-[749px] xl:w-[525px] 2xl:w-[750px]'}`}>
                  <div className="flex ">
                    <div className="w-full max-w-xs mb-3">
                    {index === 0 && (
                      <label className="text-sm leading-5 text-gray-700 flex">
                        Type of material
                        <div className="ml-2">
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select type of raw material
                        </option>
                        {Energy.map((eng) => (
                          <>
                            <option value={eng.value}>{eng.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Materials used
                        <div className="ml-2">
                          <NewTooltip tooltiptext="What materials does the company use to produce its goods or services?" />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select materials used
                        </option>
                        {Source.map((source) => (
                          <>
                            <option value={source.value}>{source.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Source{" "}
                        <div className="ml-2">
                          <NewTooltip tooltiptext="Where does the company get its materials from?
Internally sourced materials: Materials that the company makes itself.
Externally sourced materials: Materials that the company buys from other companies." />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select source
                        </option>
                        {Unit.map((unit) => (
                          <>
                            <option value={unit.value}>{unit.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Total weight/volume
                        <div className="ml-2">
                          <NewTooltip tooltiptext="How much material is used for the production of goods or services?
 (Please specify the total weight or volume.)" />
                        </div>
                      </label>
                    )}
                      <input
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        placeholder="Enter Total weight/volume"
                      />
                    </div>

                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Unit
                        <div className="ml-2">

                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select unit
                        </option>
                        {Munit.map((unit) => (
                          <>
                            <option value={unit.value}>{unit.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="w-full max-w-xs mx-2 mb-3">
                    {index === 0 && (
                      <label className="text-sm  leading-5 text-gray-700 flex">
                        Data source
                        <div className="ml-2">
                          <NewTooltip tooltiptext="What is the source of the data for the total weight or volume of materials used?
Estimation: process of making an approximate calculation of something.
Direct measurement: process of measuring something directly. For example,
  a company might directly measure the total weight or volume of materials used
by weighing or measuring each batch of materials used." />
                        </div>
                      </label>
                    )}
                      <select
                        className="block w-[270px] py-2 text-sm leading-6  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b-2 border-gray-300"
                        defaultValue=""
                      >
                        <option value="" disabled>
                        Select data source
                        </option>
                        <option value="" >
                          Estimated
                        </option>
                        <option value="" >
                          Direct measurement
                        </option>

                      </select>
                    </div>

                  </div>
                </div>

                <div className={`flex pt-4 absolute  bg-white  h-[73px] ${collapsed ? 'sm:right-[3rem] md:right-[3rem] lg:right-[4rem] xl:right-[4rem] 2xl:right-[4rem]' : 'sm:right-[3rem] md:right-[3rem] lg:right-[3rem] xl:right-[3rem] 2xl:right-[3rem]'}`}>
                  <div className="flex ml-3 h-[10px]">
                    <div className="w-[85px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-l flex-col justify-center items-center inline-flex">
                      <div className="justify-center items-center gap-2 inline-flex">
                        <div className="relative text-white text-[13px] font-medium leading-snug tracking-wide">
                          Assign to
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-[#007EEF] rounded-r">
                      <div className="relative inline-flex">
                        <div className="absolute -mr-2 inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <ArrowDropDownIcon className="text-white bg-[#007EEF]" />
                        </div>
                        <select className="flex justify-center items-center w-[25px] h-[30px] px-2.5 py-1 bg-sky-600 rounded-r cursor-pointer appearance-none focus:outline-none text-white bg-blue hover:bg-light-blue-300">
                          <option value="" className="text-white pe-1"></option>

                          <option>sk</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="flex ml-4 h-[10px]">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/*" // Hide the file input
                    />
                    <button className="text-[#007EEF] text-[14px]">
                      <FileUploadOutlinedIcon
                        sx={{ mr: 0.5, color: "#007EEF", fontSize: "16px" }}
                      />{" "}
                      Upload
                    </button>
                  </div>

                  <div className="h-[10px] ml-4">
                    {" "}
                    <DeleteOutlineIcon
                      className="text-red-600 cursor-pointer"
                      onClick={() => removeRow(index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </form>
      </div>
      <button
        className="font-bold py-2 px-4 rounded text-xs opacity-70 text-sky-600"
        onClick={addRow}
      >
        <AddOutlinedIcon style={{ fontSize: "20px", marginBottom: "3px" }} />{" "}
        Add new
      </button>
    </>
  );
};

export default Materialstwo;
