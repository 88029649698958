import React from "react";
import { Link } from "react-router-dom";
import confirmationlink from "../../assets/images/mark-email.svg";

const ConfirmationLink = ({ onNextPage, onPreviousPage }) => {
  return (
    <div className="min-h-screen place-items-center mt-10 to-teal-500 flex flex-wrap justify-center ">
      <div className="bg-[#fff] w-[80%] ">
        <div className="flex min-h-full flex-1 flex-col justify-center  py-2  ">
          <div className="">
            <div className="">
              {/* <Link to="/login"> */}
              <img
                className="h-[4.7rem] hover:bg-sky-50 cursor-pointer"
                src={confirmationlink}
                alt="Sustainext"
                // onClick={onPreviousPage}
              />
              {/* </Link> */}
            </div>
            <h2 className="mt-10  text-[24px] font-bold text-[#727272]">
              Confirmation Link has been sent
            </h2>
            <p className="mt-[10px] mb-4 text-start font-semibold text-[16px] text-color-login">
              Please confirm the registration by clicking on the link sent to
              your email address
            </p>
            <div className="flex items-center pt-6">
              <div className="mr-2 w-[18.5%] submit-button">
                <Link to="/login">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md  px-3 py-2.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-[#42CC71] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Back to Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationLink;
