import React, { useState, useEffect } from 'react';
import axios from 'axios';
import key from '../../assets/images/key3.png';
import { Link } from 'react-router-dom';
import { KeyboardBackspaceOutlined } from '@mui/icons-material';

const Forgotpassword = () => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const handleResetPassword = async (e) => {
    setLoading(true);
    e.preventDefault();
    const url = `${REACT_APP_BACKEND_URL}/api/auth/password/reset/`;
    const data = {
      email,
    };

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Response:', response.data);
      if (response.data.detail === 'Password reset e-mail has been sent.') {
        setSent(true);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      {sent ? (
        <div className='min-h-screen grid place-items-center bg-[#f2f2f2]'>
          <div className='bg-white  w-80 rounded-md'>
            <div className='w-12 h-12 bg-purple-50 rounded-full mx-auto mt-8'>
              <img src={key} className=' mx-auto py-2' />
            </div>
            <div className='mt-2'>
              <h2 className=' text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                Check your mail
              </h2>
              <div className='px-3'>
                {' '}
                <p
                  className='text-center px-8 mt-2'
                  style={{ fontSize: '13px' }}
                >
                  We sent a password reset link to your Registered Email
                </p>
              </div>
            </div>
            <div className='my-5'>
              <p className='text-center px-8 mt-2' style={{ fontSize: '13px' }}>
                Didn't receive the email?
                <button
                  onClick={() => setSent(false)}
                  className='font-semibold text-[#a855f7] hover:text-[#a855f7] ml-1'
                >
                  Click to resend
                </button>
              </p>
            </div>
            <div>
              <Link to='/'>
                <p className='text-center mb-5' style={{ fontSize: '13px' }}>
                  <KeyboardBackspaceOutlined /> Back to log in
                </p>
              </Link>{' '}
            </div>
          </div>
        </div>
      ) : (
        <div className='min-h-screen grid place-items-center bg-[#f2f2f2]'>
          <div className='bg-white  w-80 rounded-md'>
            <div className='p-4'>
              <Link to='/'>
                <p
                  className='text-center flex items-center space-x-3'
                  style={{ fontSize: '13px' }}
                >
                  <KeyboardBackspaceOutlined />
                  Back to Login
                </p>
              </Link>{' '}
            </div>
            <div className='w-12 h-12 bg-purple-50 rounded-full mx-auto mt-8'>
              <img src={key} className=' mx-auto py-2' />
            </div>
            <div className='mt-2'>
              <h2 className=' text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
                Forgotten password
              </h2>
              <p className='text-center px-1 mt-2' style={{ fontSize: '13px' }}>
                No worries, we'll send you reset instructions
              </p>
            </div>
            <div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm  justify-center px-2 py-2 lg:px-5 '>
              <form className='space-y-6' action='#' method='POST'>
                <div>
                  <div>
                    <label
                      htmlFor='price'
                      className='block text-sm font-medium leading-6 text-gray-900'
                    >
                      Email
                    </label>
                    <div
                      className='relative mt-2 rounded-md shadow-sm'
                      id='app-title'
                    >
                      <input
                        id='email'
                        name='email'
                        type='email'
                        autoComplete='email'
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className='block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                      />
                    </div>
                  </div>
                </div>

                <div>
                  {/* <Link to='/email'> */}
                  <button
                    onClick={handleResetPassword}
                    type='submit'
                    className='flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  mb-5'
                  >
                    {loading ? 'Sending mail...' : 'Reset Password'}
                  </button>
                  {/* </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Forgotpassword;
