import React, { useState, useEffect, useRef } from "react";
import HelpIcon from "@mui/icons-material/Help";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Predictiontable from "./Prediction-table";
import MultiAxisLineChart from "./line-chart";
import Experimentalvariables from "./Experimental-Variables";
import { Link, useNavigate } from "react-router-dom";
const Datainjection = () => {
  let navigate = useNavigate();
  const [isShown, setIsShown] = useState(true);
  const [isShownui, setIsShownui] = useState(true);
  const [orshwo, setOrshwo] = useState(true);
  const [isShownform, setIsShownform] = useState(false);
  const [isShownformui, setIsShownformui] = useState(false);
  const [chartShownform, setChartShownform] = useState(false);
  const [tableShown, setTableShown] = useState(false);
  const back = () => {
    navigate(`/Create-Scenarios`);
  };
  const hanldshow = () => {
    setIsShown((current) => !current);
    setIsShownform((current) => !current);
    setOrshwo(!orshwo);
  };
  const hanldshowui = () => {
    setIsShownui((current) => !current);
    setOrshwo(!orshwo);
    setIsShownformui((current) => !current);
  };
  const tableshwo = () => {
    setTableShown((current) => !current);
  };
  const chartshwo = () => {
    setChartShownform((current) => !current);
  };
  return (
    <>
      <div className="my-4 mx-5 text-left">
        <div className="mb-2  py-4 px-3 shadow-md ">
          <div className="flex">
            <div>
              <h1 className="text-left mb-5 text-lg ">
                <b>Data Injection & Prediction</b>
              </h1>
            </div>

            <div className="ml-3">
              <HelpIcon id="app-title" />
              <ReactTooltip
                anchorId="app-title"
                place="right"
                content="Choose an option to inject experimental data for your scenario."
              />
            </div>
          </div>
          <div className="flex justify-between mb-5">
            {isShownui && (
              <div className="w-[15%]">
                {" "}
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                  onClick={hanldshow}
                >
                  Inject through Excel
                </button>
              </div>
            )}
            {orshwo && (
              <div>
                <h6>
                  <b>Or</b>
                </h6>
              </div>
            )}

            {isShown && (
              <>
                <div className="w-[15%]">
                  {" "}
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                    onClick={hanldshowui}
                  >
                    Inject throught UI
                  </button>
                </div>
              </>
            )}
          </div>
          {isShownformui && <Experimentalvariables />}
          {isShownform && (
            <>
              <div className="flex justify-between">
                <div className="w-[15%]">
                  {" "}
                  <button
                    type="file"
                    className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                  >
                    Download Template
                  </button>
                </div>
                <div className="bg-grey-lighter w-[18%]">
                  <label className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ">
                    <svg
                      className="w-8 h-8"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                    </svg>
                    <span className="ml-4 mt-1 text-base leading-normal">
                      Select a file
                    </span>
                    <input type="file" className="hidden" />
                  </label>
                </div>
                <div className="w-[15%]">
                  {" "}
                  <button
                    type="file"
                    className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                    onClick={tableshwo}
                  >
                    Validate & View Data
                  </button>
                </div>
              </div>
              {tableShown && (
                <>
                  <div>
                    <Predictiontable />
                  </div>

                  <div className="flex justify-center">
                    <div className="w-[15%]">
                      {" "}
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                        onClick={chartshwo}
                      >
                        Predict Emissions
                      </button>
                    </div>
                  </div>
                </>
              )}
              {chartShownform && (
                <>
                  <div>
                    <MultiAxisLineChart />
                  </div>
                  <div>
                    <div className="flex justify-center mb-5 mt-5">
                      <div className="w-[15%]">
                        {" "}
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                        >
                          Discard
                        </button>
                      </div>

                      <div className="w-[15%] ml-5">
                        {" "}
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                          onClick={back}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Datainjection;
