import React, { useState } from "react";

const WorkerswhoareNotemployees = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.3</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Workers who are not employees
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 2-8
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report the total number of workers who are not employees and
                whose work is controlled by the organization
              </h2>
            </label>
            <input
              type="text"
              id="countriesOfOperation"
              autocomplete="off"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.countriesOfOperation}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe the most common types of worker and their contractual
                relationship with the organization & the type of work they
                perform
              </h2>
            </label>
            <textarea
              type="text"
              id="countriesOfOperation"
              autocomplete="off"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.countriesOfOperation}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe the methodologies and assumptions used to compile data,
                including whether the numbers are reported - - in headcount,
                full-time equivalent (FTE), or using another methodology - at
                the end of the reporting period, as an average across the
                reporting period, or using another methodology "{" "}
              </h2>
            </label>
            <input
              type="text"
              id="countriesOfOperation"
              autocomplete="off"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.countriesOfOperation}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                describe significant fluctuations in the number of employees
                during the reporting period and between reporting periods
              </h2>
            </label>
            <input
              type="text"
              id="countriesOfOperation"
              autocomplete="off"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.countriesOfOperation}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default WorkerswhoareNotemployees;
