import React, { useState } from 'react';

const StructureComposition = () => {
  const [rows, setRows] = useState([{ value: '' }]);

  const handleAddRow = () => {
    setRows([...rows, { value: '' }]);
  };

  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].value = event.target.value;
    setRows(newRows);
  };

  const [formData, setFormData] = useState({
    legalForm: '',
    natureofOwnership: '',
    headquarters: '',
    countriesOfOperation: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className='container mx-auto max-w- '>
      {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
      <div className='flex justify-between'>
        <h1 className='mx-4 pb-8 text-gray-500 text-xl font-bold'>
          Governance Structure and Composition
        </h1>
        <h3 className='text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12'>
          GRI 2-9
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className='flex items-center mx-4 pb-2 mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                Describe the governance structure, including the committees of
                the highest governance body
              </h2>
            </label>
            <textarea
              id='countriesOfOperation'
              name='countriesOfOperation'
              placeholder='Enter a text'
              className='mt-2 p-2 border rounded-md w-full'
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full  mr-2 '>
            <label htmlFor='legalForm' className='block font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                List the committees of the highest governance body that are
                responsible for decision-making on and overseeing the management
                of the organization's impacts on the economy, environment and
                people
              </h2>
            </label>
            <input
              type='text'
              // id="natureofOwnership"
              autocomplete='off'
              placeholder='Enter a text'
              name='natureofOwnership'
              className=' mt-2 p-2 border rounded-md w-full'
              // value={formData.natureofOwnership}
              // onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                Describe the composition of the highest governance body and its
                committees by the following
              </h2>
            </label>
            <div className=' border custom-scrollbar rounded-md overflow-x-auto'>
              <div className='w-full'>
                <table className='mb-5 w-[120%] text-xs text-start'>
                  <thead className='h-[30px] pb-5 border'>
                    <tr className='space-x-2 '>
                      <th className='w-[15%] text-left pl-3'>Name</th>
                      <th className='w-[15%] text-left pl-3'>
                        Executive Power
                      </th>
                      <th className='w-[15%] text-left pl-3'>Independence</th>
                      <th className='w-[15%] text-left pl-3 '>
                        Tenure on the governance body
                      </th>
                      <th className='w-[15%] text-left pl-3'>
                        Number of significant positions & commitments held
                      </th>
                      <th className='w-[15%] text-left pl-3'>
                        The nature of such commitments
                      </th>
                      <th className='w-[15%] text-left pl-3'>Gender</th>
                      <th className='w-[15%] text-left pl-3'>
                        Under-represented social groups
                      </th>
                      <th className='w-[15%] text-left pl-3'>
                        Competencies relevant to the impacts of the organization
                      </th>
                      <th className='w-[15%] text-left pl-3'>
                        Stakeholder representation
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            value={row.value}
                            onChange={(event) => handleChange(event, index)}
                            className='cursor-pointer border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                          >
                            <option>- Select -</option>

                            {/* {scopeInfo[0].Category.map((item) => (
                    <option>{item}</option>
                  ))} */}
                          </select>
                        </td>
                        <td>
                          <select
                            // value={row.value}
                            // onChange={(event) => handleChange(event, index)}
                            className='cursor-pointer border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                          >
                            <option>Executive Member</option>
                            <option>Non-executive Member</option>
                            {/* {scopeInfo[0].SubCategory.map((item) => (
                    <option>{item}</option>
                  ))} */}
                          </select>
                        </td>
                        <td>
                          <select
                            // value={row.value}
                            // onChange={(event) => handleChange(event, index)}
                            className='cursor-pointer border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                          >
                            <option>Independent</option>
                            <option>Non-Independent</option>
                            {/* <option>Dropdown 3</option> */}
                          </select>
                        </td>
                        {/* <td>
                <select
                  value={row.value}
                  onChange={(event) => handleChange(event, index)}
                  className='cursor-pointer appearance-none bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                >
                  <option>Dropdown 1</option>
                  <option>Dropdown 2</option>
                  <option>Dropdown 3</option>
                </select>
              </td> */}
                        {/* <td>
                <select
                  value={row.value}
                  onChange={(event) => handleChange(event, index)}
                  className='cursor-pointer appearance-none bg-white  px-4 py-2 rounded leading-tight outline-none w-[85%] m-[3px]'
                >
                  <option>Dropdown 1</option>
                  <option>Dropdown 2</option>
                  <option>Dropdown 3</option>
                </select>
              </td> */}
                        <td className=''>
                          <input
                            type='text'
                            // id="natureofOwnership"
                            name='natureofOwnership'
                            autocomplete='off'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            // id="natureofOwnership"
                            autocomplete='off'
                            name='natureofOwnership'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            // id="natureofOwnership"
                            name='natureofOwnership'
                            autocomplete='off'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <select
                            // value={row.value}
                            // onChange={(event) => handleChange(event, index)}
                            className='cursor-pointer border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                          >
                            <option>- Select -</option>
                            <option>Male</option>
                            <option>Female</option>
                            {/* <option>Dropdown 3</option> */}
                          </select>
                        </td>
                        <td>
                          <input
                            type='text'
                            // id="natureofOwnership"
                            name='natureofOwnership'
                            autocomplete='off'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            // id="natureofOwnership"
                            name='natureofOwnership'
                            autocomplete='off'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                        <td>
                          <input
                            type='text'
                            autocomplete='off'
                            // id="natureofOwnership"
                            name='natureofOwnership'
                            className=' border bg-white  px-4 py-2 rounded leading-tight outline-none w-[95%] m-[3px]'
                            // value={formData.natureofOwnership}
                            // onChange={handleInputChange}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className='mb-6 ms-2 bg-gradient-to-r from-[#364161] to-[#06081f] hover:bg-gray-600 text-white font-bold py-2 px-4 rounded text-xs opacity-70'
                  onClick={handleAddRow}
                >
                  Add Row
                </button>
              </div>
            </div>
            {/* <table className="table  overflow-x-auto">
              <thead>
                <tr>
                  <th className=" border border-black px-4 py-2 text-xs text-xs">Name</th>
                  <th className=" border border-black px-4 py-2 text-xs text-xs">Executive power</th>
                  <th className=" border border-black px-4 py-2 text-xs">Independence</th>
                  <th className=" border border-black px-4 py-2 text-xs">
                    Tenure on the governance body
                  </th>
                  <th className=" border border-black px-4 py-2 text-xs">
                    Number of significant positions & commitments held
                  </th>
                  <th className=" border border-black px-4 py-2 text-xs">
                    The nature of such commitments
                  </th>
                  <th className=" border border-black px-4 py-2 text-xs">Gender</th>
                  <th className=" border border-black px-4 py-2 text-xs">
                    under-represented social groups
                  </th>
                  <th className=" border border-black px-4 py-2 text-xs">
                    competencies relevant to the impacts of the organization
                  </th>
                  <th className=" border border-black px-4 py-2 text-xs">stakeholder representation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                </tr>
                <tr>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                </tr>
                <tr>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                  <td className=" border border-black px-4 py-2 text-xs"></td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default StructureComposition;
