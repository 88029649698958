import React, { useState } from "react";

const Remuneration = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.1</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Remuneration policies highest governance body
        </h1>
        <h3 className="text-end mx-8 w-[7%] bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12 ">
          GRI 2-19
        </h3>
      </div>
     
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe the remuneration policies for members of the highest
                governance body and senior executives, including
              </h2>
            </label>

            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full  flex items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1  w-[22%]"
                >
                  <h2 className="text-gray-600">Fixed pay & variable pay </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border mx-4 rounded-md w-[70%]"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
              <div className="my-4">
                <input
                  className="border-gray-300 rounded px-4 py-2 w-full text-xs"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full flex items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-[22%]"
                >
                  <h2 className="text-gray-600">
                    Sign-on bonuses or recruitment incentive payments{" "}
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border mx-4 rounded-md w-[70%]"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
              <div className="my-4">
                <input
                  className="border-gray-300 rounded px-4 py-2 w-full text-xs"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full flex items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1  w-[22%]"
                >
                  <h2 className="text-gray-600">Termination payments </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border mx-4 rounded-md w-[70%]"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
              <div className="my-4">
                <input
                  className="border-gray-300 rounded px-4 py-2 w-full text-xs"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full flex items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-[22%]"
                >
                  <h2 className="text-gray-600">Clawbacks </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border mx-4 rounded-md w-[70%]"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
              <div className="my-4">
                <input
                  className="border-gray-300 rounded px-4 py-2 w-full text-xs"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full flex items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-[22%]"
                >
                  <h2 className="text-gray-600">Retirement benefits </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border mx-4 rounded-md w-[70%]"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
              <div className="my-4">
                <input
                  className="border-gray-300 rounded px-4 py-2 w-full text-xs"
                  type="file"
                  id="fileInput"
                  name="fileInput"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe how the remuneration policies for members of the
                highest governance body and senior executives relate to their
                objectives and performance in relation to the management of the
                organization’s impacts on the economy, environment, and people.
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Remuneration;
