import React, { useState } from "react";

const DirectEconomicvalue = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Direct economic value generated & distributed
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 205-2
        </h3>
      </div>
      <div className="w-full">
        <div className="">
          {/* <div className="w-full flex justify-end border-l border-r ">
            <div className="py-2 text-center w-[218px] border-l text-sm">
              <span className="px-2 py-2 font-bold">Number of all Employees</span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-3 py-2 font-bold ">
                No. of workers who are not employees but whose work and/or
                workplace is controlled by the organization
              </span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-2 py-2 font-bold">Total number of employees</span>
            </div>
          </div> */}
          <p className="mt-6 mb-5 text-gray-500 font-bold">
            Direct economic value generated and distributed (EVG&D) on an
            accruals basis, including the basic components for the
            organization’s global operations as listed below: (compile the EVG&D
            from data in the organization’s audited financial or profit and loss
            (P&L) statement, or its internally audited management accounts.)"{" "}
          </p>
          <table className=" text-xs text-start">
            <thead className="border">
              <th className="py-2 text-center text-sm">
                <span className="px-2 py-2 font-bold"></span>
              </th>
              <th className="py-2 text-center w-[22%] border-l text-sm">
                <span className="px-2 py-2 font-bold">
                  Select Currency
                </span>
              </th>
              <th className="py-2 text-center w-[25%] border-l text-sm">
                <span className="px-3 py-2 font-bold ">
                  Select Reporting Period
                </span>
              </th>
              <th className="py-2 text-center w-[22%] border-l text-sm">
                <span className="px-2 py-2 font-bold">
                  Add Country
                </span>
              </th>
            </thead>
            <tbody className="border">
              <tr>
                <td className="px-8 py-2 font-bold h-[80px] w-[20%] text-sm text-black border-r">
                Direct Economic value generated (Revenues)	
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px] "
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Economic Value distributed	
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Operation costs
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Employee wages & benefits	
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Payments to providers of capital		
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Payments to governments by country			
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Country A				
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Country B				
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Country C				
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Community investments					
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                Economic Value Retained						
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DirectEconomicvalue;
