import React, { useState, useReducer, useEffect } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useLocation } from "react-router-dom";
import WelcomeModal from "./welcomemodal";
import SustainabilityGoals from "./sustainabilitygoals";
import LetStarted from "./getStarted";
import { useNavigate } from "react-router-dom";
// import CarouselWithContent from "pages/carousel/carousel";
// import Modal from "react-modal";

const TOUR_STEPS = [
  {
    target: ".take-a-tour",
    content:
      "Welcome to the Quick Tour of the Sustainext Platform. You can come back here anytime.",
    disableBeacon: true,
    hideCloseButton: false, // Hide the close button for the welcome modal
    spotlightClicks: false,
    showSkipButton: true,
    // buttonBack:true
  },
  {
    target: ".sustainext-hq",
    content:
      "This is your Home Screen. You can manage your tasks and set your organization's preferences here. You can come back for a Quick Tour anytime.",
    showSkipButton: true,
    // buttonBack:true
  },
  {
    target: ".general-section",
    content:
      "Add details about your organization and create a reporting structure based on your organization chart.",
    showSkipButton: true,
    // buttonBack:true
  },
  {
    target: ".materilaity-dashboard",
    content:
      "Engage with Stakeholders and Conduct Materiality Assessments to define your ESG priorities.    ",
    showSkipButton: true,
  },
  {
    target: ".collect-icon",
    content:
      "Collect your base E,S, & G data though manual input or through invoices here. Conduct automated Emissions calculations based on the collected data.      ",
    showSkipButton: true,
  },
  {
    target: ".environment-icon",
    content: "Clicking on this shows scope-1,2,3",
    showSkipButton: true,
  },
  {
    target: ".add-tasks",
    content: "This is where you can add the tasks.",
    showSkipButton: true,
  },
  {
    target: ".analyse-section",
    content:
      "See the results from the automated calculations on the collected data. View your Scope 1, 2 and 3 Emissions by Source and Location.    ",
    showSkipButton: true,
  },
  {
    target: ".report-section",
    content:
      "Generate pre-filled GHG and ESG Reports based on international frameworks.    ",
    showSkipButton: true,
  },
  {
    target: ".optimize-section",
    content:
      "Create what-if scenarios based on business metrics that matter to you, and reach net-zero based on data-backed decision-making.    ",
    showSkipButton: true,
  },
  {
    target: ".track-section",
    content:
      "This is where you will find your dashboards to visually view and track performance on ESG metrics.    ",
    showSkipButton: true,
  },
  {
    target: ".take-a-tour",
    content:
      "Done! You can click on Take a tour again at any time to re-start this tour.",
    showSkipButton: true,
  },
];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS,
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

const customStyles = {
  // Custom styles for JoyRide
  options: {
    // Set your custom colors here
    primaryColor: "#ff0000", // Change the primary color of the tour
    backgroundColor: "#f0f0f0", // Change the background color of the tour
    textColor: "#333", // Change the text color of the tour
    overlayColor: "rgba(0, 0, 0, 0.5)", // Change the overlay color of the tour
    arrowColor: "#ffffff", // Change the color of the arrow
    spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)", // Change the spotlight shadow color
    width: 300, // Change the width of the tour tooltip
    zIndex: 1000, // Change the z-index of the tour
    // preventScroll: true,
  },
  buttonBack: {
    // Custom styles for the "Back" button
    color: "#0a0428", // Change the back button text color to blue
    // position: "absolute", // Position the button absolutely
    // left: "10px", // Adjust the left position as needed
    // top: "10px",
    // bottom:'10px'
  },
  buttonNext: {
    // Custom styles for the "Next" button
    background:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(90deg, #007EEF 0%, #2AE4FF 100%)",
    color: "#fff",
    borderRadius: "5px",
    padding: "10px 20px",
    // position: "fixed", // Apply fixed position
  },
};

const SustainextHQ = () => {
  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [showSustainabilityGoals, setShowSustainabilityGoals] = useState(false);
  const [showLetStarted, setShowLetStarted] = useState(false);
  const [showComponents, setShowComponents] = useState(true);
  const [showTakeTourButton, setShowTakeTourButton] = useState(false);
  const [modalsSeen, setModalsSeen] = useState(false); // State to track if modals
  const location = useLocation();
  const [modalsOpen, setModalsOpen] = useState({
    welcomeModal: true,
    sustainability: true,
    letstarted: true,
    // ... other modal states
  });


  const closeModal = () => {
    // Close all modals and mark them as seen
    setShowTakeTourButton(true); // Reset showTakeTourButton to false when a modal is closed
    setShowWelcomeModal(false);
    setShowSustainabilityGoals(false);
    setShowLetStarted(false);
    setModalsSeen(true); // Mark modals as seen
  };

  const shouldRunTour = false;

  const handleWelcomeModalContinue = () => {
    setShowWelcomeModal(false);
    setShowSustainabilityGoals(true);
  };

  const handleSustainabilityGoalsContinue = () => {
    setShowSustainabilityGoals(false);
    setShowLetStarted(true);
    setShowWelcomeModal(false);
  };

  const handleLetStartedContinue = () => {
    setShowWelcomeModal(false);
    setShowSustainabilityGoals(false);
    setShowLetStarted(false);
    setShowTakeTourButton(true); // Show the "Take a tour" button when "Get Started" is clicked
    // setHasSeenModals(true);
    onStartTour(); // Start the tour directly when clicking the "Get Started" button
  };


  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      // setTourViewed();
      dispatch({ type: "STOP" });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  const onStartTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
    if (!shouldRunTour) {
      return; // Exit the function if shouldRunTour is false
    }
  };
  // const navigate = useNavigate();

  return (
    <div className="">
      <div>
        {/* Show the WelcomeModal component */}
        { showWelcomeModal && modalsOpen.welcomeModal &&(
          <WelcomeModal
            onContinue={handleWelcomeModalContinue}
            onClose={closeModal}
          />
        )}

        {/* Show the SustainabilityGoals component */}
        {
          showSustainabilityGoals &&
          modalsOpen.sustainability &&(
            <SustainabilityGoals
              onContinue={handleSustainabilityGoalsContinue}
              onClose={closeModal}
            />
          )}

        {/* Show the LetStarted component */}
        { showLetStarted && modalsOpen.letstarted &&(
          <LetStarted
            onContinue={handleLetStartedContinue}
            onClose={closeModal}
          />
        )}
      </div>
      <div className="flex justify-between items-center ">
        {/* <div className="m-4 ms-8 gradient-text text-opacity-20 text-[22px] font-semibold leading-relaxed">
          Sustainext HQ
        </div> */}
        {showTakeTourButton && (
          <div className="h-[10%]">
            <button
              className={`right-10 top-18 ms-2 bg-gradient-to-r from-[#364161] to-[#06081f] hover:bg-gray-600 text-white font-bold py-2 mr-4 px-2 rounded text-xs take-a-tour`}
              onClick={(e) => {
                e.preventDefault(); // Prevent default anchor link behavior
                onStartTour();
              }}
              // style={{ zIndex: 200 }}
            >
              Take a tour
            </button>
          </div>
        )}
      </div>

      {tourState.run && (
        <JoyRide
          {...tourState}
          callback={callback}
          showSkipButton={false}
          disableCloseOnEsc={true}
          disableOverlayClose={true}
          styles={customStyles}
          locale={{
            last: "End tour",
          }}
        />
      )}
    </div>
    // </div>
  );
};
export default SustainextHQ;
