import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ path, activeTab }) => {
  const activeNav = useSelector((state) => state.global.activeNav);

  const parts = path.split('/').filter((part) => part);
  const breadcrumbLinks = [];
  let linkSoFar = '';
  for (const [index, part] of parts.entries()) {
    linkSoFar += '/' + part;
    breadcrumbLinks.push({ link: linkSoFar, label: part });
  }

  return (
    <div className='flex justify-start items-center m-4 gap-1'>
      {breadcrumbLinks.map(({ link, label }, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <span className='text-[#222222] mx-1'>&gt;</span>
          )}
          <Link to={link}>
            <span
              className={
                index === breadcrumbLinks.length - 1 && !activeNav
                  ? 'text-[#222222] hover:text-[#0057A5] mx-2'
                  : 'text-[#007EEF] hover:text-[#0057A5] mx-2'
              }
            >
              {label.charAt(0).toUpperCase() + label.slice(1)}
            </span>
          </Link>
        </React.Fragment>
      ))}
      {activeNav && (
        <React.Fragment>
          {' > '}
          <span className='text-[#222222] hover:text-[#0057A5] cursor-pointer mx-2'>
            {activeTab}
          </span>
        </React.Fragment>
      )}
    </div>
  );
};

export default Breadcrumb;
