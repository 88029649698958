import React, { useEffect, useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import Socialheader from "../header/Socialheader";
import Employeehier from "./employee-hier";
import Employeeturnover from "./employee-turnover";
import { Energydata } from "components/data/info/energydata";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Employee = ({handleNext}) => {
  const [activeMonth, setActiveMonth] = useState("Jan");
  const { collapseSidebar, collapsed } = useProSidebar();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  const [category, setCategory] = useState("");
  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
  }
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    setCategory(selected);
  };
  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  }, [category])

  return (
    <>
      <div className="flex flex-col justify-start overflow-x-hidden">


        <div className="flex justify-between items-center shadow-md border-gray-100 mb-5">
          <div
            className={`${collapsed ? "w-[90%] " : "w-[75%]"
              } flex justify-between items-center`}
          >
            <div className=" text-left mb-5 ml-6 mt-4">
              <p className="text-sm">Social</p>
              <p className="gradient-text text-[22px] font-bold">
                Employment 2016
              </p>
            </div>
            <div className="flex  absolute right-[4.5rem] mt-3">
              <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]" onClick={() => toggleDrawer('6')}>
                GRI 401-1
              </button>
              <button className="text-[#fff] bg-[#FF3A21] rounded-full text-[11px] w-[60px] h-[25px] ml-2 " onClick={() => toggleDrawer('7')}>
                SDG 5
              </button>
              <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2" onClick={() => toggleDrawer('8')}>
                SDG 8
              </button>
              <button className="text-[#fff] bg-[#DD1367] rounded-full text-[11px] w-[60px] h-[25px] ml-2 " onClick={() => toggleDrawer('9')}>
                SDG 10
              </button>

            </div>
          </div>

        </div>
        <div className="ml-2">
          <h6 className="text-[15px] mb-4">

            New employee hires and employee turnover
          </h6>
        </div>
        <div className={`${isOpen ? "translate-x-[15%] block" : "translate-x-[120%] hidden"} 
          fixed right-[51px]  w-[340px] h-full bg-white  rounded-md 
          transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}>

          {data && data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">
                  {program.header}
                </div>

                <div className="ml-2 float-right">
                  <h5 className="text-[#727272] text-[17px] font-bold cursor-pointer" onClick={toggleDrawerclose}><ClearOutlinedIcon /></h5>
                </div>

              </div>
              <div> {program.data}</div>
            </>
          ))}

        </div>
      </div>
      <div className="mb-8">
        <Socialheader activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
      </div>
      <div className="mb-8">
        <Employeehier activeMonth={activeMonth} />
      </div>
      <div className="mb-8">
        <Employeeturnover activeMonth={activeMonth} />
      </div>

      <div className="w-full mb-5">
                <div className="absolute right-5">
                  <div className="flex  me-2  mb-5">
                    <button
                      className="bg-white text-gray-400 px-3 py-1.5 rounded w-[120px]"
                    >
                      &lt; Previous
                    </button>

               
                      <button
                        className="bg-blue-500 text-white px-3 py-1.5 rounded ml-2 font-bold w-[100px]"
                        onClick={handleNext}
                     
                      >
                        Next &gt;
                      </button>
                 
                  </div>
                </div>
              </div>
    </>
  );
}
export default Employee;