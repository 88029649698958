import React, { useState, useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import Socialheader from "../header/Socialheader";
import { Energydata } from "components/data/info/energydata";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
const NewTooltip = ({ tooltiptext }) => {
  return (
    <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#000',
          color: 'white',
          fontSize: '12px',
          boxShadow: 3,
          borderRadius: "8px"
        },
      },
      arrow: {
        sx: {
          color: '#000',
        },
      },
    }}>
      <InfoOutlinedIcon sx={{ fontSize: "14px", }} />
    </Tooltip>
  );
};
const ParentalLeave = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const [activeMonth, setActiveMonth] = useState("Jan");
  const [data, setData] = useState();
  const [category, setCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const handleeditClick = () => {
    setIsClicked(!isClicked);
  };
  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
  }
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    setCategory(selected);
  };
  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  }, [category])
  const [rows, setRows] = useState([
    { male: "", female: "", total: 0 },
    { male: "", female: "", total: 0 },
    { male: "", female: "", total: 0 },
    { male: "", female: "", total: 0 },
  ]);
  const categories = [
    {
      title: 'Parental Leave Entitlement',
      tooltip: "This section refers to the total number of employees who meet the eligibility criteria for parental leave within the organization, regardless of whether they actually took leave.",
    },
    {
      title: 'Taking Parental Leave',
      tooltip: "This section refers to the total number of employees who used their parental leave entitlement during the reporting period.",
    },
    {
      title: 'Returning to Work Post-Leave',
      tooltip: "This section refers to the total number of employees who returned to work after their parental leave ended within the reporting period.",
    },
    {
      title: 'Retained 12 Months After Leave',
      tooltip: "This section refers to the total number of employees who returned to work after their parental leave ended within the reporting period and were still employed 12 months later.",
    },
  ];

  const handleInputChange = (event, index, gender) => {
    const updatedRows = [...rows];
    const updatedValue = event.target.value ? parseInt(event.target.value, 10) : 0;

    updatedRows[index][gender] = updatedValue;

    // Calculate total for the row
    updatedRows[index].total = Object.values(updatedRows[index]).slice(0, -1).reduce((sum, value) => sum + (parseInt(value) || 0), 0);

    setRows(updatedRows);
  };
  return (
    <>
      <div className="flex flex-col justify-start overflow-x-hidden">
        <div className="flex justify-between items-center shadow-md border-gray-100 mb-5">
          <div
            className={`${collapsed ? "w-[90%] " : "w-[75%]"
              } flex justify-between items-center`}
          >
            <div className=" text-left mb-5 ml-6 mt-4">
              <p className="text-sm">Social</p>
              <p className="gradient-text text-[22px] font-bold">
                Employment 2016
              </p>
            </div>
            <div className="flex  absolute right-[4.5rem] mt-3">
              <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]" onClick={() => toggleDrawer('14')}>
                GRI 401-3
              </button>
              <button className="text-[#fff] bg-[#FF3A21] rounded-full text-[11px] w-[60px] h-[25px] ml-2" onClick={() => toggleDrawer('15')}>
                SDG 5
              </button>
              <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2" onClick={() => toggleDrawer('16')}>
                SDG 8
              </button>
            </div>
          </div>
        </div>
        <div className={`${isOpen ? "translate-x-[15%] block" : "translate-x-[120%] hidden"}
          fixed right-[51px]  w-[340px] h-full bg-white  rounded-md
          transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}>

          {data && data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">
                  {program.header}
                </div>

                <div className="ml-2 float-right">
                  <h5 className="text-[#727272] text-[17px] font-bold cursor-pointer" onClick={toggleDrawerclose}><ClearOutlinedIcon /></h5>
                </div>

              </div>
              <div> {program.data}</div>
            </>
          ))}

        </div>
      </div>
      <div className="mb-2">
        <div className="mb-4 ml-2"><h6>Parental leave</h6></div>
      </div>
      <div className="mb-7">
        <Socialheader activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
      </div>
      <div className="flex justify-between mb-5">
        <div className="ml-3">
          <h6 className="text-[15px]"> Parental leave <NewTooltip tooltiptext="This section documents data corresponding to the number of employees entitled to, taking, returning from, and remaining employed after parental leave, broken down by gender." />
          </h6>
        </div>
        <div className="flex justify-between gap-2">
          <div className="bg-blue-100 h-[25px] rounded-md">
            <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-3a</p>
          </div>
          <div className="bg-blue-100 h-[25px] rounded-md">
            <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-3b</p>
          </div>
          <div className="bg-blue-100 h-[25px] rounded-md">
            <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-3c</p>
          </div>
          <div className="bg-blue-100 h-[25px] rounded-md">
            <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-3d</p>
          </div>
          <div>

            <>
              {isClicked ? (
                <>
                  <CloseOutlinedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={handleeditClick}
                  />
                  <SaveOutlinedIcon
                    sx={{ ml: 2, cursor: "pointer" }} />
                </>
              ) : (

                <EditOutlinedIcon onClick={handleeditClick} sx={{ cursor: "pointer" }} />
              )}
            </>

          </div>
        </div>
      </div>
      <div className="rounded-md">

        <div
          className="shadow-lg rounded-[10px] mb-3">

          <table className="rounded-[10px]">
            {/* <div className="div">{renderHeadings()}</div> */}
            <thead className="border rounded-[10px]">


              <tr className="border-t border-b gradient-background">
                {/* <td className="px-4 py-2  border">Employee Category</td> */}

                <td className="px-4 py-2 text-[14px] text-[#727272] border w-[10%]">
                  Employee Categrory
                </td>

                <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                  Male
                </td>
                <td className="px-2 py-2 text-[14px] text-[#727272] border w-[11%] text-center">
                  Female
                </td>
                <td className="px-2 py-2 text-[14px] text-[#727272] border w-[10%] text-center">
                  Total
                </td>


              </tr>
            </thead>
            <tbody className="border-l border-r">

              {categories.map((category, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm w-[13%]">
                    {category.title} <NewTooltip tooltiptext={category.tooltip} />
                  </td>
                  <td className="px-4 py-2 border-y">
                    <input
                      type="number"
                      value={rows[index].male}
                      onChange={e => handleInputChange(e, index, 'male')}
                      className="w-full text-center hover:border-indigo-500 hover:border-b"
                      placeholder="10"
                    />
                  </td>
                  <td className="px-4 py-2 border-y">
                    <input
                      type="number"
                      value={rows[index].female}
                      onChange={e => handleInputChange(e, index, 'female')}
                      className="w-full text-center hover:border-indigo-500 hover:border-b"
                      placeholder="10"
                    />
                  </td>
                  <td className="px-4 py-2 border-y border-l text-center">{rows[index].total}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default ParentalLeave;
