import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import { Energydata } from "components/data/info/energydata";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Wasteheader from '../header/Wasteheader';
import Wastegeneratedbody from './waste-generated-body';

const Wastegenerated = () => {
  const [activeMonth, setActiveMonth] = useState("Jan");
  const [data, setData] = useState();
  const [category, setCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { collapsed } = useProSidebar();
  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
  }
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    setCategory(selected);
  };
  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  }, [category])

  return (
    <>
      <div className="flex flex-col justify-start overflow-x-hidden ">


        <div className="flex justify-between items-center border-b border-gray-200 mb-5">
        <div
            className={`${collapsed ? "w-[90%] " : "w-[75%]"
              } flex justify-between items-center`}
          >
            <div className="text-left mb-4 ml-3 pt-5">
              <p className="text-sm">Environment</p>
              <div className='flex'>
                <div>
                  <p className="gradient-text text-[22px] font-bold">
                  Waste
                  </p>
                </div>
                <div className="bg-gray-100 h-[22px] w-[95px]  mx-2 mt-2 rounded-md" >
                  <p className="text-gray-500 text-[12px] pt-0.5 px-2">Material Topic</p>
                </div>
              </div>

            </div>
            <div className="flex  absolute right-[4.5rem] border-l">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('26')}>GRI 306 - 3</button>
              <button className="text-[#fff] bg-[#4C9F38] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 " onClick={() => toggleDrawer('19')}>SDG 3</button>
              <button className="text-[#fff] bg-cyan-500 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('20')}>SDG 6</button>
              <button className="text-[#fff] bg-[#FD9D24] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 " onClick={() => toggleDrawer('21')}>SDG 11</button>
              <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('22')}>SDG 12</button>
              <button className="text-[#fff] bg-[#56C02B] rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('23')}>SDG 15</button>
            </div>
          </div>

        </div>
    <div className="ml-3">
          <h6 className="text-[17px] mb-4 font-semibold">
          Topic disclosure

          </h6>
        </div>
        <div className={`${isOpen ? "translate-x-[15%] block" : "translate-x-[120%] hidden"}
      fixed right-[51px]  w-[340px] h-full bg-white  rounded-md
      transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}>

          {data && data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">
                  {program.header}
                </div>

                <div className="ml-2 float-right">
                  <h5 className="text-[#727272] text-[17px] font-bold cursor-pointer" onClick={toggleDrawerclose}><ClearOutlinedIcon /></h5>
                </div>

              </div>
              <div> {program.data}</div>
            </>
          ))}

        </div>
      </div>
      <Wasteheader activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
<Wastegeneratedbody/>



    </>

  );
};

export default Wastegenerated;
