import React, { useState } from "react";

const ManagementofImpacts = () => {
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Role of the highest governance body in overseeing the management of
          impacts
        </h1>
        <h3 className="text-end mx-8 w-[7%] bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12 ">
          GRI 2-12
        </h3>
      </div>
      <form>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe the role of the highest governance body and of senior
                executives in developing, approving, and updating the
                organization's purpose, value or mission statements, strategies,
                policies and goals related to sustainable development
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe the role of the highest governance body and of senior
                executives in overseeing the organization's due diligence and
                other processes to identify and manage the organization's impact
                on the economy, environment and people
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManagementofImpacts;
