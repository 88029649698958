//eslint-disable

import React, { useState } from 'react';
import jsonData from '../stake-holder-engagement/tabledata.json';

const StakeHolderEngagement = () => {
  const [activeSection, setActiveSection] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  const handleToggleRows = (section) => {
    setActiveSection(activeSection === section ? '' : section);
  };

  const handlePreview = (index) => {
    setSelectedRow(index);
  };

  const handleSendForm = (name, email) => {
    console.log('Send Form:', name, email);
    // Perform any form submission action here
  };

  const closeModal = () => {
    setSelectedRow(null);
  };

  return (
    <div className='table-auto w-full'>
      <h1 className='pt-5 pb-5 font-bold p-2 text-lg'>
        Materiality Assessment Engagement:
      </h1>
      <table className=''>
        <tbody>
          {/* Employees Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 text-gray-600 pb-3 font-bold'
              onClick={() => handleToggleRows('employees')}
            >
              Employees {activeSection === 'employees' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'employees' && (
            <>
              <tr className='border'>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.employee}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}

          {/* Customer Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('customers')}
            >
              Customers {activeSection === 'customers' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'customers' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.customers}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}

          {/* Supplier Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('suppliers')}
            >
              Suppliers {activeSection === 'suppliers' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'suppliers' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.suppliers}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}

          {/* Governments Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('government')}
            >
              Governments {activeSection === 'government' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'government' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>
                    {item.government}
                  </td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
          {/* Local Communities Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('local')}
            >
              Local Communities {activeSection === 'local' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'local' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.local}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}

          {/* NGO's Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('ngo')}
            >
              NGO's {activeSection === 'ngo' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'ngo' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.ngo}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
          {/* Trade Unions */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('trade')}
            >
              Trade Unions {activeSection === 'trade' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'trade' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.trade}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
          {/* Investors Section */}
          <tr>
            <td
              colSpan='5'
              className='cursor-pointer pt-5 pb-3 text-gray-600 font-bold'
              onClick={() => handleToggleRows('investor')}
            >
              Investors {activeSection === 'investor' ? '-' : '+'}
            </td>
          </tr>
          {activeSection === 'investor' && (
            <>
              <tr>
                <th className='p-2 text-sm border'>Group Name</th>
                <th className='p-2 text-sm border'>Name</th>
                <th className='p-2 text-sm border'>Email ID</th>
                <th className='p-2 text-sm border'>Stakeholder Group</th>
                <th className='p-2 text-sm border'>Actions</th>
              </tr>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 px-2 text-sm'>{item.investor}</td>
                  <td className='border py-2 px-6 text-sm'>{item.name}</td>
                  <td className='border py-2 text-sm'>{item.email}</td>
                  <td className='border py-2 text-sm'>{item.stake}</td>
                  <td className='border py-2 text-sm'>
                    <button
                      className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded'
                      onClick={() => handlePreview(index)}
                    >
                      Preview
                    </button>
                    <button
                      className='bg-gray-500 hover:bg-gray-500 text-white font-bold py-1 px-2 rounded ml-2'
                      onClick={() => handleSendForm(item.name, item.email)}
                    >
                      Send Form
                    </button>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {selectedRow !== null && (
        <div className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'>
          <div className='bg-white p-4 rounded shadow-md'>
            <h2 className='text-lg font-bold mb-2'>Preview Details</h2>
            <p>
              <strong>Name:</strong> {jsonData.data[selectedRow].name}
            </p>
            <p>
              <strong>Email:</strong> {jsonData.data[selectedRow].email}
            </p>
            {/* Render other details here */}
            <button
              className='bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4'
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StakeHolderEngagement;
