import React, { useState } from "react";

const RestatementofInfo = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.4</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
        Restatements of Information
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 2-4
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label
              htmlFor="legalForm"
              className="block font-bold mb-1 pb-4"
            >
              <h2 className="text-gray-600">Are there any restatements of information you would like to
              make from the previous periods?</h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              autocomplete="off"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label
              htmlFor="legalForm"
              className="block font-bold mb-1 pb-4"
            >
              <h2 className="text-gray-600">If yes, please explain the reason for the restatements and the
              effects of the restatements (this refers to the consequence of the
              change or correction. Where quantitative information is relevant
              to the restatement, then specify the quantitative change in
              restated information.)</h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default RestatementofInfo;
