import React, { useState } from 'react';
import Structure from './Structure';
import Locations from './Locations';
import Preferences from 'pages/general/setup/Organization/Preferences';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState('tab2');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='overflow-hidden'>
      <div className='m-4 ms-8 gradient-text text-opacity-20 text-[22px] font-semibold leading-relaxed'>
        Org SetUp
      </div>

      <div className='ms-6'>
        <div className='flex flex-col'>
          <div className={`flex my-6 mx-4 border-b`}>
            {/* <button
              className={`px-4 py-1 rounded-b-none text-sm font-bold leading-[15px] ${
                activeTab === 'tab1'
                  ? 'border-b-2 border-[#1aaef4] text-[#1aaef4]'
                  : 'border-transparent text-neutral-500'
              }`}
              onClick={() => handleTabChange('tab1')}
            >
              Preferences
            </button> */}
            <button
              className={`px-4 py-1 rounded-b-none text-sm font-bold leading-[15px] ${
                activeTab === 'tab2'
                  ? 'border-b-2 border-[#1aaef4] text-[#1aaef4]'
                  : 'border-transparent text-neutral-500'
              }`}
              onClick={() => handleTabChange('tab2')}
            >
              Structure
            </button>
            {/* <button
              className={`px-4 py-1 rounded-b-none text-sm font-bold leading-[15px] ${
                activeTab === 'tab3'
                  ? 'border-b-2 border-[#1aaef4] text-[#1aaef4]'
                  : 'border-transparent text-neutral-500'
              }`}
              onClick={() => handleTabChange('tab3')}
            >
              Location
            </button> */}
          </div>
          <div className='flex-grow'>
            {/* {activeTab === 'tab1' && <Preferences />} */}
            {activeTab === 'tab2' && <Structure />}
            {/* {activeTab === 'tab3' && <Locations />} */}
            {/* {activeTab === 'tab3' && <p>Tab3</p>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
