import React, { useState, useEffect } from "react";
// import logo from '../../assets/logos/Sustainext-logo.png';
import logo from "../../assets/logos/Sustainext-logo.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PasswordChecklist from "react-password-checklist";
import { Link } from "react-router-dom";
import { industryinfo } from "../../components/data/industryinfo";
import { useFormik } from "formik";
import { signUpSchema } from "../../components/schemas";
import axios from "axios";
import { VisibilityOff, VisibilityOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { registerUser } from "state/authSlice";
import backicon from "../../assets/images/back-icon.svg";

const initialValues = {
  name: "",
  email: "",
  lastname: "",
  cname: "",
  jobtitle: "",
  department:'',
  mobile: "",
};

const SignupWorkDetails = ({ onNextPage, onPreviousPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [conshowPassword, setConshowPassword] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [newconfirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [massgecolor, setMassgecolor] = useState("");
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [country_name, setCountryName] = useState("");
  const [cities, setCities] = useState([]);
  const [stateCode, setStatecode] = useState();
  const { values, errors, touched, handleBlur } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
  });
  console.log(
    "🚀 ~ file: Registration.jsx ~ line 25 ~ Registration ~ errors",
    errors
  );
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      lastname: "",
      password: "",
      jobtitle:'',
      department:'',
      confirmPassword: "",
      termsAndCondition: false,
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const credentials = {
      username: values.email,
      password: password,
      confirmPass: confirmPass,
    };
    dispatch(registerUser(credentials));
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordCon = () => setConshowPassword((show) => !show);
  function confirmPassword(event) {
    setConfirmPassword(event.target.value);
    let val = event.target.value;
    console.log(confirmPass);
    if (password === val) {
      // console.log(confirmPass);
      setConfirmPass("New Password and Confirm Password are matched");

      setMassgecolor("rit");

      // setConfirmPass("Password matched");
    } else if (password == "") {
      setConfirmPass("");
    } else {
      // console.log(confirmPass);
      setConfirmPass("New Password and Confirm Password are not matched");
      setMassgecolor("worng");
    }
  }

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };
  const [industry, setIndustry] = useState();
  const handleChangeindustry = (event) => {
    setIndustry(event.target.value);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Use a regular expression to check if the input contains only alphabetic characters
    const isValidInput = /^[A-Za-z]*$/.test(value);


    if (isValidInput) {
      // Update the state or formik values if the input is valid
      formik.setFieldValue(name, value);
    }
  };
  return (
    <>
      <div className="min-h-screen place-items-center mt-10 to-teal-500 flex flex-wrap justify-center ">
        <div className="h-full bg-[#fff] w-[80%] ">
          <div className="flex flex-1 flex-col justify-center py-2   ">
            <div className="">
              <div className="">
                {/* <Link to="/login"> */}
                <img
                  className="h-[2rem] hover:bg-sky-50 cursor-pointer"
                  src={backicon}
                  alt="Sustainext"
                  onClick={onPreviousPage}
                />
                {/* </Link> */}
              </div>
              <h2 className="mt-10  text-[24px] font-medium gradient-heading">
                Enter Work Details
              </h2>
              <p className="mt-[10px] mb-4 text-start font-semibold text-[16px] text-color-login">
                Already have an account ?
                <Link
                  to="/login"
                  className="font-weight-400  ml-2 hover-underline-blue"
                  style={{ color: "#00AEEF" }}
                >
                  Log in
                </Link>
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mb-6 mt-2">
              <div className="mr-4">
                <label
                  htmlFor="cname"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Company name
                </label>
                <div className="">
                  <input
                    id="cname"
                    name="cname"
                    type="text"
                    autoComplete="cname"
                    required
                    placeholder="Wayne Enterprise"
                    className="block w-full cursor-not-allowed rounded-md border-0 py-4 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={values.cname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled
                  />
                  {errors.cname && touched.cname ? (
                    <p className="text-red-600">{errors.cname}</p>
                  ) : null}
                </div>
              </div>

              <div className="mr-2">
                <label
                  htmlFor="industry"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Industry
                </label>
                <div className="">
                  <select
                    className="block w-full cursor-not-allowed  border-0  rounded-md py-[18px] pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleChangeindustry}
                    required
                    value={industry}
                    disabled
                  >
                    {industryinfo.map((option) => (
                      <option value={option.name} className="text-gray-300">
                        {" "}
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mb-6">
              <div className="mr-4">
                <label
                  htmlFor="jobtitle"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Job title
                </label>
                <div className="">
                  <input
                    id="jobtitle"
                    name="jobtitle"
                    type="text"
                    autoComplete="jobtitle"
                    pattern="^[A-Za-z]+$" // Use the regex pattern to restrict to alphabetic characters
                    required
                    placeholder="Job title"
                    className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                    value={formik.values.jobtitle}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="mr-2">
                <label
                  htmlFor="department"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Department
                </label>
                <div className="">
                  <input
                    id="department"
                    name="department"
                    type="text"
                    autoComplete="department"
                    required
                    placeholder="Enter the department"
                    className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                    value={formik.values.department}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {errors.department && touched.department ? (
                    <p className="text-red-600">{errors.department}</p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center pt-6">
              <div className="mr-2 w-[48.5%] submit-button">
                <button
                  onClick={onNextPage}
                  type="submit"
                  className="flex w-full justify-center rounded-md  px-3 py-2.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-[#42CC71] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  CREATE ACCOUNT
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignupWorkDetails;
