import React from "react";

const SasbIndustry = () => {
  return (
    <div className="table-auto">
      <table className="">
        <thead className=" border-b">
          <tr>
            <th className="p-4 ">Environment</th>
            <th className="p-4 ">Social Capital</th>
            <th className="p-4 ">Human Capital</th>
            <th className="p-4 ">Business Model & Innovation</th>
            <th className="p-4 ">Leadership & Governance</th>
          </tr>
        </thead>
        <tbody >
          <tr className="border-b">
            <td className="p-4 text-sm text-center ">GHG Emissions</td>
            <td className="p-4 text-sm text-center ">Human Rights & Community Relations</td>
            <td className="p-4 text-sm text-center ">Labor Practices</td>
            <td className="p-4 text-sm text-center">Product Design & Lifecycle Management </td>
            <td className="p-4 text-sm text-center">Business Ethics </td>
          </tr>
          <tr className="border-b">
            <td className="p-4 text-sm text-center ">Air Quality</td>
            <td className="p-4 text-sm text-center ">Customer Privacy</td>
            <td className="p-4 text-sm text-center">Employee Health & Safety</td>
            <td className="p-4 text-sm text-center">Business Model Resilience</td>
            <td className="p-4 text-sm text-center">Competitive Behavior</td>
          </tr>
          <tr className="border-b">
            <td className="p-4 text-sm text-center ">Energy Management </td>
            <td className="p-4 text-sm text-center">Data Security</td>
            <td className="p-4 text-sm text-center">Employee Engagement, Diversity & Inclusion</td>
            <td className="p-4 text-sm text-center">Supply Chain Management</td>
            <td className="p-4 text-sm text-center">
              Management of the Legal & Regulatory Environment
            </td>
          </tr>
          <tr className="border-b">
            <td className="p-4 text-sm text-center">Water & Wastewater Management</td>
            <td className="p-4 text-sm text-center">Access & Affordability</td>
            <td className="p-4 text-sm text-center"></td>
            <td className="p-4 text-sm text-center">Materials Sourcing & Efficiency </td>
            <td className="p-4 text-sm text-center">Critical Incident Risk Management</td>
          </tr>
          <tr className="border-b">
            <td className="p-4 text-sm text-center ">Ecological Impacts</td>
            <td className="p-4 text-sm text-center">Customer Welfare</td>
            <td className="p-4 text-sm text-center"></td>
            <td className="p-4 text-sm text-center">Physical Impacts of Climate Change</td>
            <td className="p-4 text-sm text-center">Systemic Risk Management</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SasbIndustry;
