import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import CollectEmission from "./CollectEmission";
import ExpandableComponent from "components/ExpandableComponent";
import { scope1Info, scope2Info, scope3Info } from "components/data/scopeInfo";
import axios from "axios";
import {
  setRowsStateNew,
  setNextQuarter,
  selectRowsStateByMonth,
} from "state/emissionSlice";
import CalculateSuccess from "components/react-modal/successCalculate";
import ShowEmission from "./ShowEmission";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { setLoading } from "state/authSlice";

const initialErrorState = {
  error: false,
  1: { subCategory: false, activity: false, value: false, unit: false },
  2: { subCategory: false, activity: false, value: false, unit: false },
  3: { subCategory: false, activity: false, value: false, unit: false },
};

const Emissions = () => {
  //loader
  const [loopen, setLoOpen] = useState(false);
  const LoaderOpen = () => {
    setLoOpen(true);
  };
  const LoaderClose = () => {
    setLoOpen(false);
  };
  const location = useSelector((state) => state.emission?.location);
  const year = useSelector((state) => state.emission?.year);
  const quarter = useSelector((state) => state.emission?.quarter);

  const rowsData = useSelector((state) =>
    selectRowsStateByMonth(state, quarter)
  );
  const token = useSelector((state) => state.auth?.authToken);
  const loginUser = localStorage.getItem("email");
  const orgName = useSelector((state) => state.global.orgName);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const refreshPage = useSelector((state) => state.emission.refreshPage);

  const [locError, setLocError] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();

  const [calculating, setCalculating] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [monthsCalculated, setMonthsCalculated] = useState([]);
  const [response, setResponse] = useState({
    location: "",
    month: "",
    monthly_emissions: "",
    message: "",
  });

  const handleCalculate = async (event) => {
    LoaderOpen();

    try {
      if (!location) {
        setLocError(true);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        // Assuming handleSubmitData contains async logic
        await handleSubmitData(event);
          
        if (!monthsCalculated.includes(quarter)) {
          setCalculating(true);

          setTimeout(() => {
            setCalculating(false);
            setCalculated(true);

            if (!monthsCalculated.includes(quarter)) {
              setMonthsCalculated((prevMonthsCalculated) => [
                ...prevMonthsCalculated,
                quarter,
              ]);
            }
            openModal();
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error in handleCalculate:", error);
      closeModal();
    } finally {
      // Always close the loader when the operation is completed
      LoaderClose();
    }
    LoaderClose();
  };

  useEffect(() => {
    if (location) setLocError(false);
  }, [location]);

  useEffect(() => {
    if (monthsCalculated.includes(quarter)) {
      setCalculated(true);
    } else {
      setCalculated(false);
    }
  }, [quarter]);

  // Sending data for batch emission estimation

  const fetchData = async () => {
    LoaderOpen();
    try {
      setRows1([]);
      setRows2([]);
      setRows3([]);
      setMonthlyEmissions(0);

      const username = localStorage.getItem('email');

      const url = `${process.env.REACT_APP_BACKEND_URL}/emissions/?username=${username}&location=${location}&year=${year}&month=${quarter}`;

      const response = await axios.get(url);
      if (response.data === "empty batch , no row data available") {
        setRows1([]);
        setRows2([]);
        setRows3([]);
        setMonthlyEmissions(0);
      } else {
        setRows1(response.data[1]);
        setRows2(response.data[2]);
        setRows3(response.data[3]);
        console.log("emission", response.data.monthly_emissions[0].total_co2e);
        setMonthlyEmissions(response.data.monthly_emissions[0].total_co2e);
      }
      console.log("show emission 1", response.data[1]);
    } catch (e) {
      console.error("Error fetching emissions:", e);
    } finally {
      LoaderClose();
    }
  };

  const handleSubmitData = async (event) => {
    LoaderOpen();
    setErrorState(initialErrorState);
    event.preventDefault();
    const url = `${process.env.REACT_APP_BACKEND_URL}/calculateview/`;
    console.log("rows data:", rowsData);

    // Flag to check if there is an error
    let hasError = false;

    const keys = Object.keys(rowsData);
    const lastIndex = keys.length - 1;
    const data = keys
      .slice(0, lastIndex)
      .map((key) => {
        const rowDataArray = rowsData[key];
        const row_data = [];
        rowDataArray.forEach((rowData, rowIndex) => {
          console.log("row in calculate", rowData);
          if (rowData.category !== "") {
            if (
              rowData.subCategory === "" ||
              rowData.activity === "" ||
              !rowData.unit[0] ||
              rowData.value1 === ""
            ) {
              const scope = parseInt(key);
              setErrorState((prevErrorState) => ({
                ...prevErrorState,
                error: true,
              }));
              if (rowData.subCategory === "") {
                setErrorState((prevErrorState) => ({
                  ...prevErrorState,
                  [scope]: { ...prevErrorState[scope], subCategory: true },
                }));
              }
              if (
                !rowData.selectedActivity ||
                rowData.selectedActivity.length === 0 || rowData.activity === ""
              ) {
                setErrorState((prevErrorState) => ({
                  ...prevErrorState,
                  [scope]: { ...prevErrorState[scope], activity: true },
                }));
              }
              if (!rowData.unit[0]) {
                setErrorState((prevErrorState) => ({
                  ...prevErrorState,
                  [scope]: { ...prevErrorState[scope], unit: true },
                }));
              }
              if (rowData.value1 === "") {
                setErrorState((prevErrorState) => ({
                  ...prevErrorState,
                  [scope]: { ...prevErrorState[scope], value: true },
                }));
              }
              hasError = true; // Set the error flag
              LoaderClose();
              return;
            }
            row_data.push({
              row_number: rowIndex + 1,
              sector: rowData.category,
              category: rowData.subCategory,
              activity_data: {
                activity_id: rowData.selectedActivity?.activity_id,
                emmissionfactorid: rowData.selectedActivity?.id,
                name: rowData.selectedActivity?.name,
              },
              value1: parseFloat(rowData.value1),
              value2: rowData.value2 !== "" ? parseFloat(rowData.value2) : "",
              unit_type: rowData.unitType.toLowerCase(),
              unit1: rowData.unit[0],
              unit2: rowData.unit[1],
              file: rowData.file || null,
              assign_to: rowData.assignTo ||"",
              filename: rowData.fileName || "no filename found",
              modifiedTime: rowData.modifiedTime || "no time found",
              uploadedBy: rowData.uploadedBy || "no user",
            });
          }
        });

        return {
          no: parseInt(key),
          row_data: row_data,
        };
      })
      .filter((item) => item.row_data.length > 0);

    // Check if there was an error, and if so, don't proceed with the API call
    if (hasError) {
      LoaderClose();
      return;
    }

    console.log("data", data);
    const payload = {
      username: loginUser,
      organization: orgName,
      batch_data: {
        location: location || "Head Office",
        year: year || "2002",
        month: quarter || "APR",
        data: data,
      },
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    try {
      const response = await axios.post(url, payload, {
        headers: headers,
      });
      await setResponse({
        location: response.data.location,
        month: response.data.month,
        monthly_emissions: response.data.monthly_emissions,
        message: response.data.message,
      });
      console.log("Data Sent Successfully:", response.data);
      fetchData();
      dispatch(
        setRowsStateNew({
          scope: 1,
          quarter,
          rows: [
            {
              category: "",
              subCategory: "",
              activities: [],
              value1: "",
              value2: "",
              unit: ["", ""],
              fileRaw: "",
              file: null,
              fileName: "",
              modifiedTime: "",
              activity: "",
              selectedActivity: {},
              unitType: "",
              assignTo: "",
              uploadedBy: "",
            },
          ],
        })
      );
      dispatch(
        setRowsStateNew({
          scope: 2,
          quarter,
          rows: [
            {
              category: "",
              subCategory: "",
              activities: [],
              value1: "",
              value2: "",
              unit: ["", ""],
              fileRaw: "",
              file: null,
              fileName: "",
              modifiedTime: "",
              activity: "",
              selectedActivity: {},
              unitType: "",
              assignTo: "",
              uploadedBy: "",
            },
          ],
        })
      );
      dispatch(
        setRowsStateNew({
          scope: 3,
          quarter,
          rows: [
            {
              category: "",
              subCategory: "",
              activities: [],
              value1: "",
              value2: "",
              unit: ["", ""],
              fileRaw: "",
              file: null,
              fileName: "",
              modifiedTime: "",
              activity: "",
              selectedActivity: {},
              unitType: "",
              assignTo: "",
              uploadedBy: "",
            },
          ],
        })
      );
      // LoaderClose();
      // dispatch(setNextQuarter());
    } catch (error) {
      console.error("Error in sending Emissions Data:", error);
      if (error.message) {
        setResponse({ message: "Something went wrong" });
        openModal();
      }
    } finally {
      LoaderClose();
      console.log(payload, "payload", rowsData[1]);
    }
  };

  // Show emissions logic
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [rows3, setRows3] = useState([]);
  const [monthlyEmissions, setMonthlyEmissions] = useState(null);

  useEffect(() => {
    fetchData();
  }, [location, year, quarter, refreshPage]);

  // Previous month

  const [previousMonthRows1, setPreviousMonthRows1] = useState([]);

  const [previousMonthRows2, setPreviousMonthRows2] = useState([]);

  const [previousMonthRows3, setPreviousMonthRows3] = useState([]);

  useEffect(() => {
    const fetchPreviousMonthData = async () => {
      try {
        LoaderOpen(); // Open loader at the beginning of the fetching process
  
        const previousMonths = [
          "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
          "JUL", "AUG", "SEP", "OCT", "NOV", "DEC",
        ];
        const currentMonth = quarter; // Consider renaming 'quarter' to 'currentMonth'
        const currentMonthIndex = previousMonths.indexOf(currentMonth);
        console.log(`Current Month: ${currentMonth}, Index: ${currentMonthIndex}`);
  
        if (currentMonthIndex > 0) {
          const previousMonth = previousMonths[currentMonthIndex - 1];
          const url = `${process.env.REACT_APP_BACKEND_URL}/emissions/?username=${loginUser}&location=${location}&year=${year}&month=${previousMonth}`;
          const response = await axios.get(url);
  
          if (response.data !== "empty batch, no row data available") {
            setPreviousMonthRows1(response.data[1]);
            setPreviousMonthRows2(response.data[2]);
            setPreviousMonthRows3(response.data[3]);
          } else {
            setPreviousMonthRows1([]);
            setPreviousMonthRows2([]);
            setPreviousMonthRows3([]);
          }
        } else {
          // If it's January or there's no previous month to fetch
          console.log("Setting rows to empty because it's January or no previous month.");
          setPreviousMonthRows1([]);
          setPreviousMonthRows2([]);
          setPreviousMonthRows3([]);
          // setRows1([]);
          // setRows2([]);
          // setRows3([]); 
        }
      } catch (e) {
        console.error("Error fetching previous month data:", e);
      } finally {
        LoaderClose();
      }
    };
  
    if (location && year && quarter) {
      fetchPreviousMonthData();
    }
  }, [location, year, quarter, loginUser]);
  
  

  // Previous month
  // useEffect(() => {
  //   // Function to fetch data for the previous month
  //   const fetchPreviousMonthData = async () => {
  //     try {
  //       const previousMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  //       const currentMonthIndex = previousMonths.indexOf(quarter);
        
  //       // Check if it's not January and not the first month of the year
  //       if (currentMonthIndex > 0) {
  //         const previousMonth = previousMonths[currentMonthIndex - 1];
  //         const url = `${process.env.REACT_APP_BACKEND_URL}/emissions/?username=${loginUser}&location=${location}&year=${year}&month=${previousMonth}`;
  //         const response = await axios.get(url);

  //         if (response.data !== 'empty batch , no row data available') {
  //           // Separate data into rows1, rows2, and rows3 as needed
  //           setPreviousMonthRows1(response.data[1]);
  //           setPreviousMonthRows2(response.data[2]);
  //           setPreviousMonthRows3(response.data[3]);
  //         }
  //       }
  //     } catch (e) {
  //       console.error('Error fetching previous month data:', e);
  //     }
  //   };

  //   // Fetch previous month's data when location, year, or quarter changes
  //   if (location && year && quarter) {
  //     fetchPreviousMonthData();
  //   }
  // }, []);

  return (
    <>
      <div className="relative">
        <Header
          monthsCalculated={monthsCalculated}
          error={locError}
          monthlyEmissions={monthlyEmissions}
        />
        <ExpandableComponent
          label="Scope 1"
          description="Direct emission from operations"
          expand={!!location}
          locError={setLocError}
        >
          <CollectEmission
            data={rows1}
            prevMonth={previousMonthRows1}
            monthlyEmissions={monthlyEmissions}
            scope={1}
            scopeInfo={scope1Info}
            location={location}
            year={year}
            quarter={quarter}
            errorState={errorState[1]}
            locError={setLocError}
          />
        </ExpandableComponent>
        <ExpandableComponent
          label="Scope 2"
          description="InDirect emission from operations"
          expand={location?true:false}
          locError={setLocError}
        >
          <CollectEmission
            data={rows2}
            prevMonth={previousMonthRows2}
            monthlyEmissions={monthlyEmissions}
            scope={2}
            scopeInfo={scope2Info}
            location={location}
            year={year}
            quarter={quarter}
            errorState={errorState[2]}
            locError={setLocError}

          />
        </ExpandableComponent>
        <ExpandableComponent
          label="Scope 3"
          description="All other emissions (associated)"
          expand={!!location}
          locError={setLocError}
        >
          <CollectEmission
            data={rows3}
            prevMonth={previousMonthRows3}
            monthlyEmissions={monthlyEmissions}
            scope={3}
            scopeInfo={scope3Info}
            location={location}
            year={year}
            quarter={quarter}
            errorState={errorState[3]}
            locError={setLocError}
          />
        </ExpandableComponent>
        <div className="flex justify-end items-center mt-[24] me-5">
          <button
            onClick={handleCalculate}
            className="w-[172px] h-8 px-[22px] py-2 bg-sky-600 rounded shadow flex-col justify-center items-center inline-flex text-white text-xs font-bold leading-[15px]"
          >
            <div className="cursor-pointer">
              {calculating
                ? "Calculating..."
                : calculated
                ? "Calculate"
                : "Calculate"}
            </div>
          </button>
        </div>
        <ShowEmission />
        {isModalOpen && !errorState.error && (
          <CalculateSuccess onClose={closeModal} data={response} />
        )}
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Emissions;