import React from "react";

const MaterialityMatrix = () => {
  return (
    <>
      <div className="">
        <div className="pt-10">
          <p className="font-semibold text-lg ml-4 text-gray-700">
            Materiality Matrix:
          </p>
        </div>
        <div className="grid grid-cols-4 gap-1 pt-14">
          <div className="col-span-1 p-4 w-3/5 ms-32">
            <div className="text-rotate h-full">
              <div className="flex justify-between items-center pb-6">
                <span className="mt-auto font-bold text-sm">Low</span>
                <span className="mt-auto font-bold text-sm">Med</span>
                <span className="mt-auto font-bold text-sm">High</span>
              </div>
              <div className="mb-2 text-center px-1 pl-10 pt-16 font-bold text-lg">
                Stakeholder Responses
              </div>
            </div>
          </div>
          <div className="col-span-2 border-s-2 border-gray-400 mb-8 mt-20">
            <div className="grid grid-cols-3 gap-5 ps-3 gap-x-10 ">
              <div className="bg-green-600 p-4 my-custom-class relative w-40 h-40 hover:scale-110 transition-transform duration-300 ease-in-out">
                {" "}
                <p className="text-xs text-white font-bold">Emissions</p>
              </div>
              <div className="bg-green-600 p-4 my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out">
                <p className="text-xs text-white font-bold">
                  Customer Data Privacy
                </p>
                <p className="text-xs text-white font-bold">
                  Climate chnage and Sustainibility
                </p>
              </div>
              <div className="bg-green-600 p-4 my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out">
                <p className="text-xs text-white font-bold">
                  Corporate Governance
                </p>
                <p className="text-xs text-white font-bold">
                  Human Capital Development
                </p>
                <p className="text-xs text-white font-bold">
                  Data Security Practices
                </p>
              </div>
              <div className="bg-blue-600 p-4  text-white font-bold my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out"></div>
              <div className="bg-blue-600 p-4  text-white font-bold my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out"></div>
              <div className="bg-green-600 p-4  text-white font-bold my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out"></div>
              <div className=" bg-blue-950 p-4  my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out">
                <p className="text-xs text-white font-bold">Energy</p>
                <p className="text-xs text-white font-bold">Water</p>
                <p className="text-xs text-white font-bold">Waste</p>
                <p className="text-xs text-white font-bold">
                  Political Contributions
                </p>
                <p className="text-xs text-white font-bold">
                  Local Communities
                </p>
              </div>
              <div className="bg-blue-600 p-4 my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out">
                <p className="text-xs text-white font-bold">
                  Customer Health & Safety
                </p>
                <p className="text-xs text-white font-bold">
                  Occupation Health and Safety
                </p>
              </div>
              <div className="bg-green-600 p-4  break-all my-custom-class relative w-40 h-40  hover:scale-110 transition-transform duration-300 ease-in-out">
                <p className="text-xs text-white font-bold">
                  Human Rights Ethics
                </p>
                <p className="text-xs text-white font-bold">Risk Management</p>
                <p className="text-xs text-white font-bold">
                  Economic Performance
                </p>
                <p className="text-xs text-white font-bold">
                  Diversity & Inclusions
                </p>
                <p className="text-xs text-white font-bold">
                  Supply Chain Sustainability
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="m-[-60px] border-t-2 border-gray-400 mx-40 pl-4">
          <div className="flex justify-evenly items-center pt-2">
            <span className="mt-auto font-bold text-sm">Low</span>
            <span className="mt-auto font-bold text-sm">Med</span>
            <span className="mt-auto font-bold text-sm">High</span>
          </div>
          <div className="mb-2 text-center pt-8 pb-40 font-bold text-lg">
            Organizational Impact
          </div>
        </div>
      </div>
    </>
  );
};

export default MaterialityMatrix;
