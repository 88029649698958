import React, { useState } from "react";

const dateFormatOptions = [
  { label: "MM/DD/YYYY", value: "MM/DD/YYYY" },
  { label: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { label: "YYYY/MM/DD", value: "YYYY/MM/DD" },
];

const currencyOptions = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" },
];

const timezones = [
  { label: "UTC-12:00", value: "-12:00" },
  { label: "UTC-11:00", value: "-11:00" },
  { label: "UTC-10:00", value: "-10:00" },
  // Add more timezone options here as needed
];

const reportFramework = ["GRI", "CDP", "SASB", "TCFD", "BRSR"];

const initialState = {
  generalDetails: {
    name: "",
    email: "",
    phone: "",
    website: "",
    type: "",
    ownership: "",
    location: "",
    Empcount: "",
    revenue: "",
    mobile: "",
    fax: "",
    sector: "",
    subIndustry: "",
  },
  addressInformation: {
    country: "",
    state: "",
    city: "",
    street: "",
    zipCode: "",
  },
  reportingPeriodInformation: {
    fromDate: "",
    toDate: "",
    reportingFramework: "",
  },
};

const SustainabilityContext = ({ handleGeneralDetailsSubmit, heading }) => {
  const [formData, setFormData] = useState(initialState);

  const handleGeneralDetailsChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      generalDetails: {
        ...prevFormData.generalDetails,
        [name]: value,
      },
    }));
  };

  const handleAddressInformationChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      addressInformation: {
        ...prevState.addressInformation,
        [name]: value,
      },
    }));
  };

  const handleReportingPeriodChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      reportingPeriodInformation: {
        ...prevState.reportingPeriodInformation,
        [name]: value,
      },
    }));
  };

  const handleDateFormatChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      generalDetails: {
        ...prevFormData.generalDetails,
        dateFormat: selectedOption.value,
      },
    }));
  };

  const handleCurrencyChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      generalDetails: {
        ...prevFormData.generalDetails,
        currency: selectedOption.value,
      },
    }));
  };

  const [selectedTimezone, setSelectedTimezone] = useState(
    formData.generalDetails.timezone
  );

  const handleTimezoneChange = (event) => {
    const value = event.target.value;
    setSelectedTimezone(value);
  };

  const [languages, setLanguages] = useState([]);

  const [selectedFrameworks, setSelectedFrameworks] = useState([]);

  function handleFrameworkChange(event) {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedFrameworks([...selectedFrameworks, value]);
    } else {
      setSelectedFrameworks(
        selectedFrameworks.filter((framework) => framework !== value)
      );
    }
  }

  return (
    <>
      <h2 className="text-2xl font-bold mb-8 text-gray-700">
        Organization Details:
      </h2>
      <div className="">
        <div className="flex flex-row">
          <div className="flex text-sm">
            <p className="font-bold">Legal Name -</p>
            <p className="mx-4"> World Tech Digital</p>
          </div>
          <div className="flex text-sm ml-40">
            <p className="font-bold ">Type of Corporate Entity -</p>
            <p className="mx-4"> Private Limited</p>
          </div>
        </div>
      </div>
      <div className="bg-white-100 pt-8">
        <div className="flex flex-row">
          <div className="flex  text-sm ">
            <p className="font-bold ">Ownership -</p>
            <p className="mx-4"> Enterprenuer</p>
          </div>
          <div className="flex text-sm ml-48">
            <p className="font-bold ">Location Of Headquarters -</p>
            <p className="mx-4"> Washington</p>
          </div>
        </div>
      </div>
      <div className="pt-8">
        <div className="flex flex-row">
          <div className="flex  text-sm ">
            <p className="font-bold ">Employee Count -</p>
            <p className="mx-4"> 7400</p>
          </div>
          <div className="flex text-sm ml-52">
            <p className="font-bold ">Revenue -</p>
            <p className="mx-4"> $123000</p>
          </div>
        </div>
      </div>
      <div className="pt-8">
        <div className="flex flex-row">
          <div className="flex text-sm ">
            <p className="font-bold ">Sector -</p>
            <p className="mx-4"> Energy</p>
          </div>
          <div className="flex text-sm  ml-64">
            <p className="font-bold ">Sub-Industry -</p>
            <p className="mx-4"> Oil & Petroleum</p>
          </div>
        </div>
      </div>
      

      <h3 className="text-lg font-semibold mb-6 mt-[3rem] text-gray-700">
        REPORTING PERIOD
      </h3>
      <div className="grid grid-cols-2 gap-5">
        <div className="space-y-3">
          <label
            htmlFor="fromDate"
            className="block text-sm font-semibold text-gray-700"
          >
            From Date
          </label>
          <div className="border border-gray-300  rounded-md w-full p-2 text-sm">
            01/01/2023
          </div>
        </div>
        <div className="space-y-3">
          <label
            htmlFor="toDate"
            className="block text-sm font-semibold text-gray-700"
          >
            To Date
          </label>

          <div className="border border-gray-300  rounded-md w-full p-2 text-sm">
            31/12/2023
          </div>
        </div>
      </div>
    </>
  );
};

export default SustainabilityContext;
