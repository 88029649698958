import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const MonthButton = ({ month, handleMonthClick, monthsCalculated }) => {
  const selectedMonth = useSelector((state) => state.emission.quarter);

  useEffect(() => {
    if (selectedMonth === month) {
      console.log({ selectedMonth, month });
    }
  }, [selectedMonth]);

  const isCalculated = monthsCalculated.includes(month);
  const isSelected = month === selectedMonth;

  return (
    <div className='w-16 h-6 justify-center items-center inline-flex'>
      <div className='h-6 justify-center items-center inline-flex'>
        <div className='w-16 flex-col justify-start items-center inline-flex hover:bg-gray-200 hover:rounded-md cursor-pointer'>
          <div
            className={`text-center ${
              isSelected ? 'bg-white gradient-text-green  rounded-md shadow-md' : 'text-neutral-400'
            } text-xs font-bold leading-none cursor-pointer w-full h-7 pt-2 bg-[#f7f7f7] `}
            onClick={() => handleMonthClick(month)}
          
          >
            {month}
          </div>
        </div>
        <div className='w-px h-[18px] bg-zinc-500 bg-opacity-20 rounded-sm' />
      </div>
    </div>
  );
};

export default MonthButton;
