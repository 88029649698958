import React, { useState } from 'react';
import Modal from 'react-modal';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          width: '415px',
          height: '178px',
          padding: '20px',
          borderRadius: '8px',
          backgroundColor: 'white',
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <div className='self-stretch justify-start items-start gap-5 inline-flex'>
        <div className='grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex'>
          <div className="self-stretch text-gray-900 text-[15px] font-bold font-['Manrope'] leading-tight">
            Delete row
          </div>
          <div className="self-stretch text-neutral-500 text-[13px] font-normal font-['Manrope'] leading-none">
            {message} This action cannot be undone.
          </div>
        </div>
      </div>
      <div className='self-stretch justify-start items-start gap-3 inline-flex mt-6'>
        <div className='w-[178px] h-[38px] px-[22px] py-2 rounded border border-red-600 flex-col justify-center items-center inline-flex'>
          <div className='justify-center items-center gap-2 inline-flex'>
            <button
              className="text-red-500 text-xs font-bold font-['Manrope'] leading-[15px]"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className='w-[178px] px-[22px] py-2 bg-red-500 rounded shadow flex-col justify-center items-center inline-flex'>
          <div className='justify-center items-center gap-2 inline-flex'>
            <button
              className="h-[22px] text-white text-xs font-bold font-['Manrope'] leading-[15px]"
              onClick={onConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
