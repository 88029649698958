import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from './Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'state/authSlice';
import { ArrowDropDownOutlined, Logout, Person } from '@mui/icons-material';
import loginimage from './../assets/logos/profileimage.png';
import ProfileImage from './profileimge/ProfileImage';
const Navbar = ({ onProfileClick, onProfileImgClick, dropdownOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { collapsed } = useProSidebar();
  const dispatch = useDispatch();
  const fullName = localStorage.getItem("name");
  const [firstName, lastName] = fullName.split('.');
  const path = location.pathname;
  const authToken = useSelector((state) => state.auth.authToken) || localStorage.getItem('authTokens');
  // const user = useSelector((state) => state.auth.username);
  const activeNav = useSelector((state) => state.global.activeNav);
  const loginUser = localStorage.getItem('email');
  const nspace="25px";
  const ntextsize="12px";
  const handleLogout = () => {
    console.log('logout initiated');
    dispatch(logoutUser(authToken))
      .unwrap()
      .then((response) => {
        // Successful logout
        // Perform any necessary cleanup or state updates
        // navigate('/login');
        // console.log('Logout successful');
      })
      .catch((error) => {
        console.error('Logout error:', error);
      });
  };
const newusername = localStorage.getItem("name")
  // let username = useSelector((state) => state.auth.username);
  // let tempuser = '';
  // function transformName(name) {
  //   return name
  //     .split('.')
  //     .map((word) => {
  //       return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  //     })
  //     .join(' ');
  // }

  // if (loginUser) {
  //   tempuser = loginUser.split('@')[0];
  //   // const transformedName = transformName(tempuser);
  //   // tempuser = transformedName;
  //   localStorage.setItem('name', tempuser);
  // }

  // dropdown remove
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onProfileImgClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onProfileImgClick]);

  return (
    <>
      <div
        className={`flex justify-between sticky z-50 bg-white top-0 right-0 border-b border-sky-600 border-opacity-50 pt-1 ${
          collapsed ? 'ps-4 left-[75px]' : 'ps-3 left-[250px] '
        }`}
      >
        <Breadcrumb path={path} activeTab={activeNav} />
        <div className='flex justify-center items-center'>
          <div className='me-8 flex items-center font-thin'>
            <div className='text-sky-600'>
              <span> Hi,</span>
              <span className='me-4'>{newusername}</span>
            </div>
            <div className='relative cursor-pointer'>
              <div className='flex items-baseline justify-center items-center' onClick={onProfileImgClick}>
              <ProfileImage firstName={firstName} lastName={lastName} spacing={nspace} textsize={ntextsize}/>
           <div>
           <ArrowDropDownOutlined />
           </div>
             
            
              
              </div>
              {dropdownOpen && (
                <div
                  ref={dropdownRef}
                  className='absolute bg-white border border-gray-300 rounded shadow mt-2 py-2 w-[220px] right-0'
                >
                  {/* Add your dropdown options here */}
                  <div className='px-4 py-2'>
                    <div className='self-stretch text-black text-opacity-90 text-[13px] font-normal leading-none'>
                      {newusername}
                    </div>
                    <div className='self-stretch text-black text-opacity-60 text-xs font-normal leading-[15px]'>
                      {loginUser}
                    </div>
                  </div>
                  <div className='self-stretch h-[0px] border border-black border-opacity-10'></div>

                  <button className='px-4 py-2 flex space-x-3 items-center'>
                    <Person />
                    <div
                      className='grow shrink basis-0 text-black text-opacity-90 text-[13px] font-normal leading-none'
                      onClick={onProfileClick}
                    >
                      Profile{' '}
                    </div>
                  </button>

                  <div className='self-stretch h-[0px] border border-black border-opacity-10'></div>

                  <button
                    className='px-4 py-2 flex space-x-3 items-center'
                    onClick={handleLogout}
                  >
                    <Logout className='text-red-600' />
                    <div className='grow shrink basis-0 text-red-600 text-[13px] font-normal leading-none'>
                      Log out
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
