import React,{useState} from "react";
import EnvironmentList from "./EnvironmentList";
import GovernanceList from "./GovernanceList";
import SocialList from "./SocialList";

const GenerateListofTopics = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <div className="">
        <div className={`flex my-6 mx-4 border-b`}>
          <button
            className={`px-4 py-1 font-medium rounded-md rounded-b-none ${
              activeTab === "tab1"
                ? "border-t-2 border-l-2 border-r-2 border-[#1aaef4]"
                : "border-transparent"
            }`}
            onClick={() => handleTabChange("tab1")}
          >
            Environment
          </button>
          <button
            className={`px-4 py-1 font-medium rounded-md rounded-b-none ${
              activeTab === "tab2"
                ? "border-t-2 border-l-2 border-r-2 border-[#1aaef4]"
                : "border-transparent"
            }`}
            onClick={() => handleTabChange("tab2")}
          >
            Social
          </button>
          <button
            className={`px-4 py-1 font-medium rounded-md rounded-b-none ${
              activeTab === "tab3"
                ? "border-t-2 border-l-2 border-r-2 border-[#1aaef4]"
                : "border-transparent"
            }`}
            onClick={() => handleTabChange("tab3")}
          >
            Governance
          </button>
        </div>
        <div className="flex-grow">
          {activeTab === "tab1" && (
            <div className="grid grid-cols-12 p-6">
              <EnvironmentList />
            </div>
          )}
          {activeTab === "tab2" && <SocialList />}
          {activeTab === "tab3" && <GovernanceList />}
        </div>
      </div>
    </div>
  );
};

export default GenerateListofTopics;
