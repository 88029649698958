import React, { useState, useEffect } from 'react';
import OrganizationDeatils from './OrganizationDeatils';
import SustainabilityReporting from './SustainabilityReporting';
import ReportFreqContact from './ReportFreqContact';
import RestatementofInfo from './RestatementofInfo';
import ExternalAssurance from './ExternalAssurance';
import ActivitiesandWorkers from './ActivitiesandWorkers';
import { useProSidebar } from 'react-pro-sidebar';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import GeneralSidepanel from 'components/collect/GeneralSidepanel';
import ComplianceLaws from 'pages/governance/ComplianceLaws';
import MembershipAssociations from 'pages/governance/MembershipAssociations';
import StakeholderEngagement from 'pages/governance/StakeholderEngagement';
import BargainingAgreements from 'pages/governance/BargainingAgreements';
import Employees from './Employees';
import WorkerswhoareNotemployees from './WorkerswhoareNotemployees';
import paidsubscription from '../../assets/logos/paidsubscription.svg';

const General = () => {
  const [activeTab, setActiveTab] = useState('Org Details');
  const { collapseSidebar, collapsed } = useProSidebar();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPageCount = 25;

  const sections = [
    'Board Info',
    'Structure',
    'Nomination & Selection',
    'Chair of Board',
    'Management of impact',
    'Delegation of Responsibility',
    'Sustainability Reporting',
  ];

  useEffect(() => {
    setActiveTab('Org Details');
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const CurrentPageComponent = components[currentPage - 1];

  const handlePrevious = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the previous one
    if (currentIndex > 0) {
      const prevTab = sections[currentIndex - 1];
      setActiveTab(prevTab);
      setCurrentPage(currentIndex);
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the next one
    if (currentIndex < sections.length - 1) {
      const nextTab = sections[currentIndex + 1];
      setActiveTab(nextTab);
      setCurrentPage(currentIndex + 2); // Add 2 since currentPage is 1-indexed
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = () => {
    // Handle the submit action here
  };

  const isLastPage = currentPage === totalPageCount;
  const isFirstPage = currentPage === 1;

  return (
    <>
      <div className='h-full relative'>
        {showModal && (
          <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-10 backdrop-blur-sm flex items-center justify-center'>
            <div className='bg-white rounded-lg p-4'>
              <img src={paidsubscription} alt='logo' />
            </div>
          </div>
        )}
        {/* <div className="container"> */}
        <div className='flex'>
          <GeneralSidepanel
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <div
            className={`${
              collapsed ? 'max-w-[83%] py-8' : 'max-w-[70%] py-8'
            } transition-all`}
          >
            {activeTab === 'Org Details' && <OrganizationDeatils />}
            {activeTab === 'Entities' && <SustainabilityReporting />}
            {activeTab === 'Report Details' && <ReportFreqContact />}
            {activeTab === 'Restatement' && <RestatementofInfo />}
            {activeTab === 'Assurance' && <ExternalAssurance />}
            {activeTab === 'Business Details' && <ActivitiesandWorkers />}
            {activeTab === 'Workforce-Employees' && <Employees />}
            {activeTab === 'Workforce - Other Workers' && (
              <WorkerswhoareNotemployees />
            )}
            {activeTab === 'Laws & Regulation' && <ComplianceLaws />}
            {activeTab === 'Membership & Association' && (
              <MembershipAssociations />
            )}
            {activeTab === 'Stakeholder Engagement' && (
              <StakeholderEngagement />
            )}
            {activeTab === 'Collective Bargaining Agreements' && (
              <BargainingAgreements />
            )}
          </div>
        </div>
        {/* </div> */}
        <div className='flex justify-center absolute bottom-2 w-full'>
          <p className='text-gray-500 text-md font-bold pb-10'>
            Page {currentPage}/{totalPageCount}
          </p>
        </div>
        <div className='flex justify-between  absolute bottom-4 w-[96%]'>
          <button
            className='bg-gray-200 px-4 py-2 rounded-md text-gray-700 disabled:opacity-50'
            onClick={handlePrevious}
            disabled={isFirstPage}
          >
            <ArrowBack />
          </button>

          {isLastPage ? (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleNext}
            >
              <ArrowForward />
            </button>
          )}
        </div>
      </div>
      {totalPageCount && <div className='h-16' />}{' '}
      {/* Add space at the bottom */}{' '}
    </>
  );
};

export default General;
