import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import { Energydata } from "components/data/info/energydata";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Materialsheader from '../header/Materialsheader';
import Materialsthreebody from './materials3-body';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {
  return (
    <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#000',
          color: 'white',
          fontSize: '12px',
          boxShadow: 3,
          borderRadius: "8px"
        },
      },
      arrow: {
        sx: {
          color: '#000',
        },
      },
    }}>
      <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px", color: "#000" }} />
    </Tooltip>
  );
};
const Materials3 = () => {
  const [activeMonth, setActiveMonth] = useState("Jan");
  const [data, setData] = useState();
  const [category, setCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { collapsed } = useProSidebar();
  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
  }
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    setCategory(selected);
  };
  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  }, [category])

  return (
    <>
      <div className="flex flex-col justify-start overflow-x-hidden ">


        <div className="flex justify-between items-center border-b border-gray-200 mb-5">
          <div
            className={`${collapsed ? "w-[90%] " : "w-[75%]"
              } flex justify-between items-center`}
          >
            <div className="text-left mb-4 ml-3 pt-5">
              <p className="text-sm">Environment</p>
              <div className='flex'>
                <div>
                  <p className="gradient-text text-[22px] font-bold">
                    Materials
                  </p>
                </div>

              </div>

            </div>
            <div className="flex  absolute right-[4.5rem] border-l">
              <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('41')}>GRI 301-3</button>
              <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('42')}>SDG 8</button>
              <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 " onClick={() => toggleDrawer('43')}>SDG 12</button>
            </div>
          </div>

        </div>
        <div className="ml-3">
          <h6 className="text-[17px] mb-4 font-semibold">
            Disclosure 301-3-a,b Reclaimed products and their packaging materials
            <NewTooltip tooltiptext="This section documents data corresponding to the amount of recycled material used
for packaging of the goods/services during the reporting period.
Reclaimed products are products that have been used and then collected,
processed, and marketed for reuse. They can be reused in their original form
 or they can be used to manufacture new products.
Examples include refurbished electronics, recycled clothing, reclaimed wood, and
reclaimed building materials.
Exclude: rejects and recalls of products" />
          </h6>
        </div>
        <div className={`${isOpen ? "translate-x-[15%] block" : "translate-x-[120%] hidden"}
      fixed right-[51px]  w-[340px] h-full bg-white  rounded-md
      transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}>

          {data && data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">
                  {program.header}
                </div>

                <div className="ml-2 float-right">
                  <h5 className="text-[#727272] text-[17px] font-bold cursor-pointer" onClick={toggleDrawerclose}><ClearOutlinedIcon /></h5>
                </div>

              </div>
              <div> {program.data}</div>
            </>
          ))}

        </div>
      </div>
      <Materialsheader activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
      <Materialsthreebody />

    </>

  );
};

export default Materials3;
