import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {
    return (
        <Tooltip
            title={tooltiptext}
            arrow
            placement="top"
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: "#000",
                        color: "white",
                        fontSize: "12px",
                        boxShadow: 3,
                        borderRadius: "8px",
                    },
                },
                arrow: {
                    sx: {
                        color: "#000",
                    },
                },
            }}
        >
            <InfoOutlinedIcon sx={{ fontSize: "14px", color: "#000" }} />
        </Tooltip>
    );
};
const Datacompilationtodisposal = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-sm text-[#727272]'>Provide contextual information necessary to understand the data and how the data has been compiled.
                            <NewTooltip tooltiptext="Please provide contextual information necessary to understand the data and how the data has been compiled. e.g. any standards, methodologies etc. used for data compilation. " />
                            </h6>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}
                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Datacompilationtodisposal;