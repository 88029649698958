import React from 'react';

const Index = () => {
  const appVersion = '0.1.2108';
  const lastVersions = ['0.1.1807', '0.1.2008'];

  return (
    <div className='flex justify-center my-6'>
      <h1>
        App Version: <span className='text-blue-300 text-lg'>{appVersion}</span>{' '}
      </h1>
    </div>
  );
};

export default Index;
