import React, { useState } from "react";

const RisksandOpportunities = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.1</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
        Financial implications and other risks and opportunities due to climate
        change
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 201-2
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Risks and opportunities posed by climate change that have the
                potential to generate substantive changes in operations,
                revenue, or expenditure, including
              </h2>
            </label>

            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full  items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1  w-full"
                >
                  <h2 className="text-gray-600">
                    A description of the risk or opportunity and its
                    classification as either physical, regulatory, or other
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    A description of the impact associated with the risk or
                    opportunity
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    The financial implications of the risk or opportunity before
                    action is taken
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    The methods used to manage the risk or opportunity
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    The costs of actions taken to manage the risk or opportunity
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    If currently no system is in place to calculate the
                    financial implications or costs, or to make revenue
                    projections, report plans and timeline to develop the
                    necessary systems
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RisksandOpportunities;
