const Table = () => {
  return (
    <div className="my-4 mx-5 text-left container ">
      <div className="mb-2  py-4 px-3 shadow-md ">
        <h1 className="text-left mb-5 text-lg ">
          <b>Dataframe View</b>
        </h1>
        <div className="overflow-x-auto w-[97%]">
          <div className="w-[200%]">
            <table className="min-w-full leading-normal border border-slate-200 rounded-lg  ">
              <thead className="border-s-slate-200 ">
                <tr className="border-s-slate-200 justify-between">
                  <th className=" py-3  px-1 w-[8%]  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider ">
                    Scenario Name
                  </th>
                  <th className=" py-3  px-1  w-[8%]  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Current Employees
                  </th>
                  <th className=" py-3  px-1 w-[5%]  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Year
                  </th>
                  <th className=" py-3  px-1 w-[5%]  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Net-Zero Year
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Distance Travelled (Company Owned Cars)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Distance Travelled (Member Owned Cars)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Air Distance Travelled (Short Haul)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Air Distance Travelled (Medium Haul)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Air Distance Travelled (Long Haul)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    RE Consumption (Grid Electricity)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    NRE Consumption (Grid Electricity)
                  </th>
                  <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Public Transport Emissions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-left">
                    Scope 1
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
