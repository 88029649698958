import React, { useState } from "react";
import MaterialityMatrix from "./materiality-matrix/MaterialityMatrix";
import Environmental from "./table-components/Environmental";
import Social from "./table-components/Social";
import Governance from "./table-components/Governance";

const PreviousMaterialTopics = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeRow, setActiveRow] = useState(null);

  const years = Array.from({ length: 10 }, (_, index) => 2023 - index);

  // Handle year selection
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <div className="container mx-auto ">
        <h1 className="p-4 text-2xl font-bold text-gray-700">
          Update & Review
        </h1>

        <h1 className="p-4 text-lg font-semibold text-gray-700">
          Previous Reporting Year's Material Topics
        </h1>

        <div className="p-4">
          <label htmlFor="year" className="block font-medium  mb-2">
            Select a Year:
          </label>
          <select
            id="year"
            value={selectedYear}
            onChange={handleYearChange}
            className="border border-gray-300 rounded p-1"
          >
            <option clvalue="">-- Select --</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          {selectedYear && (
            <p className="mt-2">Selected Year: {selectedYear}</p>
          )}
        </div>

        {/* Section and Sub section */}

        {/* Main Layout */}
        <div className="grid grid-cols-12 p-6">
          <Environmental />
          <Social />
          <Governance />
        </div>
         <div className="div">
            <MaterialityMatrix />
         </div>
        {/* <MaterialityMatrix /> */}
      </div>
    </>
  );
};

export default PreviousMaterialTopics;
