import React,{useState} from "react";
import ReactDOM from "react-dom";
import aboardimage from "../../assets/images/aboardimage.svg";
import sustainextlogo from "../../assets/logos/Sustainext-logo.png";

const WelcomeModal = ({ onContinue, onClose}) => {

  return ReactDOM.createPortal(
    <>
      {/* Background overlay */}
      <div className="modal-container">

      {/* Modal content */}

      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 welcome-screen">
        <div className="modal-content relative flex bg-white rounded-md p-6 w-[800px] overflow-auto">
           {/* Close icon */}
           <button
            onClick={() => onClose("welcomeModal")}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="w-1/2 flex flex-col">
            <div className="w-[130px]">
              <img src={sustainextlogo} alt="logo" />
            </div>
            <div className="mt-28">
              <h1 className="text-3xl font-bold mb-4 text-start m-4 text-gray-600">Welcome Aboard!</h1>
              <p className="text-start m-4 text-sm font-medium text-gray-700">
                We're thrilled to have you join our community of eco-conscious
                corporations.
              </p>
              <button onClick={onContinue} className="mt-5 mr-32 font-bold text-sm w-[200px] h-[35px] text-white rounded-md bg-blue-500 shadow-md">Continue</button>
            </div>
          </div>

          <div className="w-1/2 mt-4 flex justify-between items-center">
            <img src="https://sustainextstorage1.blob.core.windows.net/sustainext-frontend-assets/Home/s1.png" alt="logo" />
          </div>
        </div>
      </div>
      </div>
    </>,
    document.body // This is the target container where the modal will be rendered outside the main content flow
  );
};

export default WelcomeModal;

