import React, { useState, useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const NewTooltip = ({ tooltiptext }) => {


    return (
      <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#000',
            color: 'white',
            fontSize: '12px',
            boxShadow: 3,
            borderRadius: "8px"
          },
        },
        arrow: {
          sx: {
            color: '#000',
          },
        },
      }}>
        <InfoOutlinedIcon sx={{ fontSize: "14px", }} />
      </Tooltip>
    );
  };
const Sectionone = () => {
    const { collapseSidebar, collapsed } = useProSidebar();
    const [isClicked, setIsClicked] = useState(false);
    const [tableData, setTableData] = useState([
        ["Life Insurance", true, true, true],
        ["Disability & Invalidity Coverage", true, true, true],
        ["Parental Leave", true, true, true],
        ["Retirement Provision", true, true, true],
        ["Stock Ownership", true, true, true],
        ["Others", true, true, true],
      ]);
     
    const addBenefit = () => {
        setTableData((prevData) => {
          const newData = [...prevData];
          const newRow = new Array(newData[0].length).fill("");
          newData.push(newRow);
          return newData;
        });
      };
    
    
    
      const handleCellChange = (value, rowIndex, cellIndex) => {
        setTableData((prevData) => {
          const newData = [...prevData];
          if (rowIndex === 0 || cellIndex === 0) {
            newData[rowIndex][cellIndex] = value;
          } else {
            newData[rowIndex][cellIndex] = {
              category: value,
              management: value,
              company: value,
            };
          }
          return newData;
        });
      };
    
      const handleeditClick = () => {
        setIsClicked(!isClicked);
      };
    return (
<>
{isClicked ? (
        <>
          <div className="flex justify-between">
            <div className="ml-3">
              <h6 className="text-[15px] mb-4"> Benefits provided to full-time employees that are not provided to temporary or part-time employees <NewTooltip tooltiptext="This table documents data corresponding to the standard benefits offered to full-time employees 
of the organization, which are generally not available to temporary or part-time employees." />
              </h6>
            </div>
            <div className="flex justify-between gap-2">
              <div className="bg-blue-100 h-[25px] rounded-md">
                <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-2a</p>
              </div>
              <div>
                <CloseOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handleeditClick}
                />
                <SaveOutlinedIcon
                  sx={{ ml: 2, cursor: "pointer" }}

                />
              </div>
            </div>
          </div>
          <div className=" w-full mx-1">
            <table className="mb-5 w-full text-xs text-start shadow-lg rounded-md px-2">
              <thead className="gradient-background h-[40px] ">
                <tr>
                  <th className="py-2 font-normal text-left px-2">Benefits <NewTooltip tooltiptext="benefit definition: 
direct benefit provided in the form 
of financial contributions, 
care paid for by the organization, or
the reimbursement of expenses 
borne by the employee. 

Example: Life Insurance, Health Care, 
Coverage, Parental Leave, 
Retirement Provision, Stock Ownership etc. " /></th>
                  <th className="py-2 font-normal text-left">Full-Time Employees <NewTooltip tooltiptext="Please select  if 
 this benefit is 
standard for all 
 full-time employees. 
Unselect  if it's not 
offered." /></th>
                  <th className="py-2 font-normal text-left">Part-Time Employees <NewTooltip tooltiptext="Please select  if 
this benefit 
is standard for 
all Part-time employees. 
Unselect  if it's not offered ." /></th>
                  <th className="py-2 font-normal text-left">Temporary Employees <NewTooltip tooltiptext="Please select  if this 
benefit is 
standard for all Temporary 
employees. 
Unselect  if it's not offered." /></th>
                  <th className="py-2 font-normal text-left">Significant location of operations <NewTooltip tooltiptext="This section allows you to enter
 the organization's significant 
locations of operation where the 
listed benefit is offered to full-time 
or part-time employees." /></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex + 1} >
                    {row.map((cell, cellIndex) => (
                      <>
                        <td
                          key={cellIndex}
                          className="py-2 font-bold text-gray-600 pl-2"
                        >
                          {cellIndex === 0 ? (
                            <input
                              type="text"
                              placeholder="Enter a text"
                              className="w-full py-1 "
                              value={cell}
                              onChange={(e) =>
                                handleCellChange(
                                  e.target.value,
                                  rowIndex + 1,
                                  cellIndex
                                )
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="w-full text-center"
                              checked={cell.management && cell.company}
                              onChange={(e) =>
                                handleCellChange(
                                  e.target.checked,
                                  rowIndex + 1,
                                  cellIndex
                                )
                              }
                            />
                          )}
                        </td>

                      </>
                    ))}
                    <td>
                      <input
                        type="text"
                        placeholder="Enter  location "
                        className="w-full py-1 text-center hover:border-slate-400 hover:border-b "

                      />
                    </td>
                    <td>
                      <DeleteOutlinedIcon sx={{ cursor: "pointer", color: "#ACACAC" }} />
                    </td>
                  </tr>

                ))}
                <tr>
                  <td className="pl-2 py-2">
                    <button
                      className="mb-2 text-blue-600 font-bold py-2  rounded text-sm opacity-70 "
                      onClick={addBenefit}
                    >
                      Add more  +
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-between ">
            <div className="ml-3">
              <h6 className="text-[15px] mb-4"> Benefits provided to full-time employees that are not provided to temporary or part-time employees <NewTooltip tooltiptext="This table documents data corresponding to the standard benefits offered to full-time employees 
of the organization, which are generally not available to temporary or part-time employees." />
              </h6>
            </div>
            <div className="flex justify-between gap-2">
              <div className="bg-blue-100 h-[25px] rounded-md">
                <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-2a</p>
              </div>
              <div>
                <EditOutlinedIcon onClick={handleeditClick} sx={{ cursor: "pointer" }} />
              </div>
            </div>
          </div>

          <div className=" w-full mx-1">
            <table className="mb-5 w-full text-xs text-start shadow-lg rounded-md px-2">
              <thead className="gradient-background h-[40px] ">
                <tr>
                  <th className="py-2 font-normal text-left px-2">Benefits <NewTooltip tooltiptext="benefit definition: 
direct benefit provided in the form 
of financial contributions, 
care paid for by the organization, or
the reimbursement of expenses 
borne by the employee. 

Example: Life Insurance, Health Care, 
Coverage, Parental Leave, 
Retirement Provision, Stock Ownership etc. " /></th>
                  <th className="py-2 font-normal text-left">Full-Time Employees <NewTooltip tooltiptext="Please select  if 
 this benefit is 
standard for all 
 full-time employees. 
Unselect  if it's not 
offered." /></th>
                  <th className="py-2 font-normal text-left">Part-Time Employees <NewTooltip tooltiptext="Please select  if 
this benefit 
is standard for 
all Part-time employees. 
Unselect  if it's not offered ." /></th>
                  <th className="py-2 font-normal text-left">Temporary Employees <NewTooltip tooltiptext="Please select  if this 
benefit is 
standard for all Temporary 
employees. 
Unselect  if it's not offered." /></th>
                  <th className="py-2 font-normal text-left">Significant location of operations <NewTooltip tooltiptext="This section allows you to enter
 the organization's significant 
locations of operation where the 
listed benefit is offered to full-time 
or part-time employees." /></th>

                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex + 1} >
                    {row.map((cell, cellIndex) => (
                      <>
                        <td
                          key={cellIndex}
                          className="py-2 font-bold text-gray-600 pl-2"
                        >
                          {cellIndex === 0 ? (
                            <input
                              type="text"
                              placeholder="Enter a text"
                              className="w-full py-1 "
                              value={cell}
                              onChange={(e) =>
                                handleCellChange(
                                  e.target.value,
                                  rowIndex + 1,
                                  cellIndex
                                )
                              }
                            />
                          ) : (
                            <input
                              type="checkbox"
                              className="w-full text-center"
                              checked={cell.management && cell.company}
                              onChange={(e) =>
                                handleCellChange(
                                  e.target.checked,
                                  rowIndex + 1,
                                  cellIndex
                                )
                              }
                            />
                          )}
                        </td>

                      </>
                    ))}
                    <td>
                      <input
                        type="text"
                        placeholder="Enter  location "
                        className="w-full py-1 text-center hover:border-slate-400 hover:border-b "

                      />
                    </td>

                  </tr>

                ))}
                <tr>
                  <td className="pl-2 py-2">
                    <button
                      className="mb-2 text-blue-600 font-bold py-2  rounded text-sm opacity-70 "
                      onClick={addBenefit}
                    >
                      Add more  +
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

      
        </>
      )}
</>

    );
}
export default Sectionone;