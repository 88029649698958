//eslint-disable

import React, { useState } from 'react';

const ChairPerson = () => {
  const [formData, setFormData] = useState({
    legalForm: '',
    natureofOwnership: '',
    headquarters: '',
    countriesOfOperation: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className='container mx-auto'>
      {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
      <div className='flex justify-between'>
        <h1 className='mx-4 pb-8 text-gray-500 text-xl font-bold'>
          Chair of the highest governance body
        </h1>
        <h3 className='text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12'>
          GRI 2-11
        </h3>
      </div>
      <form>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block  font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                Is the chair of the highest governance body also a senior
                executive in the organization?
              </h2>
            </label>
            <input
              type='text'
              id='legalForm'
              name='legalForm'
              placeholder='Enter a text'
              className='mt-2 p-2 border rounded-md w-full'
              autocomplete='off'
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block  font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                If yes, please explain their function within the organization's
                management, the reasons for this arrangement and how conflicts
                of interest are prevented and mitigated
              </h2>
            </label>
            <textarea
              id='countriesOfOperation'
              name='countriesOfOperation'
              placeholder='Enter a text'
              className='mt-2 p-2 border rounded-md w-full'
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ChairPerson;
