import React, { useState } from "react";

const CriticalConcerns = () => {
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Communication of critical concerns
        </h1>
        <h3 className="text-end mx-8 w-[7%] bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12 ">
          GRI 2-16
        </h3>
      </div>
      <form>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Describe whether and how critical concerns are communicated to
                the highest governance body
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report the total number of critical concerns that were
                communicated to the highest governance body during the reporting
                period
              </h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              autocomplete="off"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report the nature of the critical concerns that were
                communicated to the highest governance body during the reporting
                period
              </h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              autocomplete="off"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CriticalConcerns;
