import React, { useState, useEffect, useRef } from 'react';

import TvIcon from '@mui/icons-material/Tv';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PrintIcon from '@mui/icons-material/Print';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import MultiAxisLineChart from './line-chart';
import paidsubscription from '../../../assets/logos/paidsubscription.svg';

const CreateScenarios = () => {
  let navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log('newValue:', newValue);
    setValue(newValue);
  };
  const handleClick = () => {
    navigate(`/optimise/create-scenarios`);
  };

  return (
    <>
      {showModal && (
        <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-10 backdrop-blur-sm flex items-center justify-center'>
          <div className='bg-white rounded-lg p-4'>
            <img src={paidsubscription} alt='logo' />
          </div>
        </div>
      )}
      <iframe
        src='https://sustainext-optimise.streamlit.app/?embed_options=light_theme,show_colored_line,show_toolbar&embedded=true'
        title='Optimise App'
        width='100%'
        height='800px'
        frameBorder='0'
      ></iframe>

      {/* <div className="ml-5 flex mt-8">
        <div className="w-[15%]">
          {" "}
          <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
            onClick={() => handleClick()}
          >
            <AddIcon /> Create Scenario
          </button>
        </div>
        <div className="ml-3 mt-1">
          <HelpIcon id="app-title" />
          <ReactTooltip
            anchorId="app-title"
            place="right"
            content="Click on Create Scenario to initiate the scenario creation process."
          />
        </div>
      </div>

      <div className="box rounded-lg p-4 shadow-md">
        <div>
          <div className="mb-3 text-left">
            <input
              type="text"
              className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
              placeholder="Search Scenario"
            />
          </div>
        </div>

        <table className="min-w-full leading-normal border border-slate-200 rounded-lg">
          <thead className="border-s-slate-200">
            <tr className="border border-indigo-600  ">
              <th className="px-5 py-3 border border-indigo-600  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider ">
                Created On
              </th>
              <th className="px-5 py-3 border border-indigo-600  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Scenario
              </th>
              <th className="px-5 py-3 border border-indigo-600  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Timeframe
              </th>
              <th className="px-5 py-3 border border-indigo-600  bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Graph
              </th>
              <th className="px-5 py-3 border border-indigo-600  bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="border border-indigo-600 ">
              <td className="px-5 py-5 border border-indigo-600  bg-white text-sm text-left">
                26-May-2023 10:30 AM IST
              </td>
              <td className="px-5 py-5 border border-indigo-600  bg-white text-sm">
                Scenario 1
              </td>
              <td className="px-5 py-5 border border-indigo-600  bg-white text-sm">
                2019 - 2030
              </td>
              <td className="px-5 py-5 border border-indigo-600  bg-white text-sm">
                <MultiAxisLineChart />
              </td>
              <td className="px-5 py-5 border border-indigo-600  bg-white text-sm">
                <div className="w-[67px] ">
                  {" "}
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                    onClick={() => handleClick()}
                  >
                    Expand
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </>
  );
};
export default CreateScenarios;
