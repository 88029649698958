import React, { useState } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const GeneralSidepanel = ({ activeTab, handleTabClick }) => {
  const sections = [
    {
      title: 'GRI Reporting Info ',
      subsections: [
        'Org Details',
        'Entities',
        'Report Details',
        'Restatement',
        'Assurance',
      ],
    },
    {
      title: 'Organization Info',
      subsections: [
        'Business Details',
        'Workforce-Employees',
        'Workforce - Other Workers',
      ],
    },
    {
      title: 'Compliance',
      subsections: ['Laws & Regulation'],
    },
    {
      title: 'Membership & Association',
      subsections: ['Membership & Association'],
    },
    {
      title: 'Stakeholder Engagement',
      subsections: ['Stakeholder Engagement'],
    },
    {
      title: 'Collective Bargaining Agreements',
      subsections: ['Collective Bargaining Agreements'],
    },
  ];

  const [completedSubsections, setCompletedSubsections] = useState([]);

  const isSubsectionCompleted = (subsection) =>
    completedSubsections.includes(subsection);
  const isSectionCompleted = (section) => {
    if (Array.isArray(section.subsections)) {
      return section.subsections.every(isSubsectionCompleted);
    }
    return completedSubsections.includes(section);
  };

  const handleSubsectionClick = (subsection) => {
    if (!completedSubsections.includes(subsection)) {
      setCompletedSubsections((prevCompletedSubsections) => [
        ...prevCompletedSubsections,
        subsection,
      ]);
    }
    handleTabClick(subsection);
  };

  return (
    <div className='m-3 p-2 ml-2 pl-2'>
      <div
        className='bg-[#f2f2f2] flex items-start py-4 min-w-[200px] rounded-lg text-sm'
        style={{ height: '85vh', overflowY: 'auto' }}
      >
        <div className='flex flex-col w-full mx-4 font-medium'>
          <ol className='relative text-gray-500  dark:border-gray-700 dark:text-gray-400'>
            {sections.map((section, index) => (
              <li className='mb-10 ml-6' key={section.title}>
                <span className='absolute flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-gray-700'>
                  {isSectionCompleted(section) ? (
                    <CheckOutlinedIcon className='w-3.5 h-3.5 text-green-500 dark:text-green-400' />
                  ) : (
                    <div className='w-3.5 h-3.5 bg-gray-500 dark:bg-gray-400 rounded-full' />
                  )}
                </span>
                <button
                  className={`text-gray-700 text-start ps-2 py-2 rounded-md focus:outline-none ${
                    Array.isArray(section.subsections) &&
                    section.subsections.includes(activeTab)
                      ? 'bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] text-white'
                      : 'bg-transparent text-[#4e4d55] hover:bg-blue-400 hover:text-white'
                  }`}
                  onClick={() => {
                    if (Array.isArray(section.subsections)) {
                      handleSubsectionClick(section.subsections[0]);
                    }
                  }}
                >
                  {Array.isArray(section.subsections) ? section.title : section}
                </button>
                {Array.isArray(section.subsections) && (
                  <ol className='ml-6 mt-2 border-l border-gray-400 relative'>
                    {section.subsections.map((subsection) => (
                      <li className='mb-3 ml-6' key={subsection}>
                        <button
                          className={`text-gray-700 flex items-center text-start ps-2 py-2 rounded-md focus:outline-none ${
                            activeTab === subsection
                              ? 'bg-gradient-to-r from-[#007EEF] to-[#2AE4FF] text-white'
                              : 'bg-transparent text-[#4e4d55] hover:bg-blue-400 hover:text-white'
                          }`}
                          onClick={() => handleSubsectionClick(subsection)}
                        >
                          <span
                            className={`absolute flex items-center justify-center w-4 h-4 rounded-full -left-2 right-1  ${
                              activeTab === subsection
                                ? 'bg-yellow-500 dark:bg-yellow-400'
                                : isSubsectionCompleted(subsection)
                                ? 'bg-green-500 dark:bg-[#56C02B]'
                                : 'bg-gray-400 dark:bg-gray-300'
                            }`}
                          />
                          {subsection}
                        </button>
                      </li>
                    ))}
                  </ol>
                )}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default GeneralSidepanel;
