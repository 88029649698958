import React, { useState } from "react";

const DefinedbenefitPlan = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.1</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Defined benefit plan obligations and other retirement plans
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 201-3
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full  items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1  w-full"
                >
                  <h2 className="text-gray-600">
                    If the plan’s liabilities are met by the organization’s
                    general resources, the estimated value of those liabilities.
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    The extent to which the scheme’s liabilities are estimated
                    to be covered by the assets that have been set aside to meet
                    them
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    The basis on which that estimate has been arrived at
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">When that estimate was made</h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    If a fund set up to pay the plan’s pension liabilities is
                    not fully covered, explain the strategy, if any, adopted by
                    the employer to work towards full coverage, and the
                    timescale, if any, by which the employer hopes to achieve
                    full coverage.
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    Percentage of salary contributed by employee or employer
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="flex items-center pb-2 mb-4">
              <div className="w-full items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 w-full"
                >
                  <h2 className="text-gray-600">
                    Level of participation in retirement plans, such as
                    participation in mandatory or voluntary schemes, regional,
                    or country-based schemes, or those with financial impact.
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="Enter a text"
                  className="mt-2 p-2 border  rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DefinedbenefitPlan;
