import React, { useState, useEffect } from 'react';
import StructureComposition from './StructureComposition';
import Nomination from './Nomination';
import ChairPerson from './ChairPerson';
import ManagementofImpacts from './ManagementofImpacts';
import DelegationImpacts from './DelegationImpacts';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import SustainabilityGovernance from './SustainabilityGovernance';
import ConflictsofInterest from './ConflictsofInterest';
import CriticalConcerns from './CriticalConcerns';
import CollectiveKnowledge from './CollectiveKnowledge';
import Evaluation from './Evaluation';
import Remuneration from './Remuneration';
import DetermineRemuneration from './DetermineRemuneration';
import CompensationRatio from './CompensationRatio';
import StrategyPolicies from './StrategyPolicies';
import PolicyCommitments from './PolicyCommitments';
import EmbeddingPolicy from './EmbeddingPolicy';
import RemediateNegativeimpacts from './RemediateNegativeimpacts';
import MechanismSeeking from './MechanismSeeking';
import ComplianceLaws from './ComplianceLaws';
import MembershipAssociations from './MembershipAssociations';
import StakeholderEngagement from './StakeholderEngagement';
import BargainingAgreements from './BargainingAgreements';
import { useProSidebar } from 'react-pro-sidebar';
import GovernanceSidepanel from 'components/collect/GovernanceSidepanel';
import paidsubscription from '../../assets/logos/paidsubscription.svg';

const Governance = () => {
  const [activeTab, setActiveTab] = useState('Structure');
  const { collapseSidebar, collapsed } = useProSidebar();
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPageCount = 25;

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const sections = [
    'Board Info',
    'Structure',
    'Nomination & Selection',
    'Chair of Board',
    'Management of impact',
    'Delegation of Responsibility',
    'Sustainability Reporting',
  ];

  useEffect(() => {
    setActiveTab('Structure');
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const CurrentPageComponent = components[currentPage - 1];

  const handlePrevious = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the previous one
    if (currentIndex > 0) {
      const prevTab = sections[currentIndex - 1];
      setActiveTab(prevTab);
      setCurrentPage(currentIndex);
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the next one
    if (currentIndex < sections.length - 1) {
      const nextTab = sections[currentIndex + 1];
      setActiveTab(nextTab);
      setCurrentPage(currentIndex + 2); // Add 2 since currentPage is 1-indexed
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = () => {
    // Handle the submit action here
  };

  const isLastPage = currentPage === totalPageCount;
  const isFirstPage = currentPage === 1;

  return (
    <>
      <div className='h-full relative'>
        {/* <div className="container"> */}
        {showModal && (
          <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-10 backdrop-blur-sm flex items-center justify-center'>
            <div className='bg-white rounded-lg p-4'>
              <img src={paidsubscription} alt='logo' />
            </div>
          </div>
        )}
        <div className='flex'>
          <GovernanceSidepanel
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <div
            className={`${
              collapsed ? 'max-w-[83%] py-8' : 'max-w-[70%] py-8'
            } transition-all`}
          >
            {activeTab === 'Structure' && <StructureComposition />}
            {activeTab === 'Nomination & Selection' && <Nomination />}
            {activeTab === 'Chair of Board' && <ChairPerson />}
            {activeTab === 'Management of impact' && <ManagementofImpacts />}
            {activeTab === 'Delegation of Responsibility' && (
              <DelegationImpacts />
            )}
            {activeTab === 'Sustainability Reporting' && (
              <SustainabilityGovernance />
            )}
            {activeTab === 'Conflicts of Interest' && <ConflictsofInterest />}
            {activeTab === 'Critical Concerns' && <CriticalConcerns />}
            {activeTab === 'Sustainability Knowledge' && (
              <CollectiveKnowledge />
            )}
            {activeTab === 'Performance Evaluation' && <Evaluation />}
            {activeTab === 'Remuneration' && <Remuneration />}
            {activeTab === 'Determine Remuneration' && (
              <DetermineRemuneration />
            )}
            {activeTab === 'Compensation Ratio' && <CompensationRatio />}
            {activeTab === 'Sustainability Strategy' && <StrategyPolicies />}
            {activeTab === 'Policy Commitments' && <PolicyCommitments />}
            {activeTab === 'Implementing Commitments' && <EmbeddingPolicy />}
            {activeTab === 'Process' && <RemediateNegativeimpacts />}
            {activeTab === 'Advice & Concerns' && <MechanismSeeking />}
            {/* {activeTab === "ComplianceLaws" && <ComplianceLaws />}
            {activeTab === "MembershipAssociations" && <MembershipAssociations />}
            {activeTab === "StakeholderEngagement" && <StakeholderEngagement />}
            {activeTab === "BargainingAgreements" && <BargainingAgreements />} */}
          </div>
        </div>
        {/* </div> */}
        <div className='flex justify-center absolute bottom-2 w-full'>
          <p className='text-gray-500 text-md font-bold pb-10'>
            Page {currentPage}/{totalPageCount}
          </p>
        </div>
        <div className='flex justify-between  absolute bottom-4 w-[96%]'>
          <button
            className='bg-gray-200 px-4 py-2 rounded-md text-gray-700 disabled:opacity-50'
            onClick={handlePrevious}
            disabled={isFirstPage}
          >
            <ArrowBack />
          </button>

          {isLastPage ? (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleNext}
            >
              <ArrowForward />
            </button>
          )}
        </div>
      </div>
      {totalPageCount && <div className='h-16' />}{' '}
      {/* Add space at the bottom */}
    </>
  );
};

export default Governance;
