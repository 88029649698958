import React,{useState} from 'react';
import jsonData from "../previous-material-topics/tabledata.json";

const SocialList = () => {

  const [rowData, setRowData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        jsonData.data[index],
      ]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.index !== index)
      );
    }
  };

  const handleSubmit = () => {
    console.log("Selected Items:", selectedItems);
    // Perform your submit logic here
  };

  return (
    <div
      className="col-span-4 mx-2 bg-white border 
       p-6 main-div"
    >
      <h4 className="text-lg font-semibold mb-6 text-black">Social</h4>
      <table className="border-t-2">
        <thead className=''>
        </thead>
        <tbody className="bg-gray-100 cursor-pointer">
          {jsonData.data1.map((item, index) => (
            <tr
              key={item.index}
              className="hover:border-b hover:border-black text-gray-600 tooltip border-b border-gray-400 border-solid font-semibold"
              data-tooltip="This is a tooltip that contains
              multiple lines of information. It can span across 3-4 lines. Adjust the width and bottom position in CSS to fit your requirements."
            >
              <div className="flex items-center content-center">
              <td className="text-xs mx-4 flex items-center justify-center col-span-full leading-7  text-left w-fit">
                <input
                  type="checkbox"
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem.index === item.index
                  )}
                  onChange={(event) => handleCheckboxChange(event, item.index)}
                />
              </td>
              <td className="text-xs mx-4 flex items-center justify-center col-span-full leading-7  text-left w-fit">
                {item.name}
              </td>
              </div>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        className="bg-gray-500 hover:bg-black-700 text-white font-bold py-2 px-4 mt-4"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  )
}

export default SocialList;