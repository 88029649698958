import React, { useState } from 'react';
import IST_Environment from './IST_Environment';
import IST_Social from './IST_Social';
import IST_Governance from './IST_Governance';
import SasbIndustry from '../sasb-industry-topics/SasbIndustry';

function IndustryspecificTopics() {
  const [selectedYear, setSelectedYear] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [activeRow, setActiveRow] = useState(null);
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const years = Array.from({ length: 10 }, (_, index) => 2023 - index);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleRowHover = (index) => {
    setActiveRow(index);
  };

  const handleRowClick = (index) => {
    setActiveRow(null);
  };

  const tableData = [
    { id: 1, name: 'Row 1', description: 'Large data for Row 1' },
    { id: 2, name: 'Row 2', description: 'Large data for Row 2' },
    // Add more table data as needed
  ];

  // Generate an array of years

  const options = [
    'Energy',
    'Materials',
    'Industrials',
    'Consumer Discretionary',
    'Consumer Staples',
    'Health care',
    'Financials',
  ];

  // Handle year selection
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  return (
    <>
      <div className='container mx-auto '>
        <h1 className='p-4 text-2xl font-bold text-gray-700'>
          Review Industry and Sector-Specific Material Topics:
        </h1>
        <div className='p-4'>
          <label htmlFor='year' className='block font-medium  mb-2'>
            Select a Year:
          </label>
          <select
            id='year'
            value={selectedYear}
            onChange={handleYearChange}
            className='border border-gray-300 rounded p-2'
          >
            <option value=''>-- Select --</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          {selectedYear && (
            <p className='mt-2'>Selected Year: {selectedYear}</p>
          )}
        </div>

        {/* Section and Sub section */}

        <div>
          <div className='relative inline-block'>
            <button
              className='bg-blue-500 text-white py-2 px-4 mx-4 rounded'
              onClick={toggleDropdown}
            >
              {selectedOption ? selectedOption : 'Sector'}
            </button>
            {isOpen && (
              <div className='absolute z-10 mt-2'>
                <ul className='bg-white border rounded shadow'>
                  {options.map((option, index) => (
                    <li
                      key={index}
                      className='py-2 px-4 hover:bg-gray-200 font-bold cursor-pointer'
                      onClick={() => handleSelect(option)}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='relative inline-block'>
            <button className='bg-green-500 text-white py-2 px-4 mx-4 rounded'>
              Sub-industry
            </button>
          </div>
        </div>

        {/* Tab Section */}
        <div className=''>
          <div className={`flex my-6 mx-4 border-b`}>
            <button
              className={`px-4 py-1 font-medium rounded-md rounded-b-none ${
                activeTab === 'tab1'
                  ? 'border-t-2 border-l-2 border-r-2 border-[#1aaef4]'
                  : 'border-transparent'
              }`}
              onClick={() => handleTabChange('tab1')}
            >
              MSCI
            </button>
            <button
              className={`px-4 py-1 font-medium rounded-md rounded-b-none ${
                activeTab === 'tab2'
                  ? 'border-t-2 border-l-2 border-r-2 border-[#1aaef4]'
                  : 'border-transparent'
              }`}
              onClick={() => handleTabChange('tab2')}
            >
              SASB
            </button>
          </div>
          <div className='flex-grow'>
            {activeTab === 'tab1' && (
              <div className='grid grid-cols-12 p-6 h-100'>
                <IST_Environment />
                <IST_Social />
                <IST_Governance />
              </div>
            )}
            {activeTab === 'tab2' && <SasbIndustry />}
          </div>
        </div>
      </div>
    </>
  );
}

export default IndustryspecificTopics;
