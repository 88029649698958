import React, { useState, useEffect } from "react";
import { KeyboardArrowUpOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useProSidebar } from "react-pro-sidebar";
import Purchased from "./Purchased/purchased";
import Consumedfuel from "./Consumed-fuel/Consumed-fuel";
import Selfgenerated from "./Self-generated/Self-generated";
import Energysold from "./Energy-sold/Energy-sold";
import Standards from "./Standards/standards";
import Source from "./Source/source";
const AccordionItem = ({ title, children, tooltiptext, sdg, display }) => {
  const [isOpen, setIsOpen] = useState(false);
  const {collapsed} = useProSidebar();

  return (
    <div className={`shadow-md py-1  mb-4 rounded-[8px] cursor-pointer border border-b-3 border-neutral-200 ${collapsed ? 'sm:w-[96%] md:w-[90%] lg:w-[98%] xl:w-[96%] 2xl:w-[100%] 3xl:w-[100%]' :'sm:w-[96%] md:w-[90%] lg:w-[98%] xl:w-[94%] 2xl:w-[98%] 3xl:w-[100%]' }`}>
      <button
        className="py-3  w-full text-left flex"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <h5 className="text-[14px]  text-[#344054] pt-1 px-3">{title}</h5>
        </div>
       <div className="ml-1">
          <Tooltip
            title={tooltiptext}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#000",
                  color: "white",
                  fontSize: "12px",
                  boxShadow: 3,
                  borderRadius: "8px",

                },
              },
              arrow: {
                sx: {
                  color: "#000",
                },
              },
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginTop: "9px", display: { display } }} />
          </Tooltip>
        </div>

         <div className={`absolute  flex justify-between ${collapsed ? 'sm:right-[4rem] md:right-[4rem] lg:right-[4rem] xl:right-[4rem] 2xl:right-[4rem] 3xl:right-[4rem] ' :'sm:right-[3rem] md:right-[3rem] lg:right-[3rem] xl:right-[3rem] 2xl:right-[4rem] 3xl:right-[4rem] ' }`}>
          {isOpen ? (
            <>
              {sdg && sdg.map((sdgItem, index) => (
                <div key={index} className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2" style={{ display: display }} >
                  <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">{sdgItem}</p>
                </div>
              ))}
            </>
          ) : (
            <>
              {sdg && sdg.map((sdgItem, index) => (
                <div key={index} className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                  <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">{sdgItem}</p>
                </div>
              ))}
            </>
          )}

          <span>
            {isOpen ? (
              <KeyboardArrowUpOutlined />
            ) : (
              <KeyboardArrowDownOutlinedIcon />
            )}
          </span>
        </div>
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

const Energyconsumptionbody = () => {

  return (
    <>
      <div className="mx-3">
        <AccordionItem
          title="Direct Purchased Heating, Cooling, Electricity and Steam"
          tooltiptext="This section documents data corresponding to Energy Purchased
          for use within the Organization. Include: Input data related only
          to directly purchased heating, electricity, steam and cooling inside
           the organization.  Exclude: Do not include data relating to fuel consumption."
          sdg={['GRI 302-1a', 'GRI 302-1b']}
          display="block"
        >
          <Purchased />
        </AccordionItem>
        <AccordionItem
          title="Consumed fuel and energy, including self generated"
          tooltiptext="This section documents data corresponding to energy consumed - including fuels.
          Include:
          Self-Generated Energy shall be incorporated in this context.
          Exclude: Direct purchased Heating, Cooling, Electricity, and Steam. "
          sdg={['GRI 302-1c', 'GRI 302-1e']}
          display="block"
        >
          <Consumedfuel />
        </AccordionItem>
        <AccordionItem
          title="Self generated - not consumed or sold"
          tooltiptext="This section documents data corresponding to energy
          generated by the organization (self-generated energy) that
          remains unconsumed or unsold."
          sdg={['GRI 302-1']}
          display="block"
        >
          <Selfgenerated />
        </AccordionItem>
        <AccordionItem
          title="Energy sold"
          tooltiptext="This section documents data corresponding
          to energy that is sold, regardless of whether
          it is procured or self-generated. "
          sdg={['GRI 302-1d']}
          display="block"
        >
          <Energysold />
        </AccordionItem>
        <AccordionItem
          title="Standards, methodologies, assumptions and calculation tools used"
          tooltiptext="Standards, methodologies, assumptions and calculation tools used"
          sdg={['GRI 302-1f']}
          display="none"
        >
          <Standards />
        </AccordionItem>
        <AccordionItem
          title="Source of  conversion factor"
          tooltiptext="Source of  conversion factor"
          sdg={['GRI 302-1g']}
          display="none"
        >
          <Source />
        </AccordionItem>

        {/* Add more accordion items here */}
      </div>
      <div className="flex justify-end items-center mt-[24] me-[51px]">
        <button className="w-[150px] h-8 px-[22px] py-2 bg-[#007EEF] rounded shadow flex-col justify-center items-center inline-flex text-white text-xs font-bold leading-[15px]">
          <div className="cursor-pointer">
            <p className="text-white text-sm font-semibold">Save</p>
            {/* {calculating
          ? 'Calculating...'
          : calculated
          ? 'Calculated'
          : 'Calculate'} */}
          </div>
        </button>
      </div>
    </>
  );
};

export default Energyconsumptionbody;
