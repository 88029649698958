import React, { useMemo } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';

import { Link } from 'react-router-dom';
const Scope = ({ data }) => {
  // Define your columns
  const columns = useMemo(
    () => [
      {
        Header: 'S.No',
        accessor: 'sno',
      },
      {
        Header: 'Scope',
        accessor: 'scope',
      },
      {
        Header: 'Contribution %',
        accessor: 'ageContribution',
      },
      {
        Header: 'Total Emissions (tCO2e)',
        accessor: 'totalemissions',
      },
    ],
    []
  );

  // Initialize the table instance
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  return (
    <div className='container mx-auto p-4'>
      <table
        {...getTableProps()}
        className='min-w-max w-full table-auto '
        style={{ border: 'none' }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className='border-b border-black border-opacity-10'
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className='px-6 py-3 text-neutral-500 text-xs font-normal leading-[15px] text-left w-1/4'
                  style={{ border: 'none' }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className='text-gray-600 text-sm font-light'
          style={{ border: 'none' }}
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className='border-b border-gray-200 hover:bg-gray-100'
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className='py-3 px-6 text-left text-black text-opacity-90 text-[13px] font-normal leading-none'
                    style={{ border: 'none' }}
                  >
                    {cell.render('Cell')}{' '}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Scope;
