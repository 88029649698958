import * as Yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const signUpSchema = Yup.object({
 
  name: Yup.string().min(2).max(25).required("Please enter your first name"),
  email: Yup.string().email().required("Please enter your email"),
  lastname: Yup.string().min(2).max(25).required("Please enter your last name"),
  cname: Yup.string().min(2).max(25).required("Please enter your company name"),
  jobtitle: Yup.string().min(2).max(25).required("Please enter your job title"),
  mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid')

});