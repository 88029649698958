import React, { useState, useEffect, useRef } from "react";
import Table from "./Table";
import Datainjection from "./Data-Injection";
import Experimentalvariables from "./Experimental-Variables";
import Moment from 'react-moment';
import moment from 'moment';
const date = moment();
const CreateScenariosForm = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };
  const hanlclick = () => {
    setIsShown((current) => !current);
  };

  return (
    <>
      <div className="my-4 mx-5 text-left">
        <div className="grid grid-cols-1 md:grid-cols-2 mb-4">
          <div className="mr-2">
            <label
              htmlFor="cname"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Scenario Name
            </label>
            <div className="mt-2">
              <input
                id="cname"
                name="cname"
                type="text"
                autoComplete="cname"
                required
                placeholder="Scenario Name"
                className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium leading-6 text-gray-900 mt-8 ml-5"
            >
              Created at -  <Moment format='D MMM YYYY HH:mm:ss a'>{date}</Moment>
            </label>



          </div>
        </div>
        <div className="mb-2  py-4 px-3 shadow-md ">
          <h1 className="text-left mb-5 text-lg ">
            <b>Company Available Data</b>
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-4">
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Current Employees
              </label>
              <div className="mt-2">
                <input
                  id="cname"
                  name="cname"
                  type="text"
                  autoComplete="cname"
                  required
                  placeholder="Current Employees"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Distance Travelled (Company Owned Cars)
              </label>
              <div className="mt-2">
                <input
                  id="cname"
                  name="cname"
                  type="text"
                  autoComplete="cname"
                  required
                  placeholder="Distance Travelled (Company Owned Cars)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="cname"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Distance Travelled (Member Owned Cars)
              </label>
              <div className="mt-2">
                <input
                  id="cname"
                  name="cname"
                  type="text"
                  autoComplete="cname"
                  required
                  placeholder="Distance Travelled (Member Owned Cars)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mr-2">
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Air Distance Travelled (Short Haul)
              </label>
              <div className="mt-2 ">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="Air Distance Travelled (Short Haul)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Air Distance Travelled (Medium Haul)
              </label>
              <div className="mt-2 ">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="Air Distance Travelled (Medium Haul)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="mr-2">
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Air Distance Travelled (Long Haul)
              </label>
              <div className="mt-2 ">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="Air Distance Travelled (Short Haul)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-4">
            <div>
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                RE Consumption (Grid Electricity)
              </label>
              <div className="mt-2 mr-2">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="RE Consumption (Grid Electricity)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                NRE Consumption (Grid Electricity)
              </label>
              <div className="mt-2 mr-2">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="NRE Consumption (Grid Electricity)"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Public Transport Emissions
              </label>
              <div className="mt-2 mr-2">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="Public Transport Emissions"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="jobtitle"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Year
              </label>
              <div className="mt-2 mr-2">
                <input
                  id="jobtitle"
                  name="jobtitle"
                  type="year"
                  autoComplete="Year"
                  required
                  placeholder="Year"
                  className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mt-8"
                onClick={hanlclick}
              >
                Submit Data
              </button>
            </div>
          </div>
        </div>
      </div>
      {isShown && (
        <>
      <div className="mb-3">
        <Table/>
        </div>
        <div>
          <Datainjection/>
          </div>
          </>
      )}


    </>
  );
};
export default CreateScenariosForm;
