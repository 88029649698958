import React, { useState, useEffect } from "react";
import { useProSidebar } from "react-pro-sidebar";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {
    return (
      <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: '#000',
            color: 'white',
            fontSize: '12px',
            boxShadow: 3,
            borderRadius: "8px"
          },
        },
        arrow: {
          sx: {
            color: '#000',
          },
        },
      }}>
        <InfoOutlinedIcon sx={{ fontSize: "14px", }} />
      </Tooltip>
    );
  };

  const Sectiontwo = () => {
    const [isClicked, setIsClicked] = useState(false);
    const handleeditClick = () => {
        setIsClicked(!isClicked);
      };
    return (
        <>
 <div className="flex justify-between ">
            <div className="ml-3">
              <h6 className="text-[15px] mb-4"> Significant locations of operation <NewTooltip tooltiptext="This section documents data corresponding to the organization's definition of significant locations of operation" />
              </h6>
            </div>
            <div className="flex justify-between gap-2">
              <div className="bg-blue-100 h-[25px] rounded-md">
                <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-2b</p>
              </div>
              <div>
                <>
              {isClicked ? (
                <>
                  <CloseOutlinedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={handleeditClick}
                />
                <SaveOutlinedIcon
                  sx={{ ml: 2, cursor: "pointer" }}/>
                  </>
              ) : (

                <EditOutlinedIcon onClick={handleeditClick} sx={{ cursor: "pointer" }} />
              )}
              </>
              </div>
            </div>
          </div>

          <div className="flex justify-between ">
          <div className="ml-3">
              <h6 className="text-[13px] mb-4"> How does the organization define "Significant locations of operation" for reporting purposes? <NewTooltip tooltiptext="Please clarify how the organization defines and
identifies its significant locations for reporting purposes" />
              </h6>
            </div>

          </div>
          <div className="ml-3">
            <textarea
                    id="countriesOfOperation"
                    name="countriesOfOperation"
                    placeholder="Enter a description..."
                    className="w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 m-0.5 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer "


                    // value={formData.countriesOfOperation}
                    // onChange={handleInputChange}
                    rows={7}
                 // Specify the number of rows to determine the initial height
                  />
            </div>

          </>
    );
  }
  export default Sectiontwo;