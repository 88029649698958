import React, { useState } from "react";

const CountrybyCountry = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.1</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Country-by-country reporting
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 207-4
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <div className="flex items-center mx-4 pb-2 mb-4">
              <div className="w-full  items-center">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1  w-full"
                >
                  <h2 className="text-gray-600">
                    All tax jurisdictions where the entities included in the
                    organization’s audited consolidated financial statements, or
                    in the financial information filed on public record, are
                    resident for tax purposes.
                  </h2>
                </label>
                <textarea
                  id="countriesOfOperation"
                  name="countriesOfOperation"
                  placeholder="List all Jurisdictions"
                  className="mt-2 p-2 border rounded-md w-full"
                  // value={formData.countriesOfOperation}
                  // onChange={handleInputChange}
                  rows={4} // Specify the number of rows to determine the initial height
                />
              </div>
            </div>
            <div className="w-full">
              <div className="">
                {/* <div className="w-full flex justify-end border-l border-r ">
            <div className="py-2 text-center w-[218px] border-l text-sm">
              <span className="px-2 py-2 font-bold">Number of all Employees</span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-3 py-2 font-bold ">
                No. of workers who are not employees but whose work and/or
                workplace is controlled by the organization
              </span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-2 py-2 font-bold">Total number of employees</span>
            </div>
          </div> */}
                <p className="mt-6 mb-5 text-gray-500 font-bold">
                  For each tax jurisdiction reported above,
                </p>
                <table className=" text-xs text-start">
                  <thead className="border">
                    <th className="py-2 text-center text-sm">
                      <span className="px-2 py-2 font-bold"></span>
                    </th>
                    <th className="py-2 text-center w-[22%] border-l text-sm">
                      <span className="px-2 py-2 font-bold">
                        Select Currency
                      </span>
                    </th>
                    <th className="py-2 text-center w-[25%] border-l text-sm">
                      <span className="px-3 py-2 font-bold ">
                        Select Reporting Period
                      </span>
                    </th>
                    <th className="py-2 text-center w-[22%] border-l text-sm">
                      <span className="px-2 py-2 font-bold">Add Country</span>
                    </th>
                  </thead>
                  <tbody className="border">
                    <tr>
                      <td className="px-8 py-2 font-bold h-[80px] w-[20%] text-sm text-black border-r">
                        Names of resident entities
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px] "
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Primary activities of the organization
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Number of employees and the basis of calculation of this
                        number
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Revenues from third party sales
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Revenues from intra-group transactions with other tax
                        jurisdictions
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Profit/Loss before tax
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Tangible assets other than cash and cash equivalents
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                        Corporate income tax paid on a cash basis
                      </td>
                      <td className="px-12 py-2">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className=" px-12 py-2  ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                      <td className="px-12 py-2 ">
                        <input
                          type="text"
                          name="natureofOwnership"
                          autocomplete="off"
                          className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="flex items-center pb-2 mb-4 mt-6">
                <div className="w-full items-center">
                  <label
                    htmlFor="legalForm"
                    className="block font-bold mb-1 w-full"
                  >
                    <h2 className="text-gray-600">
                      Corporate income tax accrued on profit/loss
                    </h2>
                  </label>
                  <textarea
                    id="countriesOfOperation"
                    name="countriesOfOperation"
                    placeholder="Enter a text"
                    className="mt-2 p-2 border  rounded-md w-full"
                    // value={formData.countriesOfOperation}
                    // onChange={handleInputChange}
                    rows={4} // Specify the number of rows to determine the initial height
                  />
                </div>
              </div>
              <div className="flex items-center pb-2 mb-4 mt-6">
                <div className="w-full items-center">
                  <label
                    htmlFor="legalForm"
                    className="block font-bold mb-1 w-full"
                  >
                    <h2 className="text-gray-600">
                      Reasons for the difference between corporate income tax
                      accrued on profit/loss and the tax due if the statutory
                      tax rate is applied to profit/loss before tax
                    </h2>
                  </label>
                  <textarea
                    id="countriesOfOperation"
                    name="countriesOfOperation"
                    placeholder="Enter a text"
                    className="mt-2 p-2 border  rounded-md w-full"
                    // value={formData.countriesOfOperation}
                    // onChange={handleInputChange}
                    rows={4} // Specify the number of rows to determine the initial height
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CountrybyCountry;
