import React from "react";

const OperationswithSignificant = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
        Operations with significant actual and potential negative impacts on
        local communities
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 413-2
        </h3>
      </div>
      <table className="border-collapse border mx-4 ">
        <tr>
          <th rowSpan="2" className="border text-sm px-4 py-2 w-[25%]">
            Operations with significant actual and potential negative impacts on
            local communities
          </th>
          <th rowspan="2" className="border text-sm px-4 w-[25%] py-2">
            Location of Operations
          </th>
          <th rowspan="2" className="border text-sm px-4 w-[25%] py-2">
            Potential Negative Impacts of the Operation
          </th>
          <th rowspan="2" className="border text-sm px-4 w-[25%] py-2">
            Actual Negative Impacts of the Operation
          </th>
        </tr>
        <tr>
          {/* <th className="border text-xs font-bold px-4 py-4">Male</th>
          <th className="border text-xs font-bold px-4 py-4">Female</th>
          <th className="border text-xs font-bold px-4 py-4">Non-Binary</th> */}
        </tr>
        <tr>
          <td className="border text-sm font-bold px-4 py-4 text-center">
            Operation 1
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
        </tr>
        <tr>
          <td className="border text-sm font-bold px-4 py-4 text-center">
            + Add Category
          </td>
          <td className="  px-1 py-2 text-xs">
            {" "}
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
          <td className="  px-1 py-2 text-xs">
            {" "}
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="12"
            ></input>
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default OperationswithSignificant;
