import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  reportType: "",
  startDate: "",
  endDate: "",
  lastReport: "",
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setReportType: (state,action) => {
      state.reportType = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setLastReport: (state, action) => {
      state.lastReport = action.payload;
    },
  },
});

export const { setName, setReportType, setStartDate, setEndDate, setLastReport } = reportSlice.actions;

export default reportSlice.reducer;
