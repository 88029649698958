import { createSlice } from '@reduxjs/toolkit';

const preferenceSlice = createSlice({
  name: 'preference',
  initialState: {
    sdgs: [1, 4, 7, 9],
    pledges: [],
    targets: [1,2],
    frameworks: [7],
    certifications: [2],
    ratings: [],
    regulation:[1,2]
  },
  reducers: {
    setSdgs: (state, action) => {
      state.sdgs = action.payload;
    },
    setPledges: (state, action) => {
      state.pledges = action.payload;
    },
    setTargets: (state, action) => {
      state.targets = action.payload;
    },
    setFrameworks: (state, action) => {
      state.frameworks = action.payload;
    },
    setCertifications: (state, action) => {
      state.certifications = action.payload;
    },
    setRatings: (state, action) => {
      state.ratings = action.payload;
    },
    setRegulation: (state, action) => {
      state.regulation = action.payload;
    },
  },
});

export const {
  setSdgs,
  setPledges,
  setTargets,
  setFrameworks,
  setCertifications,
  setRegulation,
  setRatings,
} = preferenceSlice.actions;
//reducers are action creators

export default preferenceSlice.reducer;
