import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {


    return (
        <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    fontSize: '12px',
                    boxShadow: 3,
                    borderRadius: "8px"
                },
            },
            arrow: {
                sx: {
                    color: '#000',
                },
            },
        }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px", color: "#000" }} />
        </Tooltip>
    );
};
const Sharedresource = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Interactions with Water as shared resource

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe how the organization interacts with water. <NewTooltip tooltiptext="In the description, include how, and where water is withdrawn, consumed, and discharged,
as well as water-related impacts the organization has caused or contributed to, or those
that are directly related to its operations, products, and services. An overview of water
use across the organization’s value chain."/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-1a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Water Related Impact

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the approach used to identify water-related impacts  <NewTooltip tooltiptext="Include scope of assessments, their timeframe, and any tools or methodologies used." /></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Water Related Impact

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe how water-related impacts are addressed  <NewTooltip tooltiptext="Include how the organization works with stakeholders to steward water
 as a shared resource, and how it engages with suppliers or customers
with significant water-related impacts
"/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-1c</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Water-related goals and targets

                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the process for setting any water-related goals and targets
                                that are part of the Organization's approach to managing water-related impacts. <NewTooltip tooltiptext="Include water-related goals and targets that are
part of the organization’s approach to managing
water and effluents, and how they relate to public
policy and the local context of each area with water stress
"/></h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 303-1d</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
export default Sharedresource;