import React, { useState, useEffect } from 'react';
import { useProSidebar } from "react-pro-sidebar";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const NewTooltip = ({ tooltiptext }) => {


    return (
        <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
            tooltip: {
                sx: {
                    backgroundColor: '#000',
                    color: 'white',
                    fontSize: '12px',
                    boxShadow: 3,
                    borderRadius: "8px"
                },
            },
            arrow: {
                sx: {
                    color: '#000',
                },
            },
        }}>
            <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px" ,color:"#000" }} />
        </Tooltip>
    );
};
const Sgnificantwasteimpact = () => {
    const { collapsed } = useProSidebar();
    return (
        <>
            <div className='mt-3'>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Impact Analysis: Inputs, Activities, Outputs
                                <NewTooltip tooltiptext="Please specify the inputs, activities, and outputs that lead
or could lead to actual and potential waste related impacts.
Include: The types of inputs and outputs can include raw
materials, process and manufacturing
materials, leaks
and losses, waste, by-products, products, or packaging.
" />
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe the inputs, activities, and outputs that lead or could lead to these impacts</h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 306-1a</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>
                <div className='mb-4'>
                    <div className='flex justify-between'>
                        <div className={`${collapsed ? 'w-[80%]' : 'w-[80%]'}`}>
                            <h6 className='text-[14px] font-medium text-[#344054]'>
                                Activities resulting into waste
                                <NewTooltip tooltiptext="Please provide the description of the impacts relate to waste
generated in the organization’s own activities
or to waste generated upstream or downstream in its value chain.  
Impacts: effect the organization has or could have on the economy,
environment, and people, including on their human rights, which
in turn can indicate its contribution (negative or positive) to
sustainable development."/>
                            </h6>
                            <h6 className='text-[#727272] text-[12px]'>Describe whether these impacts relate to waste generated in the organization’s own
activities or to waste generated upstream or downstream in its value chain.</h6>
                        </div>
                        <div className={`${collapsed ? 'w-[20%]' : 'w-[20%]'}`}>

                            <div className="bg-sky-100 h-[25px] w-[70px] rounded-md mx-2">
                                <p className="text-[#0057A5] text-[10px] inline-block align-middle px-2 font-semibold">GRI 306-1b</p>
                            </div>
                        </div>

                    </div>
                    <div className='mt-2'>
                        <textarea
                            id="countriesOfOperation"
                            name="countriesOfOperation"
                            placeholder="Enter a description..."
                            className={`backdrop:before:w-[48rem] border appearance-none text-xs border-gray-400 text-neutral-600 pl-2 rounded-md py-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-400 cursor-pointer ${collapsed ? 'sm:w-[48rem] md:w-[89%] lg:w-[87%] xl:w-[88%] 2xl:w-[85%] ' : 'sm:w-[85%] md:w-[92%] lg:w-[88%] xl:w-[89%] 2xl:sm:w-[86%]'}`}


                            // value={formData.countriesOfOperation}
                            // onChange={handleInputChange}
                            rows={7}
                        // Specify the number of rows to determine the initial height
                        />
                    </div>
                </div>

            </div>
        </>
    );
}
export default Sgnificantwasteimpact;