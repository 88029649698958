import React from "react";
import { useProSidebar } from "react-pro-sidebar";
const PreventionandMitigation = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  return (
    <>
        <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className="text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px] ">Prevention of OHS Impact</p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>
    <div className="container mx-auto mt-4">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.4</h1> */}
   
      <form>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block mb-1 pb-4">
              <h2 className="text-gray-600  text-[15px]">
              Is there an approach to prevent or mitigate significant negative occupational health and safety impacts that are directly linked to operations?
              </h2>
            </label>
            <select
              // id="natureofOwnership"
              name="natureofOwnership"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.natureofOwnership}
              // onChange={handleInputChange}
            >
              <option value="select">- Select - </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
   
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block mb-1 pb-4">
              <h2 className="text-gray-600  text-[15px]">If yes, Descibe the approach</h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block mb-1 pb-4">
              <h2 className="text-gray-600  text-[15px]">
              List the hazards and risks associated
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
    </>
  );
};

export default PreventionandMitigation;
