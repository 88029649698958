import React, { useState, useEffect } from "react";
// import logo from '../../assets/logos/Sustainext-logo.png';
import logo from "../../assets/logos/Sustainext-logo.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PasswordChecklist from "react-password-checklist";
import { Link } from "react-router-dom";
import { industryinfo } from "../../components/data/industryinfo";
import { useFormik } from "formik";
import { signUpSchema } from "../../components/schemas";
import axios from "axios";
import { VisibilityOff, VisibilityOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { registerUser } from "state/authSlice";
import backicon from "../../assets/images/back-icon.svg";
import * as Yup from "yup";

const initialValues = {
  name: "",
  email: "",
  lastname: "",
  cname: "",
  jobtitle: "",
  mobile: "",
};

const Signuptwo = ({ onNextPage }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [conshowPassword, setConshowPassword] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [confirmPassMessage, setConfirmPassMessage] = useState("");
  const [newconfirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [massgecolor, setMassgecolor] = useState("");
  const { values, errors, touched, handleBlur, handleChange } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
  });
  console.log(
    "🚀 ~ file: Registration.jsx ~ line 25 ~ Registration ~ errors",
    errors
  );
  const dispatch = useDispatch();

  const isOfficialEmail = (value) => {
    return /@sustainext\.ai$/.test(value);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .required("Please enter a valid email address")
      .email("Invalid email format")
      .test(
        "official-email",
        "Only official email addresses are allowed",
        (value) => {
          return isOfficialEmail(value);
        }
      ),
    password: Yup.string()
      .required("Please create a valid password ")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25, "Your password must not be longer than 25 characters.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
    confirmPassword: Yup.string()
      .required("Password confirmation is required")
      .oneOf([Yup.ref("password"), null], "The entered passwords do not match"),
    termsAndCondition: Yup.bool().oneOf(
      [true],
      "You need to accept the terms and conditions."
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      termsAndCondition: false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      // If the form is valid, proceed to the next page
      onNextPage();
    },
  });

  useEffect(() => {
    if (formik.dirty && formik.isValid) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [formik.values, formik.dirty, formik.isValid]);

  function confirmPassword(event) {
    setConfirmPassword(event.target.value);
    let val = event.target.value;

    if (password === val) {
      setConfirmPassMessage("Password and Confirm Password match");
      setMassgecolor("rit");
    } else if (password === "") {
      setConfirmPassMessage("");
    } else {
      setConfirmPassMessage("Password and Confirm Password do not match");
      setMassgecolor("worng");
    }
  }

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    if (formik.isValid) {
      // Form is valid, proceed to the next page
      onNextPage();
    } else {
      // Form is not valid, show validation errors
      formik.setTouched({ ...formik.touched, email: true }); // Mark email field as touched to display its error message
    }
  };


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordCon = () => setConshowPassword((show) => !show);
  function confirmPassword(event) {
    setConfirmPassword(event.target.value);
    let val = event.target.value;
    console.log(confirmPass);
    if (password === val) {
      // console.log(confirmPass);
      setConfirmPass("New Password and Confirm Password are matched");

      setMassgecolor("rit");

      // setConfirmPass("Password matched");
    } else if (password == "") {
      setConfirmPass("");
    } else {
      // console.log(confirmPass);
      setConfirmPass("New Password and Confirm Password are not matched");
      setMassgecolor("worng");
    }
  }

  const handlePasswordChange = (e) => {
    formik.handleChange(e); // Update formik state
    setPassword(e.target.value); // Update the local password state
  };

  return (
    <>
      <div className=" min-h-screen place-items-center mt-10 to-teal-500 flex flex-wrap justify-center ">
        <div className=" h-full bg-[#fff]  w-[80%]  ">
          <div className="flex flex-1 flex-col justify-center  py-2   ">
            <form  onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="">
                <Link to="/login">
                  <img
                    className="h-[2rem] hover:bg-sky-50 cursor-pointer"
                    src={backicon}
                    alt="Sustainext"
                  />
                </Link>
              </div>
              <h2 className="mt-10  text-[24px] font-medium gradient-heading">
                Enter email and password for registration
              </h2>
              <p className="mt-[10px] mb-4 text-start font-semibold text-[16px] text-color-login">
                Already have an account ?
                <Link
                  to="/login"
                  className="font-weight-400 ml-2 hover-underline-blue"
                  style={{ color: "#00AEEF" }}
                >
                  Log in
                </Link>
              </p>
            </div>
            <div className="w-[46.50%] h-[6.83%] mt-4">
              <label
                htmlFor="email"
                className="block text-[15px] font-medium leading-6 text-gray-900"
              >
                Enter email address*
              </label>
              <div className="">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="off"
                  required
                  placeholder="Enter your work email"
                  className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div className="error-wrapper">
                  {formik.touched.email && formik.errors.email ? (
                    <p className="errors text-red-500">{formik.errors.email}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-6 w-[96%]">
              <div className="div">
                <div className="mr-2 ">
                  <div className="mr-2 ">
                    <label
                      htmlFor="price"
                      className="block text-[15px] font-medium leading-6 text-gray-900"
                    >
                      Create Password*
                    </label>
                    <div
                      className="relative  rounded-md shadow-sm"
                      id="app-title"
                    >
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="new-password"
                        // aria-autocomplete="list"
                        required
                        placeholder="Enter password for the account"
                        // onChange={(e) => setPassword(e.target.value)}
                        value={formik.values.password}
                        onChange={handlePasswordChange}
                        onBlur={formik.handleBlur}
                        onCopy={(e) => preventCopyPaste(e)}
                        onPaste={(e) => preventCopyPaste(e)}
                        onCut={(e) => preventCopyPaste(e)}
                        className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                      />
                      <div className="absolute inset-y-0 right-0 mr-2 flex items-center cursor-pointer">
                        <div onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <VisibilityOutlined className="text-gray-500" />
                          ) : (
                            <VisibilityOff className="text-gray-500" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ReactTooltip
                  anchorId="app-title"
                  place="right"
                  style={{ zIndex: 100 }}
                  content={
                    <PasswordChecklist
                      rules={["number", "specialChar", "capital", "minLength"]}
                      minLength={8}
                      value={password}
                      iconSize={16}
                      invalidColor="red"
                      validColor="#4BCA81"
                      style={{ fontSize: "14px" }}
                      messages={{
                        number: "At least one number (0-9)",
                        specialChar:
                          "At least one special character (Eg. @#%$)",
                        capital:
                          "At least one uppercase & one lowercase letter",
                        minLength: "Minimum 8 characters",
                      }}
                    />
                  }
                />
                {/* <div className="error-wrapper">
                  {formik.touched.password && formik.errors.password ? (
                    <p className="errors text-red-500">
                      {formik.errors.password}
                    </p>
                  ) : null}
                </div> */}
              </div>
              <div>
                <div>
                  <label
                    htmlFor="price"
                    className="block text-[15px] font-medium leading-6 text-gray-900"
                  >
                    Confirm Password*
                  </label>
                  <div className="relative  rounded-md shadow-sm">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={conshowPassword ? "text" : "Password"}
                      autoComplete="confirmPassword"
                      required
                      placeholder=" Enter password for the account"
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onCopy={(e) => preventCopyPaste(e)}
                      onPaste={(e) => preventCopyPaste(e)}
                      onCut={(e) => preventCopyPaste(e)}
                      className="block w-full text-sm rounded-md border-0 py-4 pl-4 text-gray-900 outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#00AEEF] sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 mr-2 flex items-center cursor-pointer">
                      <div onClick={handleClickShowPasswordCon}>
                        {conshowPassword ? (
                          <VisibilityOutlined className="text-gray-500" />
                        ) : (
                          <VisibilityOff className="text-gray-500" />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-2">
                    {" "}
                    <h3 className={massgecolor}> {confirmPass} </h3>
                  </div> */}
                </div>
                <div className="error-wrapper">
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <p className="errors text-red-500">
                      {formik.errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="flex items-center pt-10">
              <div className="mr-2 w-[46.50%] submit-button">
                <button
                  onClick={onNextPage}
                  type="submit"
                  // disabled={!formik.isValid || !formik.dirty}
                  className="flex w-full justify-center rounded-md  px-3 py-2.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-[#42CC71] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  SUBMIT
                </button>
              </div>
            </div>
            </form>
          </div>

        </div>
      </div>
    </>
  );
};
export default Signuptwo;
