import React from "react";

const NegativesocialImpacts = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Negative social impacts in the supply chain and actions taken
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 w-[10%] h-12">
          GRI 414-2
        </h3>
      </div>
      <table className="border-collapse border mx-4 ">
        <tr>
          <th rowSpan="2" className="w-[20%]">
            Suppliers{" "}
          </th>
          <th rowspan="2" className="border text-sm px-4 w-[25%] py-2">
            Location of Supplier
          </th>
          <th rowspan="2" className="border text-sm px-4 w-[25%] py-2">
            Significant actual and potential negative social impacts
          </th>
        </tr>
        <tr>
          {/* <th className="border text-xs font-bold px-4 py-4">Male</th>
          <th className="border text-xs font-bold px-4 py-4">Female</th>
          <th className="border text-xs font-bold px-4 py-4">Non-Binary</th> */}
        </tr>
        <tr>
          <td className="border text-sm font-bold px-4 py-4 text-center">
            Supplier 1
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
          <td className=" px-1 py-2 text-xs">
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
        </tr>
        <tr>
          <td className="border text-sm font-bold px-4 py-4 text-center">
            + Add Category
          </td>
          <td className="  px-1 py-2 text-xs">
            {" "}
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="10"
            ></input>
          </td>
          <td className="  px-1 py-2 text-xs">
            {" "}
            <input
              className="w-full border m-auto flex justify-center items-center text-center py-2"
              placeholder="12"
            ></input>
          </td>
        </tr>
      </table>
      <div className="flex items-center mx-4 pb-2 mb-4 mt-8">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
            <h2 className="text-gray-600">
              Number of suppliers assessed for social impacts
            </h2>
          </label>
          <input
            id="countriesOfOperation"
            placeholder="Enter a text"
            name="countriesOfOperation"
            autoComplete="off"
            className="mt-2 p-2 border rounded-md w-full"
            // value={formData.countriesOfOperation}
            // onChange={handleInputChange}
            rows={4} // Specify the number of rows to determine the initial height
          />
        </div>
      </div>
      <div className="flex items-center mx-4 pb-2 mb-4">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
            <h2 className="text-gray-600">
              Number of suppliers having negative social impacts with which
              improvements were agreed upon
            </h2>
          </label>
          <input
            id="countriesOfOperation"
            placeholder="Enter a text"
            name="countriesOfOperation"
            autoComplete="off"
            className="mt-2 p-2 border rounded-md w-full"
            // value={formData.countriesOfOperation}
            // onChange={handleInputChange}
            rows={4} // Specify the number of rows to determine the initial height
          />
        </div>
      </div>
      <div className="flex items-center mx-4 pb-2 mb-4">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
            <h2 className="text-gray-600">
              Number of suppliers having negative social impacts with which
              services were terminated
            </h2>
          </label>
          <input
            id="countriesOfOperation"
            placeholder="Enter a text"
            name="countriesOfOperation"
            autoComplete="off"
            className="mt-2 p-2 border rounded-md w-full"
            // value={formData.countriesOfOperation}
            // onChange={handleInputChange}
            rows={4} // Specify the number of rows to determine the initial height
          />
        </div>
      </div>
    </div>
  );
};

export default NegativesocialImpacts;
