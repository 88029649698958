import React, { useState } from 'react';
import Sustainabilityindex from './sustainability-context/Sustainabilityindex';
import PreviousMaterialTopics from './previous-material-topics/PreviousmaterialTopics';
import IndustryspecificTopics from './industry-specific-topics/IndustryspecificTopics';
import StakeHolderEngagement from './stake-holder-engagement/StakeHolderEngagement';
import GenerateListofTopics from './list-of-topics/GenerateListofTopics';
// import paidsubscription from '../../assets/logos/paidsubscription.svg';

const App = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const components = [
    <Sustainabilityindex />,
    <PreviousMaterialTopics />,
    <IndustryspecificTopics />,
    <StakeHolderEngagement />,
    <GenerateListofTopics />,
  ];

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      window.scrollTo(0, 0);
      console.log("Previous button clicked. Current Page:", currentPage);
    }
  };

  const handleNext = () => {
    if (currentPage < components.length - 1) {
      setCurrentPage(currentPage + 1);
      window.scrollTo(0, 0);
      console.log("Next button clicked. Current Page:", currentPage);
    }
  };

  console.log("Current Page:", currentPage);

  return (
    <div className='flex flex-col items-center'>
      <div className='w-full p-4  text-gray-700 relative'>

        {components[currentPage]}
        {/* {showModal && (
          <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-10 backdrop-blur-sm flex items-center justify-center'>
            <div className='bg-white rounded-lg p-4'>
              <img src={paidsubscription} alt='logo' />
            </div>
          </div>
        )} */}
      </div>

      <div className='bottom-12 flex justify-center'>
        {currentPage > 0 && (
          <button
            className='py-3 px-6 bg-gray-300 text-gray-700 disabled:opacity-30 mx-4 my-5 button-neprev'
            onClick={handlePrevious}
          >
            Previous
          </button>
        )}

        {currentPage < components.length - 1 && (
          <button
            className='py-3 my-5 px-6 bg-black text-white disabled:opacity-30 disabled:cursor-not-allowed mx-4 button-neprev'
            onClick={handleNext}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default App;
