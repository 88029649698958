import SustainabilityContext from './SustainabilityContext';
import SustainabilityRealtionship from './SustainabilityRealtionship';

function Organization() {
  const handleGeneralDetailsSubmit = (event) => {
    event.preventDefault();
    // logic for submitting general details section
  };

  const handleBusinessActivitiesSubmit = (event) => {
    event.preventDefault();
    // logic for submitting general details section
  };

  // const handleBusinessRelationshipsSubmit = (event) => {
  //   event.preventDefault();
  //   // logic for submitting general details section
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    // logic for submitting full form
  };

  // section 3

  return (
    <form onSubmit={handleSubmit} className='p-4 rounded-md '>
      <SustainabilityContext
        handleGeneralDetailsSubmit={handleGeneralDetailsSubmit}
        heading='Organisation Details'
      />
      <SustainabilityRealtionship />
    </form>
  );
}

export default Organization;
