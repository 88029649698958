export const industryList = [
  {
    id: 10,
    industry: 'Energy',
    subIndustries: [
      { id: 10101010, subIndustry: 'Oil & Gas Drilling' },
      { id: 10101020, subIndustry: 'Oil & Gas Equipment & Services' },
      { id: 10102010, subIndustry: 'Integrated Oil & Gas' },
      { id: 10102020, subIndustry: 'Oil & Gas Exploration & Production' },
      { id: 10102030, subIndustry: 'Oil & Gas Refining & Marketing' },
      { id: 10102040, subIndustry: 'Oil & Gas Storage & Transportation' },
      { id: 10102050, subIndustry: 'Coal & Consumable Fuels' },
    ],
  },
  {
    id: 1510,
    industry: 'Materials',
    subIndustries: [
      { id: 15101010, subIndustry: 'Commodity Chemicals' },
      { id: 15101020, subIndustry: 'Diversified Chemicals' },
      { id: 15101030, subIndustry: 'Fertilizers & Agricultural Chemicals' },
      { id: 15101040, subIndustry: 'Industrial Gases' },
      { id: 15101050, subIndustry: 'Specialty Chemicals' },
      { id: 15102010, subIndustry: 'Construction Materials' },
      { id: 15103010, subIndustry: 'Metal & Glass Containers' },
      { id: 15103020, subIndustry: 'Paper Packaging' },
      { id: 15104010, subIndustry: 'Aluminum' },
      { id: 15104020, subIndustry: 'Diversified Metals & Mining' },
      { id: 15104025, subIndustry: 'Copper' },
      { id: 15104030, subIndustry: 'Gold' },
      { id: 15104040, subIndustry: 'Precious Metals & Minerals' },
      { id: 15104045, subIndustry: 'Silver' },
      { id: 15104050, subIndustry: 'Steel' },
      { id: 15105010, subIndustry: 'Forest Products' },
      { id: 15105020, subIndustry: 'Paper Products' },
    ],
  },
  //   {
  //     id: 2010,
  //     industry: 'Capital Goods',
  //     subIndustries: [
  //       { id: 20101010, subIndustry: 'Aerospace & Defense' },
  //       { id: 20102010, subIndustry: 'Building Products' },
  //       { id: 20103010, subIndustry: 'Construction & Engineering' },
  //       { id: 20104010, subIndustry: 'Electrical Equipment' },
  //       { id: 20105010, subIndustry: 'Industrial Conglomerates' },
  //       { id: 20106010, subIndustry: 'Machinery' },
  //       { id: 20107010, subIndustry: 'Trading Companies & Distributors' },
  //     ],
  //   },
  {
    id: 20,
    industry: 'Industrials',
    subIndustries: [
      { id: 20101010, subIndustry: 'Aerospace & Defense' },
      { id: 20102010, subIndustry: 'Building Products' },
      { id: 20103010, subIndustry: 'Construction & Engineering' },
      { id: 20104010, subIndustry: 'Electrical Equipment' },
      { id: 20104020, subIndustry: 'Heavy Electrical Equipment' },
      { id: 20105010, subIndustry: 'Industrial Conglomerates' },
      { id: 20106010, subIndustry: 'Machinery' },
      { id: 20201010, subIndustry: 'Commercial Printing' },
      { id: 20201050, subIndustry: 'Environmental & Facilities Services' },
      { id: 20201060, subIndustry: 'Office Services & Supplies' },
      { id: 20201070, subIndustry: 'Diversified Support Services' },
      { id: 20201080, subIndustry: 'Security & Alarm Services' },
      { id: 20202010, subIndustry: 'Human Resource & Employment Services' },
      { id: 20202020, subIndustry: 'Research & Consulting Services' },

      { id: 20301010, subIndustry: 'Air Freight & Logistics' },
      { id: 20302010, subIndustry: 'Airlines' },
      { id: 20303010, subIndustry: 'Marine' },
      { id: 20304010, subIndustry: 'Railroads' },
      { id: 20304020, subIndustry: 'Trucking' },
      { id: 20305010, subIndustry: 'Airport Services' },
      { id: 20305020, subIndustry: 'Highways & Railtracks' },
      { id: 20305030, subIndustry: 'Marine Ports & Services' },
    ],
  },
  {
    id: 25,
    industry: 'Consumer Discretionary',
    subIndustries: [
      { id: 25101010, subIndustry: 'Auto Parts & Equipment' },
      { id: 25101020, subIndustry: 'Tires & Rubber' },
      { id: 25102010, subIndustry: 'Automobile Manufacturers' },
      { id: 25102020, subIndustry: 'Motorcycle Manufacturers' },
      { id: 25201010, subIndustry: 'Consumer Electronics' },
      { id: 25201020, subIndustry: 'Home Furnishings' },
      { id: 25201030, subIndustry: 'Homebuilding' },
      { id: 25201040, subIndustry: 'Household Appliances' },
      { id: 25201050, subIndustry: 'Housewares & Specialties' },
      { id: 25202010, subIndustry: 'Leisure Products' },
      { id: 25203010, subIndustry: 'Apparel, Accessories & Luxury Goods' },
      { id: 25203020, subIndustry: 'Footwear' },
      { id: 25203030, subIndustry: 'Textiles' },
      { id: 25301010, subIndustry: 'Casinos & Gaming' },
      { id: 25301020, subIndustry: 'Hotels, Resorts & Cruise Lines' },
      { id: 25301030, subIndustry: 'Leisure Facilities' },
      { id: 25301040, subIndustry: 'Restaurants' },
      { id: 25302010, subIndustry: 'Education Services' },
      { id: 25302020, subIndustry: 'Specialized Consumer Services' },
      { id: 25501010, subIndustry: 'Distributors' },
      { id: 25502020, subIndustry: 'Internet & Direct Marketing Retail' },
      { id: 25503010, subIndustry: 'Department Stores' },
      { id: 25503020, subIndustry: 'General Merchandise Stores' },
      { id: 25504010, subIndustry: 'Apparel Retail' },
      { id: 25504020, subIndustry: 'Computer & Electronics Retail' },
      { id: 25504030, subIndustry: 'Home Improvement Retail' },
      { id: 25504040, subIndustry: 'Specialty Stores' },
      { id: 25504050, subIndustry: 'Automotive Retail' },
      { id: 25504060, subIndustry: 'Home Furnishing Retail' },
    ],
  },
  {
    id: 30,
    industry: 'Consumer Staples',
    subIndustries: [
      { id: 30101010, subIndustry: 'Drug Retail' },
      { id: 30101020, subIndustry: 'Food Distributors' },
      { id: 30101030, subIndustry: 'Food Retail' },
      { id: 30101040, subIndustry: 'Hypermarkets & Super Centers' },

      { id: 30201010, subIndustry: 'Brewers' },
      { id: 30201020, subIndustry: 'Distillers & Vintners' },
      { id: 30201030, subIndustry: 'Soft Drinks' },
      { id: 302020, subIndustry: 'Food Products' },
      { id: 30202010, subIndustry: 'Agricultural Products' },
      { id: 30202030, subIndustry: 'Packaged Foods & Meats' },
      { id: 30203010, subIndustry: 'Tobacco' },
      { id: 3030, subIndustry: 'Household & Personal Products' },
      { id: 30301010, subIndustry: 'Household Products' },
      { id: 30302010, subIndustry: 'Personal Products' },
    ],
  },
  {
    id: 35,
    industry: 'Health Care',
    subIndustries: [
      { id: 35101010, subIndustry: 'Health Care Equipment' },
      { id: 35101020, subIndustry: 'Health Care Supplies' },
      { id: 35102010, subIndustry: 'Health Care Distributors' },
      { id: 35102015, subIndustry: 'Health Care Services' },
      { id: 35102020, subIndustry: 'Health Care Facilities' },
      { id: 35102030, subIndustry: 'Managed Health Care' },
      { id: 35103010, subIndustry: 'Health Care Technology' },
      { id: 35201010, subIndustry: 'Biotechnology' },
      { id: 35202010, subIndustry: 'Pharmaceuticals' },
      { id: 35203010, subIndustry: 'Life Sciences Tools & Services' },
    ],
  },
  {
    id: 40,
    industry: 'Financials',
    subIndustries: [
      { id: 40101010, subIndustry: 'Diversified Banks' },
      { id: 40101015, subIndustry: 'Regional Banks' },
      { id: 40102010, subIndustry: 'Thrift & Mortgage Finance' },
      { id: 40201020, subIndustry: 'Other Diversified Financial Services' },
      { id: 40201030, subIndustry: 'Multi-Sector Holdings' },
      { id: 40201040, subIndustry: 'Specialized Finance' },
      { id: 40202010, subIndustry: 'Consumer Finance' },
      { id: 40203010, subIndustry: 'Asset Management & Custody Banks' },
      { id: 40203020, subIndustry: 'Investment Banking & Brokerage' },
      { id: 40203030, subIndustry: 'Diversified Capital Markets' },
      { id: 40203040, subIndustry: 'Financial Exchanges & Data' },
      { id: 40204010, subIndustry: 'Mortgage REITs' },
      { id: 40301010, subIndustry: 'Insurance Brokers' },
      { id: 40301020, subIndustry: 'Life & Health Insurance' },
      { id: 40301030, subIndustry: 'Multi-line Insurance' },
      { id: 40301040, subIndustry: 'Property & Casualty Insurance' },
      { id: 40301050, subIndustry: 'Reinsurance' },
    ],
  },
  {
    id: 45,
    industry: 'Information Technology',
    subIndustries: [
      { id: 45102010, subIndustry: 'IT Consulting & Other Services' },
      { id: 45102020, subIndustry: 'Data Processing & Outsourced Services' },
      { id: 45102030, subIndustry: 'Internet Services & Infrastructure' },
      { id: 45103010, subIndustry: 'Application Software' },
      { id: 45103020, subIndustry: 'Systems Software' },
      { id: 45201020, subIndustry: 'Communications Equipment' },
      {
        id: 45202030,
        subIndustry: 'Technology Hardware, Storage & Peripherals',
      },

      { id: 45203010, subIndustry: 'Electronic Equipment & Instruments' },
      { id: 45203015, subIndustry: 'Electronic Components' },
      { id: 45203020, subIndustry: 'Electronic Manufacturing Services' },
      { id: 45203030, subIndustry: 'Technology Distributors' },
      { id: 45301010, subIndustry: 'Semiconductor Equipment' },
      { id: 45301020, subIndustry: 'Semiconductors' },
    ],
  },
  {
    id: 50,
    industry: 'Communication Services',
    subIndustries: [
      { id: 50101010, subIndustry: 'Alternative Carriers' },
      { id: 50101020, subIndustry: 'Integrated Telecommunication Services' },
      { id: 50102010, subIndustry: 'Wireless Telecommunication Services' },
      { id: 55102010, subIndustry: 'Advertising' },
      { id: 50201010, subIndustry: 'Multi-Utilities' },
      { id: 50201020, subIndustry: 'Broadcasting' },
      { id: 50201030, subIndustry: 'Cable & Satellite' },
      { id: 50201040, subIndustry: 'Publishing' },
      {
        id: 50202010,
        subIndustry: 'Movies & Entertainment',
      },
      {
        id: 50202020,
        subIndustry: 'Interactive Home Entertainment',
      },
      { id: 50203010, subIndustry: 'Interactive Media & Services' },
    ],
  },
  {
    id: 55,
    industry: 'Utilities',
    subIndustries: [
      { id: 55101010, subIndustry: 'Electric Utilities' },
      { id: 55102010, subIndustry: 'Gas Utilities' },
      { id: 55103010, subIndustry: 'Multi-Utilities' },
      { id: 55104010, subIndustry: 'Water Utilities' },

      {
        id: 55105010,
        subIndustry: 'Independent Power Producers & Energy Traders',
      },
      { id: 55105020, subIndustry: 'Renewable Electricity' },
    ],
  },
  {
    id: 60,
    industry: 'Real Estate',
    subIndustries: [
      { id: 60101010, subIndustry: 'Diversified REITs' },
      { id: 60101020, subIndustry: 'Industrial REITs' },
      { id: 60101030, subIndustry: 'Hotel & Resort REITs' },
      { id: 60101040, subIndustry: 'Office REITs' },
      { id: 60101050, subIndustry: 'Health Care REITs' },
      { id: 60101060, subIndustry: 'Residential REITs' },
      { id: 60101070, subIndustry: 'Retail REITs' },
      { id: 60101080, subIndustry: 'Specialized REITs' },
      { id: 60102010, subIndustry: 'Diversified Real Estate Activities' },
      { id: 60102020, subIndustry: 'Real Estate Operating Companies' },
      { id: 60102030, subIndustry: 'Real Estate Development' },
      { id: 60102040, subIndustry: 'Real Estate Services' },
    ],
  },
];

export default industryList;
