import React, { useState } from "react";

const FinancialAssistance = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Financial assistance received from government
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 201-4
        </h3>
      </div>
      <div className="w-full">
        <div className="">
          {/* <div className="w-full flex justify-end border-l border-r ">
            <div className="py-2 text-center w-[218px] border-l text-sm">
              <span className="px-2 py-2 font-bold">Number of all Employees</span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-3 py-2 font-bold ">
                No. of workers who are not employees but whose work and/or
                workplace is controlled by the organization
              </span>
            </div>
            <div className="py-2 text-center w-[216px] border-l  text-sm">
              <span className="px-2 py-2 font-bold">Total number of employees</span>
            </div>
          </div> */}
          <p className="mt-6 mb-5 text-gray-500 font-bold">
            Total monetary value of financial assistance received by the
            organization from any government during the reporting period,
            including
          </p>
          <table className=" text-xs text-start">
            <thead className="border">
              <th className="py-2 text-center text-sm">
                <span className="px-2 py-2 font-bold"></span>
              </th>
              <th className="py-2 text-center w-[22%] border-l text-sm">
                <span className="px-2 py-2 font-bold">Select Currency</span>
              </th>
              <th className="py-2 text-center w-[25%] border-l text-sm">
                <span className="px-3 py-2 font-bold ">
                  Select Reporting Period
                </span>
              </th>
              <th className="py-2 text-center w-[22%] border-l text-sm">
                <span className="px-2 py-2 font-bold">Add Country</span>
              </th>
            </thead>
            <tbody className="border">
              <tr>
                <td className="px-8 py-2 font-bold h-[80px] w-[20%] text-sm text-black border-r">
                  Tax relief and tax credits
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px] "
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Subsidies
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Investment grants s, research and development grants, and
                  other relevant types of grant
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Awards
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Royalty holidays
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Financial assistance from Export Credit Agencies (ECAs
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Financial incentives
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
              <tr>
                <td className="px-8 py-2 font-bold w-[20%] h-[80px] text-sm border">
                  Other financial benefits received or receivable from any
                  government for any operation.
                </td>
                <td className="px-12 py-2">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className=" px-12 py-2  ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
                <td className="px-12 py-2 ">
                  <input
                    type="text"
                    name="natureofOwnership"
                    autocomplete="off"
                    className="py-1 border text-center rounded-md w-[60%] m-[3px]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex items-center pb-2 mb-4 mt-6">
          <div className="w-full items-center">
            <label htmlFor="legalForm" className="block font-bold mb-1 w-full">
              <h2 className="text-gray-600">
                Whether, and the extent to which, any government is present in
                the shareholding structure
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border  rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialAssistance;
