import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const WorkrelatedInjuries = () => {
  const [rows, setRows] = useState([{ value: "" }]);
  const [rowsnew, setRowsnew] = useState([{ value: "" }]);
  const { collapseSidebar, collapsed } = useProSidebar();

  const handleAddRow = () => {
    setRows([...rows, { value: "" }]);
  };
  const handleAddRownew = () => {
    setRowsnew([...rowsnew, { value: "" }]);
  };
  const handleChange = (event, index) => {
    const newRows = [...rows];
    newRows[index].value = event.target.value;
    setRows(newRows);
  };
  const removeRow = (index) => {
    const newRows = rows.filter((_, i) => i !== index);
    setRows(newRows);
  };
  const removeRownew = (index) => {
    const newRows = rowsnew.filter((_, i) => i !== index);
    setRowsnew(newRows);
  };
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <>
      <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className="text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px]">Injuries</p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-4">
        {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div
            className={`${
              collapsed ? "w-[78%] " : "w-[75%]"
            } mb-5 text-xs text-start`}
          >
            <label htmlFor="legalForm" className="block  mb-1 pb-4">
              <h2 className="text-gray-500 text-[15px] text-[15px]">
                For all employees, please report the following
              </h2>
            </label>
            <div className="border custom-scrollbar rounded-md shadow-md">
              <div className="mb-5 text-xs text-start overflow-x-scroll">
                <table className="rounded-md px-2 mb-5">
                  <thead className="h-[50px] pb-5 border gradient-background mb-2">
                    <tr className="space-x-2 ">
                      <th className="text-left pl-3 py-2">Employee Category</th>
                      <th className="text-left pl-3">
                        Number of fatalities as a result of work-related injury
                      </th>
                      <th className="text-left pl-3">
                        Number of high-consequence work-related injuries
                        (excluding fatalities)
                      </th>
                      <th className="text-left pl-3 ">
                        Number of recordable work-related injuries
                      </th>
                      <th className="text-left pl-3">
                        Main types of work-related injury
                      </th>
                      <th className="text-left pl-3">Number of hours worked</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="mt-2 mb-3">
                    {rows.map((row, index) => (
                      <tr key={index} className="mt-3">
                        <td>
                          <select
                            className="block w-[210px]  mx-2 py-2 mt-4 pl-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                            value={row.value}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option>- Select -</option>
                            <option>- Commitee 1 -</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>

                        <td className="">
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>

                        <td>
                          {" "}
                          <DeleteOutlineIcon
                            className="text-red-600 cursor-pointer"
                            onClick={() => removeRow(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="mb-2 text-blue-600 py-2 px-4 mx-2 rounded text-xs opacity-70 border border-[#007EEF]"
                  onClick={handleAddRow}
                >
                  Add new row +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div
            className={`${
              collapsed ? "w-[78%] " : "w-[75%]"
            } mb-5 text-xs text-start`}
          >
            <label htmlFor="legalForm" className="block  mb-1 pb-4">
              <h2 className="text-gray-500 text-[15px] text-[15px]">
                For workers who are not employees but whose work and workplace
                is controlled by the organization
              </h2>
            </label>
            <div className="border custom-scrollbar rounded-md shadow-md">
              <div className="mb-5 text-xs text-start overflow-x-scroll">
                <table className="rounded-md px-2 mb-5">
                  <thead className="h-[50px] pb-5 border gradient-background mb-2">
                    <tr className="space-x-2 ">
                      <th className="text-left pl-3 py-2">
                        Workers who are not employees but whose work and/or
                        workplace is controlled by the organization
                      </th>
                      <th className="text-left pl-3">
                        Number of fatalities as a result of work-related injury
                      </th>
                      <th className="text-left pl-3">
                        Number of high-consequence work-related injuries
                        (excluding fatalities)
                      </th>
                      <th className="text-left pl-3 ">
                        Number of recordable work-related injuries
                      </th>
                      <th className="text-left pl-3">
                        Main types of work-related injury
                      </th>
                      <th className="text-left pl-3">Number of hours worked</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="mt-2 mb-3">
                    {rowsnew.map((row, index) => (
                      <tr key={index} className="mt-3">
                        <td>
                          <select
                            className="block w-[210px] mt-4 mx-2 py-2 pl-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                            value={row.value}
                            onChange={(event) => handleChange(event, index)}
                          >
                            <option>- Select -</option>
                            <option>- Commitee 1 -</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>

                        <td className="">
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            name="natureofOwnership"
                            autocomplete="off"
                            placeholder="Enter text"
                            className="block w-[210px]  mx-2 py-2 mt-4 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                          />
                        </td>

                        <td>
                          {" "}
                          <DeleteOutlineIcon
                            className="text-red-600 cursor-pointer"
                            onClick={() => removeRownew(index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="mb-2 text-blue-600 py-2 px-4 mx-2 rounded text-xs opacity-70 border border-[#007EEF]"
                  onClick={handleAddRownew}
                >
                  Add new row +
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            collapsed ? "w-[78%] " : "w-[75%]"
          } mb-5 text-xs text-start`}
        >
          <div className="flex items-center mx-4 mt-8 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  Are there work-related hazards that pose a risk of
                  high-consequence injury?
                </h2>
              </label>
              <select
                // id="natureofOwnership"
                name="natureofOwnership"
                className="mt-1 p-2 border rounded-md w-full mb-2 mb-2"
                // value={formData.natureofOwnership}
                // onChange={handleInputChange}
              >
                <option value="select">- Select - </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <div className="flex items-center mx-4 pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  If yes, How these hazards have been determined?
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={6} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
          <div className="flex items-center mx-4 pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  Which of these hazards have caused high-consequence injuries?
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={6} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
          <div className="flex items-center mx-4 pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  Actions taken or underway to eliminate these hazards and
                  minimize risk
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={6} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
          <div className="flex items-center mx-4 mt-8 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  Whether the rates have been calculated based on 200,000 or
                  1,000,000 hours worked.
                </h2>
              </label>
              <select
                // id="natureofOwnership"
                name="natureofOwnership"
                className="mt-1 p-2 border rounded-md w-full mb-2"
                // value={formData.natureofOwnership}
                // onChange={handleInputChange}
              >
                <option value="select">- Select - </option>
                <option value="yes"> 200,000 hours worked</option>
                <option value="no">1,000,000 hours worked</option>
              </select>
            </div>
          </div>
          <div className="flex items-center mx-4 mt-8 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  Are there any workers have been excluded from this disclosure?
                </h2>
              </label>
              <select
                // id="natureofOwnership"
                name="natureofOwnership"
                className="mt-1 p-2 border rounded-md w-full mb-2"
                // value={formData.natureofOwnership}
                // onChange={handleInputChange}
              >
                <option value="select">- Select - </option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>

          <div className="flex items-center mx-4 pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  If yes, Why the workers are excluded
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={6} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
          <div className="flex items-center mx-4 pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block mb-1 pb-4">
                <h2 className="text-gray-500 text-[15px]">
                  The types of workers excluded
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={6} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkrelatedInjuries;
