import React, { useState } from "react";

const ReportFreqContact = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  //   Page buttons
  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };

  // form contents
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission or file upload logic here
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2.3</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Reporting period, Frequency and Contact Point
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 2-3
        </h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Please specify the reporting frequency for the organization's
                sustainability reporting
              </h2>
            </label>
            <select
              // id="natureofOwnership"
              name="natureofOwnership"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.natureofOwnership}
              // onChange={handleInputChange}
            >
              <option value="select">- Select - </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Does the reporting period for the organization's financial
                reporting align with the period for its sustainability
                reporting?
              </h2>
            </label>
            <select
              id="natureofOwnership"
              name="natureofOwnership"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.natureofOwnership}
              onChange={handleInputChange}
            >
              <option value="select">- Select - </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">If not, then please explain why</h2>
            </label>
            <input
              type="text"
              id="countriesOfOperation"
              autocomplete="off"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.countriesOfOperation}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Please indicate the contact point for questions about the report
              </h2>
            </label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              className="mt-2 p-2 border rounded-md w-full"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              style={{ textTransform: "uppercase" }}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Please indicate the contact point for questions about the report
              </h2>
            </label>
            <input
              type="date"
              // id="dateOfBirth"
              name="dateOfBirth"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.dateOfBirth}
              // onChange={handleInputChange}
              style={{ textTransform: "uppercase" }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportFreqContact;
