import React, { useState } from "react";

const CompensationRatio = () => {
  const [formData, setFormData] = useState({
    legalForm: "",
    natureofOwnership: "",
    headquarters: "",
    countriesOfOperation: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className="container mx-auto">
      {/* <h1 className="mx-4 pt-4 font-semibold">2 text-xs.2 text-xs</h1> */}
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Annual total compensation ratio
        </h1>
        <h3 className="text-end mx-8 w-[7%] bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12 ">
          GRI 2-21
        </h3>
      </div>
      <form>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report the ratio of the annual total compensation for the
                organization’s highest-paid individual to the median annual
                total compensation for all employees (excluding the highest-paid
                individual)
              </h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              autocomplete="off"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report the ratio of the percentage increase in annual total
                compensation for the organization’s highest-paid individual to
                the median percentage increase in annual total compensation for
                all employees (excluding the highest-paid individual);
              </h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              autocomplete="off"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2  mb-4">
          <div className="w-full mr-2 ">
            <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Report contextual information necessary to understand the data
                and how the data has been compiled.
              </h2>
            </label>
            <input
              type="text"
              id="legalForm"
              name="legalForm"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              autocomplete="off"
              value={formData.legalForm}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompensationRatio;
