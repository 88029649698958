import React from "react";

const IncidentsofViolation = () => {
  return (
    <div className="container mx-auto">
      <div className="flex justify-between">
        <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
          Incidents of violations involving rights of indigenous people
        </h1>
        <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
          GRI 411-1
        </h3>
      </div>
      <form>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Are there any identified incidents of violations involving the
                rights of indigenous peoples
              </h2>
            </label>
            <select
              // id="natureofOwnership"
              name="natureofOwnership"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.natureofOwnership}
              // onChange={handleInputChange}
            >
              <option value="select">- Select - </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>
        <h3 className="text-gray-600 font-bold mb-4 mx-4 ">If yes,</h3>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Specify the total number of incident violations involving the
                rights of indigenous peoples
              </h2>
            </label>
            <input
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                No.of workers involed in performing the organizations activites
              </h2>
            </label>
            <input
              id="countriesOfOperation"
              name="countriesOfOperation"
              placeholder="Enter a text"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <h3 className="text-gray-600 font-bold italic mb-4 mx-4">
          Specify the status of the incidents and actions taken{" "}
        </h3>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Please provide details about the Incident being reviewed by the
                organization
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                If Any remediation plans are being implemented
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                Specify the results of the remediation plans implemented
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className="flex items-center mx-4 pb-2 mb-4">
          <div className="w-full mr-2">
            <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
              <h2 className="text-gray-600">
                List which Incidents are no longer subject to action
              </h2>
            </label>
            <textarea
              id="countriesOfOperation"
              placeholder="Enter a text"
              name="countriesOfOperation"
              className="mt-2 p-2 border rounded-md w-full"
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default IncidentsofViolation;
