import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
const DiversityofGovernance1 = () => {
  const [rows, setRows] = useState([{ value: "" }]);
  const { collapseSidebar, collapsed } = useProSidebar();
  const [count, setCount] = useState(2);
  const [tableData, setTableData] = useState([
    ["A"],
    ["Category A", "", "", "",],
 
  ]);

  const addBenefit = () => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newRow = new Array(newData[1].length).fill("");
      newData.push(newRow);
      return newData;
    });
  };
  const removeRow = (index) => {
    setTableData((prevData) => {
      // Creating a new array without the row at the specified index
      const newData = prevData.filter((_, rowIndex) => rowIndex !== index);
      return newData;
    });
  };

  const addCategory = () => {
    setTableData((prevData) => {
      const newData = prevData.map((row, rowIndex) => {
        if (rowIndex === 0) {
          return [...row, ""];
        } else {
          return [...row, ""];
        }
      });
      return newData;
    });

    setCount(count + 1);
  };
  const handleCellChange = (value, rowIndex, cellIndex) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      if (rowIndex === 0 || cellIndex === 0) {
        newData[rowIndex][cellIndex] = value;
      } else {
        newData[rowIndex][cellIndex] = {
          category: value,
          management: value,
          company: value,
        };
      }
      return newData;
    });
  };
  return (
    <>
    <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[95%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className="text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px]">Salary Ratio</p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>
   
    <div className="container mx-auto mt-4">
    <h2 className="text-gray-5600 ml-3">
              {" "}
              Diversity of governance bodies & employees
            </h2>
      <div className="flex items-center mx-4 pb-2 mb-4">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block first-line:mb-1 pb-4">
            <h2 className="text-gray-500 mt-5">
              {" "}
              Define "Significant Locations of Operation"
            </h2>
          </label>
          <textarea
            id="countriesOfOperation"
            name="countriesOfOperation"
            placeholder="Enter a text"
            className="mt-2 p-2 border rounded-md w-full"
            // value={formData.countriesOfOperation}
            // onChange={handleInputChange}
            rows={5} // Specify the number of rows to determine the initial height
          />
        </div>
      </div>
      <p className="font-bold italic text-xs mx-4">
        {" "}
        Ratio of basic salary of women to men{" "}
      </p>
      <div className="flex items-center mx-4 pb-2 mt-3  mb-4">
      <div
          className={`${
            collapsed ? "w-[98%]" : "w-[96%] overflow-x-scroll"
          } mb-5 text-xs text-start`}
        >
          <table className="border-collapse border  w-full  rounded-md shadow-md">
            <thead className="h-[50px] pb-5 border gradient-background mb-2">
              <tr>
                <th rowspan="1" className="border  px-4 py-2">Basic Salary per Employee Category</th>
               
                <th colspan="3" className="text-xs font-bold px-4 py-2 text-center">
                  Gender
                </th>
                <th rowspan="1"></th>
              </tr>

              <tr>
           <th></th>
                <th className="border text-xs font-bold px-4 py-2">Male</th>
                <th className="border text-xs font-bold px-4 py-2">Female</th>
                <th className="border text-xs font-bold px-4 py-2">Non-Binary</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex + 1}>
                  {row.map((cell, cellIndex) => (
                    <>
                    <td
                      key={cellIndex}
                      className="py-2 font-bold text-gray-600 border"
                    >
                      {cellIndex === 0 ? (
                        <input
                          type="text"
                          placeholder="Enter a text"
                          className="w-full py-1 px-2 "
                          value={cell}
                          onChange={(e) =>
                            handleCellChange(
                              e.target.value,
                              rowIndex + 1,
                              cellIndex
                            )
                          }
                        />
                      ) : (
                        <input
                        type="text"
                        name="natureofOwnership"
                        autocomplete="off"
                        placeholder="Enter text"
                        className="block w-[85%] py-2 px-2 mt-2 mx-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                        value={cell}
                        onChange={(e) =>
                          handleCellChange(e.target.value, 0, cellIndex)
                        }
                      />
                     
                      )}
                    </td>
                
                    </>
                  ))}
                              <td>
                    <DeleteOutlineIcon
                     className="text-red-600 cursor-pointer "
                     onClick={() => removeRow(rowIndex)}
                   />
             </td>
                </tr>
        
              ))}
              <tr>
                <td className="pl-2 py-2">
                  <button
                    className="mb-2 text-blue-600 font-bold py-2 px-4 rounded text-xs opacity-70 border border-[#007EEF]"
                    onClick={addBenefit}
                  >
                    + Add new row
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
    </div>
    </>
  );
};

export default DiversityofGovernance1;
