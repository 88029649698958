import React, { useState, useRef } from "react";
import jsonData from "../previous-material-topics/tabledata.json";

const Tooltip = ({ item, isVisible, position }) => {
  const style = {
    top: position.top,
    left: position.left,
    display: isVisible ? "block" : "none",
  };

  return (
    <div className="tooltip-container" style={style}>
      <div className="tooltip-heading">{item.name}</div>
      <div className="tooltip-description">
        This is a description for {item.name}
      </div>
    </div>
  );
};

const EnvironmentList = () => {
  const [rowData, setRowData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const leaveTimeoutRef = useRef(null);

  const handleCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        jsonData.data[index],
      ]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.index !== index)
      );
    }
  };

  const handleMouseEnter = (event, item) => {
    const rowRect = event.target.getBoundingClientRect();
    const tooltipTop = rowRect.top - 110; // Adjust the value to position the tooltip
    const tooltipLeft = rowRect.left + rowRect.width / 2;

    setHoveredItem(item);
    setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
    clearTimeout(leaveTimeoutRef.current);
  };

  const handleMouseLeave = () => {
    leaveTimeoutRef.current = setTimeout(() => {
      setHoveredItem(null);
    }, 100); // Adjust the delay as needed
  };

  const handleSubmit = () => {
    console.log("Selected Items:", selectedItems);
    // Perform your submit logic here
  };

  return (
    <div className="col-span-4 mx-2 bg-white border p-6 main-div">
      <h4 className="text-lg font-semibold mb-6 text-black ">Environment</h4>
      <table className="border-t-2">
        <thead></thead>
        <tbody className="bg-gray-100 cursor-pointer">
          {jsonData.data.map((item, index) => (
            <tr
              key={item.index}
              className="hover:border-b hover:border-black text-gray-600 border-b border-gray-400 border-solid font-semibold items-center"
              onMouseEnter={(event) => handleMouseEnter(event, item)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="flex items-center content-center">
                <td className="text-xs mx-4 flex items-center justify-center col-span-full leading-7 text-left w-fit">
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.index === item.index
                    )}
                    onChange={(event) =>
                      handleCheckboxChange(event, item.index)
                    }
                  />
                </td>
                <td className="text-xs mx-4 flex items-center justify-center col-span-full leading-7 text-left w-fit">
                  {item.name}
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </table>
      {hoveredItem && (
        <Tooltip
          item={hoveredItem}
          isVisible={hoveredItem !== null}
          position={tooltipPosition}
        />
      )}
      <button
        className="bg-gray-500 hover:bg-black-700 text-white font-bold py-2 px-4 mt-4"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
};

export default EnvironmentList;
