import { createSlice } from '@reduxjs/toolkit';

const emissionSlice = createSlice({
  name: 'emission',
  initialState: {
    location: '',
    year: '2022',
    quarter: 'JAN',
    countryCode: '',
    refreshPage: false,
    uploadedFile: null, // Initialize as null
    // fileRaw:'',
    // fileData: {
    //   rowFile: null, // You can store the selected file here
    // },
    rowsState: {
      1: [],
      2: [],
      3: [],
    },
    rowsStateNew: [
      { quarter: 'JAN', 1: [], 2: [], 3: [] },
      { quarter: 'FEB', 1: [], 2: [], 3: [] },
      { quarter: 'MAR', 1: [], 2: [], 3: [] },
      { quarter: 'APR', 1: [], 2: [], 3: [] },
      { quarter: 'MAY', 1: [], 2: [], 3: [] },
      { quarter: 'JUN', 1: [], 2: [], 3: [] },
      { quarter: 'JUL', 1: [], 2: [], 3: [] },
      { quarter: 'AUG', 1: [], 2: [], 3: [] },
      { quarter: 'SEP', 1: [], 2: [], 3: [] },
      { quarter: 'OCT', 1: [], 2: [], 3: [] },
      { quarter: 'NOV', 1: [], 2: [], 3: [] },
      { quarter: 'DEC', 1: [], 2: [], 3: [] },
    ],
  },
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setYear: (state, action) => {
      state.year = action.payload;
    },
    setQuarter: (state, action) => {
      state.quarter = action.payload;
    },
    setCountryCode: (state, action) => {
      state.countryCode = action.payload;
    },
    setRefreshPage: (state, action) => {
      state.refreshPage = action.payload;
    },
    setRowsState: (state, action) => {
      const { scope, rows } = action.payload;
      state.rowsState[scope] = rows?.map((r) => ({ ...r }));
    },
    setFileData:(state, action)=>{
     state.fileRaw = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload; // Store the base64 data in Redux
    },
    setNextQuarter: (state) => {
      const months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ];
      const currentQuarterIndex = months.indexOf(state.quarter);
      const nextQuarterIndex = (currentQuarterIndex + 1) % 12;
      state.quarter = months[nextQuarterIndex];
    },
    setRowsStateNew: (state, action) => {
      const { quarter, scope, rows } = action.payload;
      // Find the index of the quarter in rowsStateNew
      const quarterIndex = state.rowsStateNew.findIndex(
        (item) => item.quarter === quarter
      );
      if (quarterIndex !== -1) {
        // Update rows for the specified scope in the found quarter
        state.rowsStateNew[quarterIndex][scope] = rows.map((r) => ({ ...r }));
      }
    },
  },
});

export const {
  setLocation,
  setYear,
  setQuarter,
  setCountryCode,
  setRefreshPage,
  setRowsState,
  setNextQuarter,
  setRowsStateNew,
  setFileData,
  setUploadedFile,
} = emissionSlice.actions;

export default emissionSlice.reducer;

export const selectRowsState = (state, scope) =>
  state.emission.rowsState[scope];

export const selectRowsStateNew = (state, quarter, scope) => {
  const quarterData = state.emission.rowsStateNew.find(
    (data) => data.quarter === quarter
  );
  if (quarterData) {
    return quarterData[scope];
  }
  return [];
};

export const selectRowsStateByMonth = (state, quarter) => {
  const quarterData = state.emission.rowsStateNew.find(
    (data) => data.quarter === quarter
  );
  if (quarterData) {
    return quarterData;
  }
  return [];
};



