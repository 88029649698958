const Predictiontable = () => {
    return (
      <div className="my-4  text-left container ">
        <div className="mb-2  py-4 px-3 shadow-md ">
      
          <div className="overflow-x-auto ">
            <div className="">
              <table className="min-w-full leading-normal border border-slate-400 rounded-lg  ">
                <thead className="border-s-slate-400 ">
           
                  <tr className="border-s-slate-400 justify-between border-inherit">
                  <th className=" py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider ">
                      S.No
                    </th>
                    <th className=" py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider ">
                    ExperimentalYear
                    </th>
                    <th className=" py-3  px-1    border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    EmployeesComingOffice(P)
                    </th>
                    <th className=" py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Employees(C)
                    </th>
                    <th className=" py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    EnergyEfficiency(P)
                    </th>
                    <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    EVCars(P)
                    </th>
                    <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    NRESources(P)
                    </th>
                    <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    RESources(P)
                    </th>
                    <th className=" py-3  px-1  border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    AirTravelReduction(P)
                    </th>
                  
                  </tr>
                </thead>
                <tbody className="border-s-slate-400">
                  <tr className="border-s-slate-400">
                  <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                       1
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    2,020
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    100
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    15,359
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    0
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    7.5000
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    10
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                    5
                    </td>
                    <td className=" py-5 border-b border-gray-200 bg-white text-sm text-center">
                     25
                    </td>
             
              
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default Predictiontable;
  