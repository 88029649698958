import React from 'react';

const colors = ['#FFD700', '#FF6347', '#4682B4', '#32CD32', '#FF69B4']; // Array of colors

const ProfileImage = ({ firstName = '', lastName = '', spacing, textsize }) => {
  // Default values for firstName and lastName are set to empty strings to avoid undefined
  
  const firstLetters = `${firstName[0] || ''}${lastName[0] || ''}`; // Check for undefined or empty

  // Function to get a random color - It seems like you're not using this, consider removing if unnecessary
  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const style = {
    background: 'linear-gradient(#007eef, #2ae4ff)',
    width: spacing,
    height: spacing,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: textsize,
    fontWeight: 'bold'
  };

  return (
    <div style={style}>
      {firstLetters.toUpperCase()}
    </div>
  );
};

export default ProfileImage;
