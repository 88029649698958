//eslint-disable

import React, { useState } from 'react';

const DelegationImpacts = () => {
  const [formData, setFormData] = useState({
    legalForm: '',
    natureofOwnership: '',
    headquarters: '',
    countriesOfOperation: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div className='container mx-auto '>
      {/* <h1 className="mx-4 pt-4 font-semibold">2 .2 </h1> */}
      <div className='flex justify-between'>
        <h1 className='mx-4 pb-8 text-gray-500 text-xl font-bold'>
          Delegation of responsibility for managing impacts
        </h1>
        <h3 className='text-end mx-8 w-[7%] bg-gray-200 font-bold text-sm h-[3%] w-[80px] border-4 h-12 '>
          GRI 2-13
        </h3>
      </div>
      <form>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block  font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                Describe how the highest governance body delegates
                responsibility for managing the organization's impacts on
                economy, environment and people including: - whether it has
                appointed any senior executives with responsibility for the
                management of impacts - whether it has delegated responsibility
                for the management of impacts to other employees"
              </h2>
            </label>
            <textarea
              id='countriesOfOperation'
              name='countriesOfOperation'
              className='mt-2 p-2 border rounded-md w-full'
              placeholder='Enter a text'
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
        <div className='flex items-center mx-4 pb-2  mb-4'>
          <div className='w-full mr-2 '>
            <label htmlFor='legalForm' className='block  font-bold mb-1 pb-4'>
              <h2 className='text-gray-600'>
                Describe the process and frequency for senior executives or
                other employees to report back to the highest governance body on
                the management of the organization's impacts on the economy,
                environment and people.
              </h2>
            </label>
            <textarea
              id='countriesOfOperation'
              name='countriesOfOperation'
              placeholder='Enter a text'
              className='mt-2 p-2 border rounded-md w-full'
              // value={formData.countriesOfOperation}
              // onChange={handleInputChange}
              rows={4} // Specify the number of rows to determine the initial height
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DelegationImpacts;
