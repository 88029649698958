import React, { useState } from "react";

const Employees = () => {
  const [rows, setRows] = useState([{ value: "" }]);
  const [alphabetLabels, setAlphabetLabels] = useState(["A"]);
  const [selectedPeriod, setSelectedPeriod] = useState("Jan-March");
  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const handleAddRow = () => {
    setRows([...rows, { value: "" }]);
    const lastLabel = alphabetLabels[alphabetLabels.length - 1];
    const nextLabel = String.fromCharCode(lastLabel.charCodeAt(0) + 1);
    setAlphabetLabels([...alphabetLabels, nextLabel]);
  };

  const renderHeadings = () => {
    let headings = [];

    switch (selectedPeriod) {
      case "Jan-March":
        headings = ["Jan", "Feb", "March"];
        break;
      case "April-June":
        headings = ["April", "May", "June"];
        break;
      case "July-Sep":
        headings = ["July", "Aug", "Sep"];
        break;
      case "Oct-Dec":
        headings = ["Oct", "Nov", "Dec"];
        break;
      default:
        headings = [];
    }
    return headings.map((heading, index) => (
      <th
        key={index}
        className="px-4 py-2 text-center w-[280px] border-l border-r text-sm "
      >
        {heading}
      </th>
    ));
  };

  const renderData = () => {
    // Modify the data based on the selected period
    let data = [];

    switch (selectedPeriod) {
      case "Jan-March":
        data = [
          // Data for Jan-March
        ];
        break;
      case "April-June":
        data = [
          // Data for April-June
        ];
        break;
      case "July-Sep":
        data = [
          // Data for July-Sep
        ];
        break;
      case "Oct-Dec":
        data = [
          // Data for Oct-Dec
        ];
        break;
      default:
        data = [];
    }

    return data.map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <td key={cellIndex} className="px-4 py-2 text-sm border">
            {cell}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className="div">
      <div className="overflow-x-scroll rounded-md pt-8">
        <div className="flex justify-between">
          <h1 className="mx-4 pb-8 text-gray-500 text-xl font-bold">
            Total number of employees
          </h1>
          <h3 className="text-end mx-8 bg-gray-200 font-bold text-sm h-[3%] border-4 h-12">
            GRI 2-7
          </h3>
        </div>
        <div className="w-full ">
          <div className="flex mb-6">
            <button
              className={`${
                selectedPeriod === "Jan-March" ? "bg-gray-400" : "bg-gray-200"
              } mr-2 px-2 text-xs  rounded`}
              onClick={() => handlePeriodChange("Jan-March")}
            >
              Jan-Mar
            </button>
            <button
              className={`${
                selectedPeriod === "April-June" ? "bg-gray-400" : "bg-gray-200"
              } mr-2 px-2 text-xs py-2 rounded`}
              onClick={() => handlePeriodChange("April-June")}
            >
              Apr-Jun
            </button>
            <button
              className={`${
                selectedPeriod === "July-Sep" ? "bg-gray-400" : "bg-gray-200"
              } mr-2 px-2 text-xs py-2 rounded`}
              onClick={() => handlePeriodChange("July-Sep")}
            >
              Jul-Sep
            </button>
            <button
              className={`${
                selectedPeriod === "Oct-Dec" ? "bg-gray-400" : "bg-gray-200"
              } mr-2 px-2 text-xs py-2 rounded`}
              onClick={() => handlePeriodChange("Oct-Dec")}
            >
              Oct-Dec
            </button>
          </div>
          <div className="w-full flex justify-end border-l border-r border-t">
            <div className="py-2 text-center  w-[720px] border-l">
              {renderHeadings()}
            </div>
          </div>
          <table className="mb-5 min-w-full text-xs text-start">
            <thead className="border">
              <tr className="border-t border-b">
                <th className="px-4 py-2"></th>
                <th className="px-4 py-2"></th> {/* New column */}
                <th colSpan="3" className="px-4 py-2 text-center ">
                  Region
                </th>
                <th colSpan="3" className="px-4 py-2 text-center border">
                  Region
                </th>
                <th colSpan="3" className="px-4 py-2 text-center ">
                  Region
                </th>
              </tr>
              <tr className="border-t border-b">
                <td className="px-4 py-2 font-bold border">Disclosure</td>
                <td className="px-4 py-2 font-bold border">Gender</td>{" "}
                {/* New column */}
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 1
                </td>
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 2
                </td>
                <td className="px-4 py-2 font-bold border">add area</td>
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 1
                </td>
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 2
                </td>
                <td className="px-4 py-2 font-bold border">add area</td>
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 1
                </td>
                <td className="px-4 py-2 font-bold border">
                  Geographical Area 2
                </td>
                <td className="px-4 py-2 font-bold border">add area</td>
              </tr>
            </thead>
            <tbody className="border-l border-r">
              {rows.map((row, index) => (
                <>
                  <tr>
                    <td className="px-4 py-2 "></td>
                    <td className="px-4 py-2 font-bold border-s">Male</td>{" "}
                    {/* New column */}
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        autocomplete="off"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className="py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="px-4 py-2  font-bold text-center">
                      Number of employees
                    </td>
                    <td className="px-4 py-2 font-bold border-s">Female</td>{" "}
                    {/* New column */}
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        autocomplete="off"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                  <tr className="border-b">
                    <td className="px-4 py-2  border-b"></td>
                    <td className="px-4 py-2 font-bold border-s">
                      Non-Binary
                    </td>{" "}
                    {/* New column */}
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        autocomplete="off"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        autocomplete="off"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        autocomplete="off"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        autocomplete="off"
                        name="natureofOwnership"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                    <td className=" py-2 ">
                      <input
                        type="text"
                        // id="natureofOwnership"
                        name="natureofOwnership"
                        autocomplete="off"
                        className="py-1 border rounded-md w-[90%] m-[3px]"
                        // value={formData.natureofOwnership}
                        // onChange={handleInputChange}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          <button
            className="mb-2 ms-2 bg-gradient-to-r from-[#364161] to-[#06081f] hover:bg-gray-600 text-white font-bold py-2 px-4 rounded text-xs opacity-70"
            onClick={handleAddRow}
          >
            Add Row
          </button>
        </div>
      </div>
      <div className="flex items-center mx-4 pb-2 mb-4 mt-6">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
            <h2 className="text-gray-600">
              Describe the methodologies and assumptions used to compile data,
              including whether the numbers are reported - - in headcount,
              full-time equivalent (FTE), or using another methodology - at the
              end of the reporting period, as an average across the reporting
              period, or using another methodology "{" "}
            </h2>
          </label>
          <textarea
            type="text"
            id="countriesOfOperation"
            autocomplete="off"
            placeholder="Enter a text"
            name="countriesOfOperation"
            className="mt-2 p-2 border rounded-md w-full"
          />
        </div>
      </div>
      <div className="flex items-center mx-4 pb-2 mb-4 mt-6">
        <div className="w-full mr-2">
          <label htmlFor="legalForm" className="block  font-bold mb-1 pb-4">
            <h2 className="text-gray-600">
              Describe significant fluctuations in the number of employees
              during the reporting period and between reporting periods
            </h2>
          </label>
          <textarea
            type="text"
            id="countriesOfOperation"
            autocomplete="off"
            placeholder="Enter a text"
            name="countriesOfOperation"
            className="mt-2 p-2 border rounded-md w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Employees;
