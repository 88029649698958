import React, { useState, useEffect } from "react";
import JoyRide from "react-joyride";
import Pledges from "pages/Home/Pledges";
import Tasks from "pages/Home/Tasks";
import SustainextHQ from "./useronboarding";
import ESGperformance from "../../assets/logos/ESGperformance.png";
import paidsubscription from "../../assets/logos/paidsubscription.svg";
import SustainextHQClone from "./homeCloneOnboarding";

const Home = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const [showModal, setShowModal] = useState(true);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="overflow-scroll">
        <div className=" ms-6">
          <div className="m-4 gradient-text text-opacity-20 text-[22px] font-semibold leading-relaxed ">
            Sustainext HQ
          </div>
          <div className="flex flex-col h-screen">
            <div className={`flex my-6 mx-4 border-b`}>
              <button
                className={`pr-4 py-1 rounded-b-none text-sm font-bold leading-[15px] sustainext-hq ${
                  activeTab === "tab1"
                    ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                    : "border-transparent text-neutral-500"
                }`}
                onClick={() => handleTabChange("tab1")}
              >
                Dashboard
              </button>
              {/* <button
                className={`px-4 py-1 rounded-b-none text-sm font-bold leading-[15px] ${
                  activeTab === "tab2"
                    ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                    : "border-transparent text-neutral-500"
                }`}
                onClick={() => handleTabChange("tab2")}
              >
                ESG Performance
              </button> */}
              {/* <button
                className={`px-4 py-1 rounded-b-none text-sm font-bold leading-[15px] ${
                  activeTab === "tab3"
                    ? "border-b-2 border-[#1aaef4] text-[#1aaef4]"
                    : "border-transparent text-neutral-500"
                }`}
                onClick={() => handleTabChange("tab3")}
              >
                Tasks
              </button> */}
              <div className="ml-auto mb-2">
              <SustainextHQClone/>
              </div>
            </div>

            <div className="flex-grow">
              <div className="flex-grow">
                {activeTab === "tab1" && <Pledges />}
                {activeTab === "tab2" && (
                  <div className="relative">
                    {showModal && (
                      <div className="yoyyo absolute z-50 bg-gray-700 bg-opacity-30 backdrop-blur-sm flex items-center justify-center">
                        <div className="bg-white rounded-lg p-4">
                          <img src={paidsubscription} alt="logo" />
                        </div>
                      </div>
                    )}
                    <img
                      src={ESGperformance}
                      alt="..."
                      className="w-[70%] h-[70%] mt-10 ms-10 object-cover rounded border-none"
                    />
                  </div>
                )}
                {activeTab === "tab3" && <Tasks />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
