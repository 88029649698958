import React, { useState, useEffect, useRef } from "react";
import MultiAxisLineChart from "./line-chart";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import HelpIcon from "@mui/icons-material/Help";
import { Link, useNavigate } from "react-router-dom";
const Experimentalvariables = () => {
  let navigate = useNavigate();
  const [eco, setEco] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [disableFieldsone, setDisableFieldsone] = useState(false);
  const [disableFieldstwo, setDisableFieldstwo] = useState(false);
  const [disableFieldsthree, setDisableFieldsthree] = useState(false);
  const [disableFieldsfour, setDisableFieldsfour] = useState(false);
  const [disableFieldsfive, setDisableFieldsfive] = useState(false);
  const [disableFieldssix, setDisableFieldssix] = useState(false);
  const [employees, setEmployees] = useState(false);
  const [ee, setEe] = useState(false);
  const [evcars, setEvcars] = useState(false);
  const [nresources, setNresources] = useState(false);
  const [resources, setResources] = useState(false);
  const [airtravelreduction, setAirtravelreduction] = useState(false);
  const [tableshow, setTableshow] = useState(false);
  const [chartShownform, setChartShownform] = useState(false);
  const handleRadioChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'dynamic') {
      setDisableFields(true);
    } else {
      setDisableFields(false);
    }
  };
  const handleRadioChangeone = (event) => {
    const selectedValueone = event.target.value;
    if (selectedValueone === 'dynamic') {
      setDisableFieldsone(true);
    } else {
      setDisableFieldsone(false);
    }
  };
  const handleRadioChangetwo = (event) => {
    const selectedValueywo = event.target.value;
    if (selectedValueywo === 'dynamic') {
      setDisableFieldstwo(true);
    } else {
      setDisableFieldstwo(false);
    }
  }


  const handleRadioChangethree = (event) => {
    const selectedValuethree = event.target.value;
    if (selectedValuethree === 'dynamic') {
      setDisableFieldsthree(true);
    } else {
      setDisableFieldsthree(false);
    }
  };
  const handleRadioChangefour = (event) => {
    const selectedValuefor = event.target.value;
    if (selectedValuefor === 'dynamic') {
      setDisableFieldsfour(true);
    } else {
      setDisableFieldsfour(false);
    }
  };
  const handleRadioChangefive = (event) => {
    const selectedValuefive = event.target.value;
    if (selectedValuefive === 'dynamic') {
      setDisableFieldsfive(true);
    } else {
      setDisableFieldsfive(false);
    }
  };
  const handleRadioChangesix = (event) => {
    const selectedValuesix = event.target.value;
    if (selectedValuesix === 'dynamic') {
      setDisableFieldssix(true);
    } else {
      setDisableFieldssix(false);
    }
  };
const back = () =>{
  navigate(`/Create-Scenarios`);
};
  const [tableData, setTableData] = useState([
    {
      id: 1,
      ExperimentalYear: 2023,
      EmployeesComingOffice: 25,
      Employees: 25,
      EnergyEfficiency: 1255,
      EVCars: 452,
      NRESources: 2558,
      RESources: 14525,
      AirTravelReduction: 4852,
    },
  ]);

  const addRow = () => {
    const newRow = {
      id: tableData.length + 1,
      ExperimentalYear: "",
      EmployeesComingOffice: "",
      Employees: "",
      EVCars: "",
      NRESources: "",
      RESources: "",
      AirTravelReduction: "",
    };
    setTableData([...tableData, newRow]);
  };

  const removeRow = (id) => {
    const updatedData = tableData.filter((row) => row.id !== id);
    setTableData(updatedData);
  };

  const handleInputChange = (id, field, value) => {
    const updatedData = tableData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setTableData(updatedData);
  };
  const Eco = () => {
    setEco(!eco);
  };
  const Employees = () => {
    setEmployees(!employees);
  };
  const Ee = () => {
    setEe(!ee);
  };
  const Evcars = () => {
    setEvcars(!evcars);
  };
  const Nresources = () => {
    setNresources(!nresources);
  };
  const Resources = () => {
    setResources(!resources);
  };
  const Airtravelreduction = () => {
    setAirtravelreduction(!airtravelreduction);
  };
  const tableshwo = () => {
    setTableshow(!tableshow);
  };
  const chartshwo = () => {
    setChartShownform((current) => !current);
  };
  return (
    <>
      <div className="my-4 mx-5 text-left normal-case">
        <div className="mb-2  py-4 px-3 shadow-md ">
          <h1 className="text-left mb-5 text-lg ">
            <b>Select Experimental Variables</b>
          </h1>
          <div className="grid grid-cols-2 md:grid-cols-2 mb-4">
            <div>
              <div>
                <div className="mr-2 flex mb-2">
                  <label
                    htmlFor="cname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Experimental Year
                  </label>
                  <div className="ml-2">
                    <input
                      id="cname"
                      name="cname"
                      type="text"
                      autoComplete="cname"
                      required
                      placeholder="2023"
                      className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="Eco"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="Eco"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Eco()}
                  >
                    Employees coming office(p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="eso" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="eso"
            place="right"
            content="An experimental value of percentage of employees that will come to office."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="employees"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="employees"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Employees()}
                  >
                    Employees (c)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="emp" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="emp"
            place="right"
            content="An experimental value of employee count that will change in an organization."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="Ee"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="Ee"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Ee()}
                  >
                    Energy efficiency (p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="ee" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="ee"
            place="right"
            content="An experimental value ef percentage ef energy efficiency to include in the calculations."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="evcars"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="evcars"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Evcars()}
                  >
                    Evcars (p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="evc" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="evc"
            place="right"
            content="An experimental value of percentage EV cars that will increase in an organization."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="nresources"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="nresources"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Nresources()}
                  >
                    Nresources (p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="nvc" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="nvc"
            place="right"
            content="An experimental value of percentage direct reduction in NRE sources to include in the calculations."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="resources"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="resources"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Resources()}
                  >
                    Resources(p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="rc" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="rc"
            place="right"
            content="An experimental value of percentage RE sources to include in the calculations."
          />
        </div>
                </div>
                <div className="flex items-center gap-x-3 text-left mb-5">
                  <input
                    id="airtravelreduction"
                    type="checkbox"
                    className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="airtravelreduction"
                    className="block text-sm font-medium leading-6 text-gray-900 w-[216px]"
                    onClick={() => Airtravelreduction()}
                  >
                    Airtravelreduction(p)
                  </label>
                  <div className="ml-3 mt-1">
          <HelpIcon id="ari" style={{fontSize:20}}/>
          <ReactTooltip
            anchorId="ari"
            place="right"
            content="An experimental value of percentage air travel reduction to include in the calculations."
          />
        </div>
                </div>
              </div>
            </div>
            <div>
              {eco && (
                <>
                  <div className="flex mt-8">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="esStatic"
                        type="radio"
                        name="selectyear"
                        value="static"
                        onChange={handleRadioChange}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="esStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="esDynamic"
                        type="radio"
                        name="selectyear"
                        value="dynamic"
                        onChange={handleRadioChange}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="esDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFields}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {employees && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="emStatic"
                        type="radio"
                        name="emStatic"
                        value="static"
                        onChange={handleRadioChangeone}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="emStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="emDynamic"
                        type="radio"
                        name="emStatic"
                        value="dynamic"
                        onChange={handleRadioChangeone}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="emDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldsone}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {ee && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="eeStatic"
                        type="radio"
                        name="ee"
                        value="static"
                        onChange={handleRadioChangetwo}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="eeStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="eeDynamic"
                        type="radio"
                        name="ee"
                        value="dynamic"
                        onChange={handleRadioChangetwo}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="eeDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldstwo}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {evcars && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="evStatic"
                        type="radio"
                        name="ev"
                        value="static"
                        onChange={handleRadioChangethree}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="evStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="evDynamic"
                        type="radio"
                        name="ev"
                        value="dynamic"
                        onChange={handleRadioChangethree}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="evDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldsthree}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {nresources && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="emrStatic"
                        type="radio"
                        name="emr"
                        value="static"
                        onChange={handleRadioChangefour}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="enrStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="enrDynamic"
                        type="radio"
                        name="emr"
                        value="dynamic"
                        onChange={handleRadioChangefour}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="enrDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldsfour}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {resources && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="ereStatic"
                        type="radio"
                        name="ere"
                        value="static"
                        onChange={handleRadioChangefive}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="ereStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="ereDynamic"
                        type="radio"
                        name="ere"
                        value="dynamic"
                        onChange={handleRadioChangefive}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="ereDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldsfive}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {airtravelreduction && (
                <>
                  <div className="flex">
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="eaiStatic"
                        type="radio"
                        name="eai"
                        value="static"
                        onChange={handleRadioChangesix}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="eaiStatic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Static
                      </label>
                    </div>
                    <div className="flex items-center gap-x-3 text-left">
                      <input
                        id="eaiDynamic"
                        type="radio"
                        name="eai"
                        value="dynamic"
                        onChange={handleRadioChangesix}
                        className="h-3.5 w-3.5 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="eaiDynamic"
                        className="block text-sm font-medium leading-6 text-gray-900 w-[100px]"
                      >
                        Dynamic
                      </label>
                    </div>
                    <div className="mr-2">
                      <div className="mt-2">
                        <input
                          id="cname"
                          name="cname"
                          type="text"
                          autoComplete="cname"
                          required
                          placeholder="2000"
                          disabled={disableFieldssix}
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[20%]">
              {" "}
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                onClick={tableshwo}
              >
                Generate UI Table
              </button>
            </div>
          </div>
        </div>
      </div>
      {tableshow && (
        <>
          <div className="p-4">
            <table className="min-w-full leading-normal border border-slate-400 rounded-lg  ">
              <thead className="border-s-slate-400 ">
                <tr className="border-s-slate-400 justify-between border-inherit">
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    ExperimentalYear
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    EmployeesComingOffice(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Employees(C)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    EnergyEfficiency(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    EVCars(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    NRESources(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    RESources(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    {" "}
                    AirTravelReduction(P)
                  </th>
                  <th className="py-3  px-1   border-b-2 border-gray-200 bg-gray-100 text-left text-[9px] font-semibold text-gray-700 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row.id} className="border-b">
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.ExperimentalYear}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "ExperimentalYear",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.EmployeesComingOffice}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "EmployeesComingOffice",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="text"
                        value={row.Employees}
                        onChange={(e) =>
                          handleInputChange(row.id, "Employees", e.target.value)
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.EnergyEfficiency}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "EnergyEfficiency",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.EVCars}
                        onChange={(e) =>
                          handleInputChange(row.id, "EVCars", e.target.value)
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.NRESources}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "NRESources",
                            e.target.value
                          )
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.RESources}
                        onChange={(e) =>
                          handleInputChange(row.id, "RESources", e.target.value)
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <input
                        type="number"
                        value={row.AirTravelReduction}
                        onChange={(e) =>
                          handleInputChange(
                            row.id,
                            "AirTravelReduction",
                            parseInt(e.target.value)
                          )
                        }
                        className="border rounded px-2 py-1 w-full"
                      />
                    </td>
                    <td className="py-2 px-4">
                      <button
                        onClick={() => removeRow(row.id)}
                        className="bg-red-500 hover:bg-red-600 text-white rounded px-2 py-1"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button
              onClick={addRow}
              className="mt-4 bg-green-500 hover:bg-green-600 text-white rounded px-4 py-2"
            >
              Add Row
            </button>
          </div>
          <div className="flex justify-center">
            <div className="w-[15%]">
              {" "}
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                onClick={chartshwo}
              >
                Predict Emissions
              </button>
            </div>
          </div>
        </>
      )}
      {chartShownform && (
        <>
          <div>
            <MultiAxisLineChart />
          </div>
          <div>
            <div className="flex justify-center mb-5 mt-5">
              <div className="w-[15%]">
                {" "}
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                >
                  Discard
                </button>
              </div>

              <div className="w-[15%] ml-5">
                {" "}
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gradient-to-r from-[#364161] to-[#06081f]  py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover: bg-gradient-to-r from-[#364161] to-[#06081f] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                  onClick={back}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Experimentalvariables;
