import React, { useState, useEffect } from 'react';
import Energyheader from '../header/Energyheader';
import { useProSidebar } from "react-pro-sidebar";
import { Energydata } from "components/data/info/energydata";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Energyintensitybody from './energy-intensity-body';
const NewTooltip = ({ tooltiptext }) => {


  return (
    <Tooltip title={tooltiptext} arrow placement="top" componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#000',
          color: 'white',
          fontSize: '12px',
          boxShadow: 3,
          borderRadius: "8px"
        },
      },
      arrow: {
        sx: {
          color: '#000',
        },
      },
    }}>
      <InfoOutlinedIcon sx={{ fontSize: "14px", marginLeft: "5px" ,color:"#000" }} />
    </Tooltip>
  );
};
const Energyintensity = () => {
  const [activeMonth, setActiveMonth] = useState("Jan");
  const [data, setData] = useState();
  const [category, setCategory] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { collapsed } = useProSidebar();
  const toggleDrawerclose = () => {
    setIsOpen(!isOpen);
  }
  const toggleDrawer = (selected) => {
    setIsOpen(!isOpen);
    setCategory(selected);
  };
  useEffect(() => {
    var newData = [];
    Energydata.map((program) => {
      program.category.map((tag) => {
        if (tag === category) {
          newData.push(program);
        }
      })
    })
    // //console.log(newData);
    setData(newData);
  }, [category])

  return (
    <>
      <div className="flex flex-col justify-start overflow-x-hidden ">


        <div className="flex justify-between items-center border-b border-gray-200 mb-5">
        <div
            className={`${collapsed ? "w-[90%] " : "w-[75%]"
              } flex justify-between items-center`}
          >
            <div className="text-left mb-4 ml-3 pt-5">
              <p className="text-sm">Environment</p>
              <div className='flex'>
                <div>
                  <p className="gradient-text text-[22px] font-bold">
                    Energy
                  </p>
                </div>
                <div className="bg-gray-100 h-[22px] w-[95px]  mx-2 mt-2 rounded-md" >
                  <p className="text-gray-500 text-[12px] pt-0.5 px-2">Material Topic</p>
                </div>
              </div>

            </div>
            <div className="flex  absolute right-[4.5rem] border-l">
              <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('16')}>GRI 302-3</button>
              <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 " onClick={() => toggleDrawer('2')}>SDG 7</button>
              <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('3')}>SDG 8</button>
              <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5 " onClick={() => toggleDrawer('4')}>SDG 12</button>
              <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[72px] h-[22px] ml-2 text-center pt-0.5" onClick={() => toggleDrawer('5')}>SDG 13</button>
            </div>
          </div>

        </div>
    <div className="ml-3">
          <h6 className="text-[17px] mb-4 font-semibold">

          Energy Intensity
            <NewTooltip tooltiptext="This section is dedicated to the calculation of Energy Intensity
Ratios based on organizational metrics. These ratios quantify the
 energy demand per unit of activity, output, or any other organization-specific metric" />
          </h6>
        </div>
        <div className={`${isOpen ? "translate-x-[15%] block" : "translate-x-[120%] hidden"}
      fixed right-[51px]  w-[340px] h-full bg-white  rounded-md
      transition-transform duration-300 ease-in-out z-[100] shadow-2xl px-2`}>

          {data && data.map((program) => (
            <>
              <div className="flex justify-between p-2 pt-5 pb-4 border-b-2 ">
                <div className="ml-2">
                  {program.header}
                </div>

                <div className="ml-2 float-right">
                  <h5 className="text-[#727272] text-[17px] font-bold cursor-pointer" onClick={toggleDrawerclose}><ClearOutlinedIcon /></h5>
                </div>

              </div>
              <div> {program.data}</div>
            </>
          ))}

        </div>
      </div>
      <Energyheader activeMonth={activeMonth} setActiveMonth={setActiveMonth} />
      <Energyintensitybody/>



    </>

  );
};

export default Energyintensity;
