import React, { useState, useRef } from "react";
import download from "../../../../src/assets/favicons/download.png";
import jsonData from "../previous-material-topics/tabledata.json";

const Tooltip = ({ item, isVisible, position }) => {
  const style = {
    top: position.top,
    left: position.left,
    display: isVisible ? "block" : "none",
  };

  return (
    <div className="tooltip-container" style={style}>
      <div className="tooltip-heading">{item.name}</div>
      <div className="tooltip-description">
        This is a description for {item.name}
      </div>
    </div>
  );
};

const IST_Social = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const leaveTimeoutRef = useRef(null);

  const handleMouseEnter = (event, item) => {
    const rowRect = event.target.getBoundingClientRect();
    const tooltipTop = rowRect.top - 110; // Adjust the value to position the tooltip
    const tooltipLeft = rowRect.left + rowRect.width / 2;

    setHoveredItem(item);
    setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
    clearTimeout(leaveTimeoutRef.current);
  };

  const handleMouseLeave = () => {
    leaveTimeoutRef.current = setTimeout(() => {
      setHoveredItem(null);
    }, 100); // Adjust the delay as needed
  };

  return (
    <div
      className="col-span-4  mx-2 bg-white shadow-right-bottom p-6 main-div"
    >
      <h4 className="font-extrabold pb-4 text-2xl ">Social</h4>
      <table className="border-t-2">
        <thead>
          <tr className="flex justify-between pb-4 pt-4">
            <th className="text-xs issue ">Issue</th>
            <th className="text-xs issue">Average weight</th>
          </tr>
        </thead>
        <tbody className="bg-green-100 cursor-pointer">
          {jsonData.data1.map((item, index) => (
            <tr
              key={item.index}
              className={`hover:border-b hover:border-black tooltip border-b border-gray-400 border-solid ${
                item.percentage !== "" && item.percentage >= 0
                  ? "font-black"
                  : "font-semibold text-gray-600"
              } flex items-center justify-between ${
                item.percentage !== "" && item.percentage >= 0
                  ? "bg-amber-100"
                  : "bg-gray-100"
              }`}
              onMouseEnter={(event) => handleMouseEnter(event, item)}
              onMouseLeave={handleMouseLeave}
            >
              <td className="text-xs leading-6 text-right w-full">
                {item.name}
              </td>
              <td className="text-xs mx-2 leading-6 text-right w-1/5">
                {item.percentage !== "" ? item.percentage + "%" : ""}
              </td>
              <div className="flex items-center justify-between">
                <div className="w-2.5 h-2.5 bg-amber-400 px-2 border"></div>
                <div className="w-2.5 h-2.5 bg-amber-400 px-2 border"></div>
                <div className="w-2.5 h-2.5 bg-amber-400 px-2 border"></div>
              </div>
              <td className="w-1/5 px-2">
                {item.image === "download.png" && (
                  <img src={download} width="15" height="10" />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {hoveredItem && (
        <Tooltip
          item={hoveredItem}
          isVisible={hoveredItem !== null}
          position={tooltipPosition}
        />
      )}
    </div>
  );
};

export default IST_Social;
