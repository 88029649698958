import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import vectorimage from "../../../assets/images/Vector.svg";
import valentaimage from "../../../assets/sustainext.png";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function CoverSheet({
  display,
  imageSrc,
  setSelectedImage,
  selectedImage,
  setImage,
}) {
  // const [selectedImage, setSelectedImage] = useState();
  const [imageName, setImageName] = useState(null);
  const [errorimg, setErrorimg] = useState("");
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const reportname = localStorage.getItem("reportorgname");
  const handleFileChangenew = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Converting the selected File to a Blob (the File API is actually already a Blob)
      const blob = new Blob([file], { type: file.type });
      console.log('Blob details:', blob);

      // Converting the Blob back to a File
      const newFile = new File([blob], file.name, { type: file.type });
      console.log('New File details:', newFile);

      setSelectedFile(newFile);
    }
  };
  useEffect(() => {
    // Attempt to load the stored image from localStorage when the component mounts
    const storedImage = localStorage.getItem("selectedImage");
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    let errorMessages = "";

    if (!selectedFile) {
      return; // No file selected
    }

    if (selectedFile.type !== "image/png") {
      errorMessages = "Only PNG images are allowed.";
    } else if (selectedFile.size > 2097152) {
      errorMessages = "File size must not exceed 2MB."; // 2MB in bytes
    } else {
      // File is valid
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;
        // Display the selected image
        localStorage.setItem("selectedImage", base64String);
        setSelectedImage(reader.result);
        setImage(selectedFile); // Display the selected image
        // setImage(selectedFile); // Set the file to parent component state if needed
      };
      reader.readAsDataURL(selectedFile);
    }
    setError(errorMessages);
  };

  const fileInputRef = React.createRef();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="box rounded-lg p-4 h-[497px]">
        <div className="flex items-center justify-between">
          <div className="flex mt-5">
            <h1 className="text-lg">
              <p className="text-gray-500">Organisation name</p>
            </h1>
            <h1 className="ml-2">-</h1>
            <h1 className="text-lg text-gray-500 ml-2">{reportname}</h1>
          </div>
          <div className="flex mt-5">
            <h1 className="text-lg">
              <p className="text-gray-500">Year</p>
            </h1>
            <h1 className="ml-2">:</h1>
            <h1 className="text-lg text-gray-500 ml-2">{display}</h1>
          </div>
        </div>

        <div className="mt-10 flex justify-between">
          <div>
            <div className="mt-4 w-[150px]">
              <img
                src={selectedImage || imageSrc || valentaimage}
                alt="Organization"
              />
            </div>
          </div>

          {/* <div className="flex flex-col justify-center items-center mx-5 -ml-10 w-50% mr-[550px]">
            {selectedImage ? (
              <div>
                <button
                  className="mt-2 py-2 px-4 bg-gray-200 font-bold text-sm text-gray-700 rounded-md shadow-sm focus:outline-none"
                  onClick={handleImageDelete}
                >
                  Upload Complete
                  <span className="ml-2">✅</span>
                </button>
                <div className="mt-2 text-sm text-gray-700">{imageName}</div>
              </div>
            ) : (
              <label className="block cursor-pointer" htmlFor="file_input">
                <div className="py-2 px-4 bg-gray-200 font-bold text-sm text-gray-700 rounded-md shadow-sm focus:outline-none">
                  <span className="mr-2">📁</span> Upload file
                </div>
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="file_input"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
              </label>
            )}
          </div> */}
        </div>
        <div className="flex mt-5">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            style={{ display: "none" }}
            accept="image/png" // Hide the file input
          />
          <button onClick={handleButtonClick} className="upload-button">
            Upload Image <CloudUploadIcon sx={{ ml: 1 }} />
          </button>
        </div>
        <div>
          {error && <p className="text-red-500">{error}</p>}

          <h1 className="text-sm mt-2 text-gray-500">
            Upload image file of organization
          </h1>
        </div>
        <div className="mt-5 flex mb-12">
          <h1 className="text-sm text-left">
            <b>Provided by -</b>
          </h1>
          <h1 className="text-sm text-left  ml-2">
            <b>Sustainext</b>
          </h1>
        </div>
      </div>
      {/* <input type="file" onChange={handleFileChangenew} accept="image/*" /> */}


    </>
  );
}
export default CoverSheet;
