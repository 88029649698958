// src/VerticalCarousel.js
import React, { useState } from "react";
import Signuptwo from "./Register2";
import SignupPersonalDetails from "./personal-details";
import SignupWorkDetails from "./work-details";
import ConfirmationLink from "./confirmation-link";

const VerticalCarousel = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    if (currentPage < 4) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="flex">
      {/* Vertical Indicators */}
      <div className="w-[20px] flex flex-col ml-6 justify-center items-center">
      {currentPage < 4 && (
        <div className="w-[20px] flex flex-col ml-6 justify-center items-center">
          <div
            className={`${
              currentPage === 1 ? 'bg-green-500' : 'bg-gray-300'
            } indicator h-12 w-1 mb-2 rounded-lg`}
          ></div>
          <div
            className={`${
              currentPage === 2 ? 'bg-green-500' : 'bg-gray-300'
            } indicator h-12 w-1 mb-2 rounded-lg`}
          ></div>
          <div
            className={`${
              currentPage === 3 ? 'bg-green-500' : 'bg-gray-300'
            } indicator h-12 w-1 rounded-lg`}
          ></div>
        </div>
      )}
      </div>
      <div className="w-full">
        {currentPage === 1 && <Signuptwo onNextPage={handleNextPage} />}
        {currentPage === 2 && (
          <SignupPersonalDetails
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        )}
        {currentPage === 3 && (
          <SignupWorkDetails
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        )}
        {currentPage === 4 && (
          <ConfirmationLink
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        )}
      </div>
    </div>
  );
};

export default VerticalCarousel;
