import React, { useState, useEffect } from 'react';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import RisksandOpportunities from './RisksandOpportunities';
import DefinedbenefitPlan from './DefinedbenefitPlan';
import ProportionofSenior from './ProportionofSenior';
import InfrastructureInvestments from './InfrastructureInvestments';
import SignificantIndirect from './SignificantIndirect';
import ProportionofSpending from './ProportionofSpending';
import OperationsAssessd from './OperationsAssessd';
import EconomicSidepanel from 'components/collect/EconomicSidepanel';
import { useProSidebar } from 'react-pro-sidebar';
import DirectEconomicvalue from './DirectEconomicvalue';
import FinancialAssistance from './FinancialAssistance';
import RatiosofStandard from './RatiosofStandard';
import ConfirmedIncidents from './ConfirmedIncidents';
import LegalActions from './LegalActions';
import ApproachTax from './ApproachTax';
import ControlandRisk from './ControlandRisk';
import StakeholderEngagement from './StakeholderEngagement';
import CountrybyCountry from './CountrybyCountry';
import paidsubscription from '../../assets/logos/paidsubscription.svg';

const Economic = () => {
  const [activeTab, setActiveTab] = useState('Value Generation & Distribution');
  const [showModal, setShowModal] = useState(false);
  const { collapseSidebar, collapsed } = useProSidebar();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPageCount = 17;

  const sections = [
    <RisksandOpportunities />,
    <DefinedbenefitPlan />,
    <ProportionofSenior />,
    <InfrastructureInvestments />,
    <SignificantIndirect />,
    <ProportionofSpending />,
    <OperationsAssessd />,
    <DirectEconomicvalue />,
  ];

  // const CurrentPageComponent = components[currentPage - 1];

  useEffect(() => {
    setActiveTab('Value Generation & Distribution');
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const CurrentPageComponent = components[currentPage - 1];

  const handlePrevious = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the previous one
    if (currentIndex > 0) {
      const prevTab = sections[currentIndex - 1];
      setActiveTab(prevTab);
      setCurrentPage(currentIndex);
      window.scrollTo(0, 0);
    }
  };

  const handleNext = () => {
    // Find the index of the current activeTab in the sections array
    const currentIndex = sections.indexOf(activeTab);

    // If the current activeTab is found in the array, navigate to the next one
    if (currentIndex < sections.length - 1) {
      const nextTab = sections[currentIndex + 1];
      setActiveTab(nextTab);
      setCurrentPage(currentIndex + 2); // Add 2 since currentPage is 1-indexed
      window.scrollTo(0, 0);
    }
  };

  const handleSubmit = () => {
    // Handle the submit action here
  };

  const isLastPage = currentPage === totalPageCount;
  const isFirstPage = currentPage === 1;

  return (
    <>
      <div className=' h-full relative'>
        {showModal && (
          <div className='fixed inset-0 z-50 bg-gray-700 bg-opacity-10 backdrop-blur-sm flex items-center justify-center'>
            <div className='bg-white rounded-lg p-4'>
              <img src={paidsubscription} alt='logo' />
            </div>
          </div>
        )}
        <div className='flex'>
          <EconomicSidepanel
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <div
            className={`${
              collapsed ? 'max-w-[83%] py-8' : 'max-w-[70%] py-8'
            } transition-all`}
          >
            {activeTab === 'Value Generation & Distribution' && (
              <DirectEconomicvalue />
            )}
            {activeTab === 'Financial Impacts of Climate Change' && (
              <RisksandOpportunities />
            )}
            {activeTab === 'Defined Benefit Obligations' && (
              <DefinedbenefitPlan />
            )}
            {activeTab === 'Financial Aid Received' && <FinancialAssistance />}
            {activeTab === 'Gender Pay Equality' && <RatiosofStandard />}
            {activeTab === 'Local Hiring for Management' && (
              <ProportionofSenior />
            )}
            {activeTab === 'Investments & Services' && (
              <InfrastructureInvestments />
            )}
            {activeTab === 'Significant Indirect Impacts' && (
              <SignificantIndirect />
            )}
            {activeTab === 'Local Suppliers Spend' && <ProportionofSpending />}
            {activeTab === 'Corruption-risk Evaluation' && (
              <OperationsAssessd />
            )}
            {activeTab === 'Communication & Training' && (
              <DirectEconomicvalue />
            )}
            {activeTab === 'Confirmed Incidents' && <ConfirmedIncidents />}
            {activeTab === 'Anti-competitive Behaviour' && <LegalActions />}
            {activeTab === 'Tax Approach' && <ApproachTax />}
            {activeTab === 'Control & Risk Management' && <ControlandRisk />}
            {activeTab === 'Tax-related Engagement' && (
              <StakeholderEngagement />
            )}
            {activeTab === 'Tax Reporting by Country' && <CountrybyCountry />}
          </div>
        </div>
        <div className='flex justify-center absolute bottom-2 w-full'>
          <p className='text-gray-500 text-md font-bold pb-10'>
            Page {currentPage}/{totalPageCount}
          </p>
        </div>
        <div className='flex justify-between  absolute bottom-4 w-[96%]'>
          <button
            className='bg-gray-200 px-4 py-2 rounded-md text-gray-700 disabled:opacity-50'
            onClick={handlePrevious}
            disabled={isFirstPage}
          >
            <ArrowBack />
          </button>

          {isLastPage ? (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleSubmit}
            >
              Submit
            </button>
          ) : (
            <button
              className='bg-green-500 px-4 py-2 rounded-md text-white disabled:opacity-50'
              onClick={handleNext}
            >
              <ArrowForward />
            </button>
          )}
        </div>
      </div>
      {totalPageCount && <div className='h-16' />}{' '}
      {/* Add space at the bottom */}
    </>
  );
};

export default Economic;
