import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {
  AddOutlined,
  SaveOutlined,
  RemoveOutlined,
  DeleteOutlineOutlined,
  InfoOutlined,
} from '@mui/icons-material';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import jsonData from '../sustainability-context/businessdata.json';

const SustainabilityRealtionship = () => {
  const [showRelationInput, setShowRelationInput] = useState(false);

  const [showStakeholderGroupInput, setShowStakeholderGroupInput] =
    useState(false);

  const [showStakeholderInput, setShowStakeholderInput] = useState(false);

  const handleAddNew = () => {
    setShowRelationInput(!showRelationInput);
  };

  //Axios Config and call

  const [token, setToken] = useState(
    useSelector((state) => state.global.authToken)
  );

  let axiosConfig = {
    headers: {
      Authorization: 'Token ' + token,
      'Content-type': 'application/json',
    },
  };

  // Business Relationships
  const [formValues, setFormValues] = useState([]);

  const [section1Fields, setSection1Fields] = useState({
    relationship_with_partners: '',
    type_of_activities: '',
    nature_of_relationship: '',
  });

  const handleChangeSection1 = (event) => {
    setSection1Fields({
      ...section1Fields,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/business-relationship/',
        axiosConfig
      )
      .then((response) => {
        setFormValues(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }

  const handleEditRow = (index) => {
    const id = formValues[index].id;
    const updatedData = {
      relationship_with_partners: section1Fields.relationship_with_partners,
      type_of_activities: section1Fields.type_of_activities,
      nature_of_relationship: section1Fields.nature_of_relationship,
    };
    axios
      .put(
        `http://bmw.sustainext.staging.azte.in/api/v1/organisation/sub-organisation/${id}`,
        updatedData
      )
      .then((response) => {
        const newFormValues = [...formValues];
        newFormValues[index] = response.data;
        setFormValues(newFormValues);
        setSection1Fields({ field1: '', field2: '', field3: '' });
        setShowRelationInput(false);
      })
      .catch((error) => {
        console.error('Error updating data: ', error);
      });
  };

  const handleDeleteBRRow = (index) => {
    const id = formValues[index].id;
    axios
      .delete(
        `http://bmw.sustainext.staging.azte.in/api/v1/organisation/sub-organisation/${id}`
      )
      .then(() => {
        const newFormValues = [...formValues];
        newFormValues.splice(index, 1);
        setFormValues(newFormValues);
        console.log(newFormValues);
      })
      .catch((error) => {
        console.error('Error deleting data: ', error);
      });
  };

  const handleSubmitBusinessRelationships = () => {
    axios
      .post(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/business-relationship/',
        {
          ...section1Fields,
          sub_organisation: 35,
          content_type: 14,
          object_id: 1,
        },
        axiosConfig
      )
      .then((res) => {
        console.log('Business Relationship created', res['data']);
        setFormValues([...formValues, res.data]);
        setSection1Fields({
          relationship_with_partners: '',
          type_of_activities: '',
          nature_of_relationship: '',
        });
        setShowRelationInput(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  // Stakeholder Groups
  const [groupValues, setGroupValues] = useState([]);

  const [stakeGroupFields, setStakeGroupFields] = useState({
    name: '',
    type: '',
  });

  const handleChangeStakeGroup = (event) => {
    const { name, value } = event.target;
    if (name === 'type') {
      // handle dropdown value for field4
      setStakeGroupFields({
        ...stakeGroupFields,
        type: value,
      });
    } else {
      // handle all other input fields
      setStakeGroupFields({
        ...stakeGroupFields,
        [name]: value,
      });
    }
  };

  const handleAddNewStackholderGroup = () => {
    if (showStakeholderGroupInput) {
      setStakeGroupFields({
        name: '',
        type: '',
      });
    }
    setShowStakeholderGroupInput(!showStakeholderGroupInput);
  };

  useEffect(() => {
    fetchStakeGroup();
  }, []);

  function fetchStakeGroup() {
    axios
      .get(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder-group/',
        axiosConfig
      )
      .then((response) => {
        setGroupValues(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }

  const handleSubmitStakeGroup = (event) => {
    axios
      .post(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder-group/',
        {
          ...stakeGroupFields,
          sub_organisation: 35,
          content_type: 14,
          object_id: 1,
        },
        axiosConfig
      )
      .then((res) => {
        console.log('Stakeholder group created', res['data']);
        setFormValues([...formValues, res.data]);
        setStakeGroupFields({
          name: '',
          type: '',
        });
        setShowRelationInput(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  const handleDeleteSGRow = (index) => {
    const id = groupValues[index].id;
    axios
      .delete(
        `http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder-group/${id}`,
        {
          id: 1,
          relationship_with_partners: '',
          type_of_activities: '',
          nature_of_relationship: '',
          sub_organisation: null,
        }
      )
      .then(() => {
        const newGroupValues = [...groupValues];
        newGroupValues.splice(index, 1);
        setGroupValues(newGroupValues);
        console.log(newGroupValues);
      })
      .catch((error) => {
        console.error('Error deleting data: ', error);
      });
  };

  // Stakeholders

  const [stakeholderFields, setStakeholderFields] = useState({
    group_name: 0,
    name: '',
    email: '',
    // field4: '',
  });

  const [stakeholderValues, setStakeholderValues] = useState([]);

  const handleChangeStakeholder = (event) => {
    const { name, value } = event.target;
    if (name === 'field4') {
      // handle dropdown value for field4
      setStakeholderFields({
        ...stakeholderFields,
        field4: value,
      });
    } else {
      // handle all other input fields
      setStakeholderFields({
        ...stakeholderFields,
        [name]: value,
      });
    }
  };

  const handleAddNewStackholder = () => {
    setShowStakeholderInput(!showStakeholderInput);
  };

  useEffect(() => {
    fetchStakeholder();
  }, []);

  function fetchStakeholder() {
    axios
      .get(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder/',
        axiosConfig
      )
      .then((response) => {
        setStakeholderValues(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }

  const handleDeleteSHRow = (index) => {
    const id = stakeholderValues[index].id;
    axios
      .delete(
        `http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder/${id}`,
        {
          id: 1,
          relationship_with_partners: '',
          type_of_activities: '',
          nature_of_relationship: '',
          sub_organisation: null,
        }
      )
      .then(() => {
        const newGroupValues = [...stakeholderValues];
        newGroupValues.splice(index, 1);
        setStakeholderValues(newGroupValues);
        console.log(newGroupValues);
      })
      .catch((error) => {
        console.error('Error deleting data: ', error);
      });
  };

  const handleSubmitStakeholder = (event) => {
    axios
      .post(
        'http://bmw.sustainext.staging.azte.in/api/v1/organisation/stakeholder/',
        {
          ...stakeholderFields,
          sub_organisation: 35,
          content_type: 14,
          object_id: 1,
        },
        {
          headers: {
            Authorization: 'Token ' + token,
            'Content-type': 'application/json',
            Accept: 'application/json',
          },
          responseType: 'json',
        }
      )
      .then((res) => {
        console.log('Stakeholder created', res['data']);
        setStakeholderValues([...stakeholderValues, res.data]);
        setStakeholderFields({
          group_name: '',
          name: '',
          email: '',
          field4: '',
        });
        setShowRelationInput(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  return (
    <div>
      <h3 className='text-lg text-gray-700 font-semibold mt-[3rem]'>
        BUSINESS RELATIONSHIPS
      </h3>
      <section className='mb-10'>
        <div>
          <table className='w-full mt-8'>
            <thead>
              <tr>
                <th className='border py-2 w-[280px] text-gray-700 text-sm space-x-2'>
                  <span>Partnerships</span>
                  <a
                    data-tooltip-id='my-tooltip'
                    data-tooltip-content='Relationship with Business Partners, value chain entities'
                    data-tooltip-place='top'
                    data-tooltip-variant='dark'
                  >
                    <InfoOutlined
                      style={{ fontSize: '16px', cursor: 'none' }}
                    />
                  </a>
                  <span className='font-normal'>
                    <Tooltip id='my-tooltip' />
                  </span>
                </th>
                <th className='border py-2 w-[280px] text-gray-700 text-sm'>
                  Activities
                </th>
                <th className='border py-2 w-[280px] text-gray-700 text-sm'>
                  Relationship
                </th>
                <th className='py-2 w-11 font-normal cursor-pointer'></th>
              </tr>
            </thead>
            <tbody>
              {jsonData.data.map((item, index) => (
                <tr key={item.index} className='text-center'>
                  <td className='border py-2 text-sm'>
                    {item.partnerships}
                  </td>
                  <td className='border py-2 text-sm'>
                    {item.Activities}
                  </td>
                  <td className='border py-2 text-sm'>
                    {item.relationship}
                  </td>
                </tr>
              ))}
              {showRelationInput && (
                <tr>
                  <td className='border'>
                    <input
                      className='appearance-nonew-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm'
                      id='relationship_with_partners'
                      name='relationship_with_partners'
                      type='text'
                      value={section1Fields.relationship_with_partners}
                      onChange={handleChangeSection1}
                    />
                  </td>
                  <td className='border'>
                    <input
                      className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm'
                      id='type_of_activities'
                      name='type_of_activities'
                      type='text'
                      value={section1Fields.type_of_activities}
                      onChange={handleChangeSection1}
                    />
                  </td>
                  <td className='border'>
                    <input
                      className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm'
                      id='nature_of_relationship'
                      name='nature_of_relationship'
                      type='text'
                      value={section1Fields.nature_of_relationship}
                      onChange={handleChangeSection1}
                    />
                  </td>
                  <td className=''>
                    <button
                      className='py-2 px-3 w-full'
                      type='button'
                      onClick={handleSubmitBusinessRelationships}
                    >
                      <SaveOutlined
                        style={{ fontSize: '20px', color: 'green' }}
                      />
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      {/* Stackholder Group */}
      <section className='mb-10'>
        <h4 className='text-lg text-gray-700 font-semibold mt-[1rem]'>STAKEHOLDER GROUPS</h4>
        <div>
          <table className='w-full mt-8'>
            <thead>
              <tr>
                <th className='border py-2 text-sm w-[385px]'>Group Name</th>
                <th className='border py-2 text-sm w-[385px]'>Int/Ext</th>
                <th className='py-2 w-11 font-normal cursor-pointer'></th>
              </tr>
            </thead>
            <tbody>
              {jsonData.data1.map((item, index) => (
                <tr key={index} className='text-center'>
                  <td className='border py-2 text-sm'>{item.groupname}</td>
                  <td className='border py-2 text-sm'>{item.intext}</td>
                </tr>
              ))}
              {showStakeholderGroupInput && (
                <tr>
                  <td className='border'>
                    <input
                      className='appearance-nonew-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='name'
                      name='name'
                      type='text'
                      value={stakeGroupFields.name}
                      onChange={handleChangeStakeGroup}
                    />
                  </td>

                  <td className='border'>
                    <select
                      className='appearance-none w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm'
                      id='type'
                      name='type'
                      type='text'
                      value={stakeGroupFields.type}
                      onChange={handleChangeStakeGroup}
                    >
                      <option>Select an option</option>
                      <option>Internal</option>
                      <option>External</option>
                    </select>
                  </td>
                  <td>
                    <button
                      className='py-2 px-3 w-full'
                      type='button'
                      onClick={handleSubmitStakeGroup}
                    >
                      <SaveOutlined
                        style={{ fontSize: '20px', color: 'green' }}
                      />
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      {/* Stakeholders */}
    </div>
  );
};

export default SustainabilityRealtionship;
