export const unitTypes = [
  {
    unit_type: 'Area',
    units: {
      area_unit: ['m2', 'km2', 'ft2', 'ha'],
    },
  },
  {
    unit_type: 'AreaOverTime',
    units: {
      area_unit: ['m2', 'km2', 'ft2', 'ha'],
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
  {
    unit_type: 'ContainerOverDistance',
    units: {
      container_unit: ['containers'],
      distance_unit: ['m', 'km', 'ft', 'mi', 'nmi'],
    },
  },
  {
    unit_type: 'Data',
    units: {
      data_unit: ['MB', 'GB', 'TB'],
    },
  },
  {
    unit_type: 'DataOverTime',
    units: {
      data_unit: ['MB', 'GB', 'TB'],
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
  {
    unit_type: 'Distance',
    units: {
      distance_unit: ['m', 'km', 'ft', 'mi', 'nmi'],
    },
  },
  {
    unit_type: 'DistanceOverTime',
    units: {
      distance_unit: ['m', 'km', 'ft', 'mi', 'nmi'],
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
  {
    unit_type: 'Energy',
    units: {
      energy_unit: [
        'Wh',
        'kWh',
        'MWh',
        'MJ',
        'GJ',
        'TJ',
        'BTU',
        'therm',
        'MMBTU',
      ],
    },
  },
  {
    unit_type: 'Money',
    units: {
      money_unit: [
        'usd',
        'afn',
        'dzd',
        'ars',
        'aud',
        'bhd',
        'brl',
        'cad',
        'kyd',
        'cny',
        'dkk',
        'egp',
        'eur',
        'hkd',
        'huf',
        'isk',
        'inr',
        'iqd',
        'ils',
        'jpy',
        'lbp',
        'mxn',
        'mad',
        'nzd',
        'nok',
        'qar',
        'rub',
        'sar',
        'sgd',
        'zar',
        'krw',
        'sek',
        'chf',
        'thb',
        'twd',
        'tnd',
        'try',
        'aed',
        'gbp',
      ],
    },
  },
  {
    unit_type: 'Number',
    units: {
      number_units: ['number of Nights'],
    },
  },
  {
    unit_type: 'NumberOverTime',
    units: {
      number_unit: ['numbers'],
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
  {
    unit_type: 'PassengerOverDistance',
    units: {
      passenger_unit: ['passengers'],
      distance_unit: ['m', 'km', 'ft', 'mi', 'nmi'],
    },
  },
  {
    unit_type: 'Time',
    units: {
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
  {
    unit_type: 'Volume',
    units: {
      volume_unit: ['ml', 'l', 'm3', 'standard_cubic_foot', 'gallon_us', 'bbl'],
    },
  },
  {
    unit_type: 'Weight',
    units: {
      weight_unit: ['g', 'kg', 't', 'ton', 'lb'],
    },
  },
  {
    unit_type: 'WeightOverDistance',
    units: {
      weight_unit: ['g', 'kg', 't', 'ton', 'lb'],
      distance_unit: ['m', 'km', 'ft', 'mi', 'nmi'],
    },
  },
  {
    unit_type: 'WeightOverTime',
    units: {
      weight_unit: ['g', 'kg', 't', 'ton', 'lb'],
      time_unit: ['ms', 's', 'm', 'h', 'day', 'year'],
    },
  },
];
