import React, { useState } from "react";
import { useProSidebar } from "react-pro-sidebar";
const WorkersCoveredOH = () => {
  const { collapseSidebar, collapsed } = useProSidebar();
  return (
    <>
      <div className="flex justify-between items-center shadow-md border-gray-100">
        <div
          className={`${
            collapsed ? "w-[90%] " : "w-[75%]"
          } flex justify-between items-center`}
        >
          <div className="text-left mb-5 ml-6 mt-4">
            <p className="text-sm">Social</p>
            <p className="gradient-text text-[22px]">
              OHS Management System Coverage
            </p>
          </div>
          <div className="flex  absolute right-[4.5rem] mt-3">
            <button className="text-[#007EEF] bg-slate-200 rounded-full text-[11px] w-[60px] h-[25px]">
              GRI 302-1
            </button>
            <button className="text-[#fff] bg-amber-400 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 7
            </button>
            <button className="text-[#fff] bg-red-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 8
            </button>
            <button className="text-[#fff] bg-yellow-600 rounded-full text-[11px] w-[60px] h-[25px] ml-2 ">
              SDG 12
            </button>
            <button className="text-[#fff] bg-lime-900 rounded-full text-[11px] w-[60px] h-[25px] ml-2">
              SDG 13
            </button>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-2 mt-4">
        <div className="w-full">
          <div
            className={`${
              collapsed ? "w-[99%] " : "w-[99%]"
            } mb-5 text-xs text-start`}
          >
            {/* <div className="w-full flex justify-end border-l border-r ">
            <div className="py-2 text-left w-[218px] border-l text-sm">
              <span className="px-2 py-2 font-bold">Number of all Employees</span>
            </div>
            <div className="py-2 text-left w-[216px] border-l  text-sm">
              <span className="px-3 py-2 font-bold ">
                No. of workers who are not employees but whose work and/or
                workplace is controlled by the organization
              </span>
            </div>
            <div className="py-2 text-left w-[216px] border-l  text-sm">
              <span className="px-2 py-2 font-bold">Total number of employees</span>
            </div>
          </div> */}
            <table className="text-xs text-start border shadow-md">
              <thead className="gradient-background ">
                <th className="py-2 text-left  text-[13px] w-[20%] text-gray-500 px-2">
                  Benefits
                </th>
                <th className="py-2 text-left  text-[13px] w-[20%] text-gray-500 px-2">
                  Management
                </th>
                <th className="py-2 text-left  text-[13px] w-[20%] text-gray-500 px-2">
                  No. of workers who are not employees but whose work and/or
                  workplace is controlled by the organization
                </th>
                <th className="py-2 text-left text-[13px] w-[20%] text-gray-500 px-2">
                  Contribution %
                </th>
              </thead>
              <tbody className="">
                <tr>
                  <td className="py-2 h-[80px] w-[20%] text-gray-500 text-[13px] px-2">
                    Covered by the system
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2  px-2 ">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-2 h-[80px] w-[20%] text-gray-500 text-[13px] px-2">
                    Internally audited
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="py-2 h-[80px] w-[20%] text-gray-500 text-[13px] px-2">
                    Audited or certified by an external party.
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                  <td className="py-2 px-2">
                    <input
                      type="text"
                      name="natureofOwnership"
                      autocomplete="off"
                      placeholder="Enter text"
                      className="block w-[210px] py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 border-b border-gray-300"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="flex items-center mt-10 pb-2 mb-4">
              <div className="w-full mr-2">
                <label
                  htmlFor="legalForm"
                  className="block font-bold mb-1 pb-4"
                >
                  <h2 className="text-gray-400 text-[15px]">
                    Is there an approach to prevent or mitigate significant
                    negative occupational health and safety impacts that are
                    directly linked to operations?
                  </h2>
                </label>
                <select
                  // id="natureofOwnership"
                  name="natureofOwnership"
                  className="mt-2 p-2 border rounded-md w-full"
                  // value={formData.natureofOwnership}
                  // onChange={handleInputChange}
                >
                  <option value="select">- Select - </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex items-center pb-2 mb-4">
            <div className="w-full mr-2">
              <label htmlFor="legalForm" className="block font-bold mb-1 pb-4">
                <h2 className="text-gray-400 text-[15px]">
                  If yes, Descibe the approach
                </h2>
              </label>
              <textarea
                id="countriesOfOperation"
                name="countriesOfOperation"
                placeholder="Enter a text"
                className="mt-2 p-2 border rounded-md w-full"
                // value={formData.countriesOfOperation}
                // onChange={handleInputChange}
                rows={4} // Specify the number of rows to determine the initial height
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkersCoveredOH;
