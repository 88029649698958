import React, { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from '@mui/material/Tooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
const tabs = ['Leased employees', 'Permanent Employee', 'Nonguaranteed Hours Employees', 'Full-Time Employee', 'Part-Time Employee'];
function convertShortNameToFullName(shortName) {
    const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const fullMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const index = shortMonthNames.indexOf(shortName);
    return index !== -1 ? fullMonthNames[index] : 'Unknown';
}
const Employeehier = ({ activeMonth }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [isClicked, setIsClicked] = useState(false);
    const data = 0;
    const fullName = convertShortNameToFullName(activeMonth);
    const [rows, setRows] = useState([
        { under30: 10, between30And50: 10, over50: 10, total: 30 },
        { under30: 10, between30And50: 10, over50: 10, total: 30 },
        { under30: 10, between30And50: 10, over50: 10, total: 30 },
    ]);
    const categories = [
        {
            title: 'Male',
        },
        {
            title: 'Female',
        },
        {
            title: 'Others',
        },

    ];

    const handleInputChange = (event, index, gender) => {
        const updatedRows = [...rows];
        const updatedValue = event.target.value ? parseInt(event.target.value, 10) : "";

        updatedRows[index][gender] = updatedValue;

        // Calculate total for the row
        updatedRows[index].total = Object.values(updatedRows[index]).slice(0, -1).reduce((sum, value) => sum + (parseInt(value) || 0), 0);

        setRows(updatedRows);
    };
    const handleeditClick = () => {
        setIsClicked(!isClicked);
    };
    return (
        <>

            <>
                <div className="flex justify-between mb-5">
                    <div className="ml-3">
                        <h6 className="text-[15px] mb-4">

                            New Employee Hire    <Tooltip title="This section documents data corresponding to the total number and
rate of new employee hire during the reporting period, categorized
 by age group, gender, and region." arrow placement="top" componentsProps={{
                                    tooltip: {
                                        sx: {
                                            backgroundColor: '#000',
                                            color: 'white',
                                            fontSize: '12px',
                                            boxShadow: 3,
                                            borderRadius: "8px"
                                        },
                                    },
                                    arrow: {
                                        sx: {
                                            color: '#000',
                                        },
                                    },
                                }}>
                                <InfoOutlinedIcon sx={{ fontSize: "14px", }} />
                            </Tooltip>
                        </h6>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div className="bg-blue-100 h-[25px] rounded-md">
                            <p className="text-blue-800 text-[12px] inline-block align-middle px-2">GRI 401-1a</p>
                        </div>
                        <div>

                            <>
                                {data !== null ? (
                                    <>
                                        {isClicked ? (
                                            <>
                                                <CloseOutlinedIcon
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={handleeditClick}
                                                />
                                                <SaveOutlinedIcon
                                                    sx={{ ml: 2, cursor: "pointer" }} />
                                            </>
                                        ) : (
                                            <EditOutlinedIcon onClick={handleeditClick} sx={{ cursor: "pointer" }} />
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>

                        </div>
                    </div>
                </div>

                <div className="shadow-md rounded-md">
                    {/* Tabs */}
                    <ul className="flex cursor-pointer">
                        {tabs.map((tab, index) => (
                            <li
                                key={index}
                                className={`flex-1 text-center border-b-2 py-2 text-[13px] ${activeTab === tab ? 'border-blue-500 text-blue-600' : 'border-gray-300 text-gray-600'
                                    }`}
                                onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </li>
                        ))}
                    </ul>
                    {/* Content */}
                    <div className="mt-2">
                        {activeTab === 'Leased employees' && <div className="mb-5">
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto shadow-md">
                                    <thead className="gradient-background text-slate-500  text-sm">
                                        <tr>
                                            <th className="border-r"></th>
                                            <th colSpan="3" className="font-normal py-2">{fullName}</th>
                                            <th className="border-l"></th>
                                        </tr>
                                        <tr>

                                            <th className="px-4 py-2 border-r font-normal">Age group</th>
                                            <th className="px-4 py-2 font-normal border">&lt; 30 years old</th>
                                            <th className="px-4 py-2 font-normal border">30 - 50 years old</th>
                                            <th className="px-4 py-2 font-normal border">&gt; 50 years old</th>
                                            <th className="px-4 py-2 border-l font-normal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {data !== null ? (

                                                <>
                                                    {isClicked ? (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].under30}
                                                                            onChange={e => handleInputChange(e, index, 'under30')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].between30And50}
                                                                            onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                            className="w-full text-center  text-slate-500 font-normal text-sm hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].over50}
                                                                            onChange={e => handleInputChange(e, index, 'over50')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].under30}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].between30And50}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm ">{rows[index].over50}</td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {categories && categories.map((category, index) => (
                                                        <tr key={index} className="text-center">

                                                            <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].under30}
                                                                    onChange={e => handleInputChange(e, index, 'under30')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].between30And50}
                                                                    onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].over50}
                                                                    onChange={e => handleInputChange(e, index, 'over50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y border-l">{rows[index].total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {activeTab === 'Permanent Employee' && <div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto shadow-md">
                                    <thead className="gradient-background text-slate-500  text-sm">
                                        <tr>
                                            <th className="border-r"></th>
                                            <th colSpan="3" className="font-normal py-2">{fullName}</th>
                                            <th className="border-l"></th>
                                        </tr>
                                        <tr>
                                            <th className="px-4 py-2 border-r font-normal">Age group</th>
                                            <th className="px-4 py-2 font-normal border">&lt; 30 years old</th>
                                            <th className="px-4 py-2 font-normal border">30 - 50 years old</th>
                                            <th className="px-4 py-2 font-normal border">&gt; 50 years old</th>
                                            <th className="px-4 py-2 border-l font-normal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                            {data !== null ? (

                                                <>
                                                    {isClicked ? (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].under30}
                                                                            onChange={e => handleInputChange(e, index, 'under30')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].between30And50}
                                                                            onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                            className="w-full text-center  text-slate-500 font-normal text-sm hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].over50}
                                                                            onChange={e => handleInputChange(e, index, 'over50')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].under30}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].between30And50}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm ">{rows[index].over50}</td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {categories && categories.map((category, index) => (
                                                        <tr key={index} className="text-center">

                                                            <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].under30}
                                                                    onChange={e => handleInputChange(e, index, 'under30')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].between30And50}
                                                                    onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].over50}
                                                                    onChange={e => handleInputChange(e, index, 'over50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y border-l">{rows[index].total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {activeTab === 'Nonguaranteed Hours Employees' && <div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto shadow-md">
                                    <thead className="gradient-background text-slate-500  text-sm">
                                        <tr>
                                            <th className="border-r"></th>
                                            <th colSpan="3" className="font-normal py-2">{fullName}</th>
                                            <th className="border-l"></th>
                                        </tr>
                                        <tr>

                                            <th className="px-4 py-2 border-r font-normal">Age group</th>
                                            <th className="px-4 py-2 font-normal border">&lt; 30 years old</th>
                                            <th className="px-4 py-2 font-normal border">30 - 50 years old</th>
                                            <th className="px-4 py-2 font-normal border">&gt; 50 years old</th>
                                            <th className="px-4 py-2 border-l font-normal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                            {data !== null ? (

                                                <>
                                                    {isClicked ? (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].under30}
                                                                            onChange={e => handleInputChange(e, index, 'under30')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].between30And50}
                                                                            onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                            className="w-full text-center  text-slate-500 font-normal text-sm hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].over50}
                                                                            onChange={e => handleInputChange(e, index, 'over50')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].under30}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].between30And50}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm ">{rows[index].over50}</td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {categories && categories.map((category, index) => (
                                                        <tr key={index} className="text-center">

                                                            <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].under30}
                                                                    onChange={e => handleInputChange(e, index, 'under30')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].between30And50}
                                                                    onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].over50}
                                                                    onChange={e => handleInputChange(e, index, 'over50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y border-l">{rows[index].total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {activeTab === 'Full-Time Employee' && <div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto shadow-md">
                                    <thead className="gradient-background text-slate-500  text-sm">
                                        <tr>
                                            <th className="border-r"></th>
                                            <th colSpan="3" className="font-normal py-2">{fullName}</th>
                                            <th className="border-l"></th>
                                        </tr>
                                        <tr>

                                            <th className="px-4 py-2 border-r font-normal">Age group</th>
                                            <th className="px-4 py-2 font-normal border">&lt; 30 years old</th>
                                            <th className="px-4 py-2 font-normal border">30 - 50 years old</th>
                                            <th className="px-4 py-2 font-normal border">&gt; 50 years old</th>
                                            <th className="px-4 py-2 border-l font-normal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                            {data !== null ? (

                                                <>
                                                    {isClicked ? (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].under30}
                                                                            onChange={e => handleInputChange(e, index, 'under30')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].between30And50}
                                                                            onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                            className="w-full text-center  text-slate-500 font-normal text-sm hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].over50}
                                                                            onChange={e => handleInputChange(e, index, 'over50')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].under30}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].between30And50}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm ">{rows[index].over50}</td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {categories && categories.map((category, index) => (
                                                        <tr key={index} className="text-center">

                                                            <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].under30}
                                                                    onChange={e => handleInputChange(e, index, 'under30')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].between30And50}
                                                                    onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].over50}
                                                                    onChange={e => handleInputChange(e, index, 'over50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y border-l">{rows[index].total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        {activeTab === 'Part-Time Employee' && <div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full table-auto shadow-md">
                                    <thead className="gradient-background text-slate-500  text-sm">
                                        <tr>
                                            <th className="border-r"></th>
                                            <th colSpan="3" className="font-normal py-2">{fullName}</th>
                                            <th className="border-l"></th>
                                        </tr>
                                        <tr>

                                            <th className="px-4 py-2 border-r font-normal">Age group</th>
                                            <th className="px-4 py-2 font-normal border">&lt; 30 years old</th>
                                            <th className="px-4 py-2 font-normal border">30 - 50 years old</th>
                                            <th className="px-4 py-2 font-normal border">&gt; 50 years old</th>
                                            <th className="px-4 py-2 border-l font-normal">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <>
                                            {data !== null ? (

                                                <>
                                                    {isClicked ? (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].under30}
                                                                            onChange={e => handleInputChange(e, index, 'under30')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].between30And50}
                                                                            onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                            className="w-full text-center  text-slate-500 font-normal text-sm hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y">
                                                                        <input
                                                                            type="number"
                                                                            value={rows[index].over50}
                                                                            onChange={e => handleInputChange(e, index, 'over50')}
                                                                            className="w-full text-center text-slate-500 font-normal text-sm  hover:border-indigo-500 hover:border-b"
                                                                            placeholder="10"
                                                                        />
                                                                    </td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {categories && categories.map((category, index) => (
                                                                <tr key={index} className="text-center">
                                                                    <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].under30}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm">{rows[index].between30And50}</td>
                                                                    <td className="px-4 py-2 border-y text-slate-500 font-normal text-sm ">{rows[index].over50}</td>
                                                                    <td className="px-4 py-2 border-y border-l text-slate-500 font-normal text-sm">{rows[index].total}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {categories && categories.map((category, index) => (
                                                        <tr key={index} className="text-center">

                                                            <td className="px-4 py-2 border-y border-r text-slate-500 font-normal text-sm">{category.title}</td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].under30}
                                                                    onChange={e => handleInputChange(e, index, 'under30')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].between30And50}
                                                                    onChange={e => handleInputChange(e, index, 'between30And50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y">
                                                                <input
                                                                    type="number"
                                                                    value={rows[index].over50}
                                                                    onChange={e => handleInputChange(e, index, 'over50')}
                                                                    className="w-full text-center  hover:border-indigo-500 hover:border-b"
                                                                    placeholder="10"
                                                                />
                                                            </td>
                                                            <td className="px-4 py-2 border-y border-l">{rows[index].total}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                    </div>
                </div>
            </>

        </>
    );
}
export default Employeehier;